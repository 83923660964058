.mattress-recommend__wrapper {
    // padding: 180px 0;
    @include spaceXL(padding, 0);
    
    .options-recommend {
        display: flex;
        align-items: center;
        // margin-bottom: 36px;
        @include spaceM(margin-bottom);
        &__link {
            padding: 5px 25px;
            &:first-child {
                border-radius: 5px 0px 0px 5px;
            }
            &:last-child {
                border-radius: 0px 5px 5px 0px;
            }
            font-weight: 700;
            cursor: pointer;
            background: map-get($mau, gray-2);
            color: map-get($mau, color-primary);
        }
        .active {
            background: map-get($mau, color-primary);
            color: map-get($mau, white-1);
            // border-radius: 6px !important;
        }
    }
    .mattress-recommend {
        .left-recommend {
            padding: 0;
            @include spaceL(padding-right);
            .product-infor .product-infor__more{
                padding-top: 0;
                border-top: 0;
                span{
                    font-weight: 400;
                }
            }
            .more-wrapper{
                // padding-top: 24px;
                @include spaceM(padding-top);
            }
        }
        .right-recommend {
            .swiper-btn__nav {
                .swiper-button-prev {
                    left: -40px;
                    padding: 7px;
                }
                .swiper-button-next {
                    right: -40px;
                    padding: 7px;
                }
            }
        }
    }
}



// @media (max-width: map-get($media, ps)) {
//     .mattress-recommend__wrapper {
//         padding: 64px 0;
//     }
// }
// @media (max-width: map-get($media, tl)) {
//     .mattress-recommend__wrapper {
//         padding: 56px 0;
//     }
// }
@media (max-width: map-get($media, ts)) {
    // .mattress-recommend__wrapper {
    //     padding: 36px 0;
    // }
    // .mattress-recommend__wrapper .mattress-recommend .left-recommend {
    //     padding: 0;
    // }
    // .product-infor .product-infor__content {
    //     margin: 24px 0;
    // }
    .mattress-recommend__wrapper .mattress-recommend{
        padding: 0 24px;
    }
    .mattress-recommend__wrapper .mattress-recommend .left-recommend .more-wrapper{
        // padding-top: 24px;
        @include spaceM(padding, 0);
    }
}
