.about-story-become__wrapper{
  background-color: map-get($mau, color-primary);
  .about-story-become{
    height: 284px;
    display: flex;
    justify-content: center;
    align-items: center;


    .btn{
      a{
        padding: 18px 24px;
        font-size: 18px;
        border-radius: 12px;
        border: 2px solid white;
      }
    }
  }
}

@media (max-width: map-get($media, tl)){
  .about-story-become__wrapper{
    .about-story-become{
      .btn a{
        padding: 16px 20px;
        font-size: 16px;
      }
    }
  }
  .about-story-become__wrapper .about-story-become{
    height: 250px;
  }
}
@media (max-width: map-get($media, ml)){
  .about-story-become__wrapper{
    .about-story-become{
      .btn a{
        padding: 8px 12px;
      }
    }
  }
  .about-story-become__wrapper .about-story-become{
    height: 200px;
  }
}