.details-collections__wrapper {
    // margin-bottom: 100px;
    @include spaceL(margin, 0);
    .details-collections
        .details-collections__title{
            // padding: 80px 0;
            @include spaceM(margin-bottom);

            text-align: center;
            display: block;
        }

        .details-collections__section {
            .collections__section{
                .collection__items{
                     margin-top: 24px;

                    .items{
                        .items__img{
                            height: 20%;
                            *{
                                width: 100%;
                                height: auto;
                            }
                        }
                        .items__title{
                            &--uppercase{
                                color: map-get($mau, color-primary );
                                text-transform: uppercase;
                                padding: 13px 0;
                                display: block;
                                font-weight: 700;
                            }
                            padding: 13px 0;
                        }
                        .items__content,
                        .items__price{
                            font-size: 18px;
                        }
                        .items__description{
                            position: relative;
                            margin-top: 20px;
                            border-top: solid 1px map-get($mau, dark-gray);
                            border-bottom: solid 1px map-get($mau, dark-gray);
                            height: 36px;
                            p{
                                @include p-line-clamp(1);
                                line-height: 36px;
                            }
                        }
                    }
                }
            }
        }
}

@media (max-width: map-get($media, ps)){
    .details-collections__wrapper .collections__section{
        flex-wrap: wrap;
        .collection__items{
            flex: 0 0 33.3333%;
            max-width: 33.3333%;
        }
    }
}
@media (max-width: map-get($media, tm)){
    .details-collections__wrapper .collections__section{
        .collection__items{
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
}
@media (max-width: map-get($media, ml)){
    .details-collections__wrapper .collections__section{
        .collection__items{
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}