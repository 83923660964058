@keyframes slideDown {
	0% {
		transform: translate3d(0, 100%, 0);
	}
	100% {
		transform: translate3d(0, 0%, 0);
	}
}
@keyframes slideUp {
	0% {
		transform: translate3d(0, 0%, 0);
	}
	100% {
		transform: translate3d(0, 100%, 0);
	}
}

.fp-container {
	position: fixed;
	z-index: 1000;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	overflow: hidden;
}
.fp-wrapper {
	width: 100%;
	height: 100%;
	position: relative;
}

.fp-slide {
	width: 100%;
	height: 100%;
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 1;
	background-color: darken(white, 10%);
	box-shadow: 0 0 10px black;
	left: 0;
	top: 0;
	z-index: 1;
	&.active {
		z-index: 2;
	}
	&.changing {
		z-index: 3;
	}
}

.fp-dots {
	position: fixed;
	// padding: 10px;
	z-index: 1001;
	right: 30px;
	bottom: 50px;
	width: 20px;
	.fp-dot {
		width: 8px;
		height: 8px;
		border: 1px solid white;
		margin: 5px auto;
		border-radius: 4px;
		background-color: white;
		cursor: pointer;
		position: relative;
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
		.fp-number {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 20px;
			height: 20px;
			color: white;
			// d-none
			display: none;
		}
		.fp-title {
			position: absolute;
			right: 100%;
			top: 50%;
			transform: translateY(-50%);
			margin-right: -15px;
			padding-right: 10px;
			white-space: nowrap;
			opacity: 0;
			transition: all 0.5s ease;
			// d-none
			display: none;
		}
		&.active {
			background-color: transparent;
			height: 16px;
		}
		@media (min-width: 1025px) {
			&:hover {
				.fp-title {
					margin-right: 0;
					opacity: 1;
				}
			}
		}
	}
}

