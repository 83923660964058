.wholesale-factory {
    &__background {
        position: relative;
        height: 450px;
        width: 100%;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
        .opacity {
            position: absolute;
            top: 0;
            left: 0;
            width: 50%;
            height: 100%;
            background-image: linear-gradient(to right, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
        }
    }
    &__content1 {
        @include spaceL(padding, 0);
        width: 30%;
        position: absolute;
        top: 50%;
        left: 10%;
        transform: translateY(-50%);

        text-shadow: 0 0 5px rgba(0,0,0,.4);

        .text {
            color: white;
        }
        p {
            // margin-top: 50px;
            @include spaceM(margin-top);
            color: white;
        }
    }
    &__menu {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
        // margin: 100px 0;
        @include spaceL(margin,0);
        .menu-1,
        .menu-2,
        .menu-3 {
            width: 100%;
            max-width: 470px;
            .title {
                @include font-heading(4);
                color: #041e42;
                // margin: 50px 0 20px 0;
                @include spaceM(margin, 0);
            }
        }
        .menu-2 {
            border-left: 1px dashed #041e42;
            border-right: 1px dashed #041e42;
        }
    }

    &__content2 {
        height: 700px;
        position: relative;
        display: flex;
        justify-content: space-between;
        .left {
            width: 43.22916%;
            height: 100%;
            background-color: #041e42;

            position: relative;
            &::after{
                content: "";
                position: absolute;
                top: 5%;
                left: 50%;
                transform: translateX(-50%);
                background-image: url('../assets/wholesale/icons/ws-s-2.png');
                background-repeat: no-repeat;
                background-size: contain;
                width: 86px;
                height: 86px;
            }
        }
        .center {
            position: absolute;
            bottom: 0;
            left: 43.22916%;
            transform: translateX(-50%);
            width: 30%;
            display: flex;
            justify-content: center;
        }
        .right {
            position: relative;
            height: 100%;
            width: 56.77083%;
            .content {
                position: absolute;
                top: 35%;
                left: 30%;
                right: 25%;
                bottom: 10%;
                .title {
                    @include font-heading(3);
                }
                .subtitle {
                    display: block;
                    overflow: hidden;
                    color: map-get($mau, color-primary);
                    margin: 10px 0 15px 0;
                    position: relative;
                    &::after {
                        position: absolute;
                        top:50%;
                        width: 100%;
                        margin: 0 4px;
                        transform: translateY(-50%);
                        border-top: 1px solid map-get($mau, color-primary);
                        content: "";
                    }
                }
                .text {
                    font-style: italic;
                }
            }
        }
    }
    &__content3 {
        @include spaceContainer;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        // padding: 100px 0px 100px 0px;
        .left {
            @include spaceL(margin, 0);

            width: 550px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .content {
                width: 550px;
                padding-left: 2%;
                padding-right: 2%;
                .title {
                    color: #041e42;
                }
                .text {
                    color: map-get($mau, dark-gray);
                    // margin: 30px 0 70px 0;
                    @include spaceM(margin, 0);
                }
                .menu {
                    padding: 0;
                    li {
                        // width: 550px;
                        margin: 25px 0;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-evenly;
                        align-items: center;
                        img{
                            flex: 0 0 10%;
                            width: 10%;
                            padding-right: 12px;
                        }
                        p {
                            @include p-line-clamp(1);
                            -webkit-box-orient: vertical;
                        }
                    }
                }
            }
        }
        .right{
            padding: 0;
            @include spaceL(margin, 0);

            img{
                height: 100%;
                object-position: center;
                object-fit: cover;
            }
            .text{
                @include p-line-clamp(5);
            }
        }
    }
    &__content4 {
        height: 480px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .content4__item {
            position: relative;
            display: flex;
            flex-direction: row;
            width: 49%;
            background-color: #041e42;

            &::after{
                content: "";
                position: absolute;
                top: 5%;
                left: 50%;
                transform: translateX(-50%);
                background-image: url('../assets/wholesale/icons/ws-s-2.png');
                background-size: contain;
                background-repeat: no-repeat;
                width: 56px;
                height: 56px;
            }
            &:nth-child(2n+1) {
                .images {
                    position: absolute;
                    bottom: 4px;
                    right: 10px;
                }
                .text {
                    position: absolute;
                    top: 20%;
                    right: 40%;
                    bottom: 5%;
                }
            }
            &:nth-child(2n) {
                .images {
                    position: absolute;
                    left: 10px;
                    bottom: 4px;
                    transform: scaleX(-1);
                }
                .text {
                    position: absolute;
                    top: 20%;
                    left: 40%;
                }
            }
            .images {
                height: 85%;
                width: 35%;
                img{
                    height: 100%;
                    object-fit: contain;
                    object-position: bottom;
                }
            }
            .text {
                color: #c0c0c0;
                width: 300px;
                height: 320px;
                .title {
                    @include font-heading(3);
                    color: #c0c0c0;
                }
                .subtitle {
                    font-size: 16px;
                    margin: 10px 0 50px 0;
                }
                .description {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 4;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    font-style: italic;
                }
            }
        }
    }
    &__content5 {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: #041e42;
        // margin: 50px 0;
        @include spaceL(margin,0);
        .title{
            @include spaceS(margin-bottom);
        }
        .subtitle {
            font-size: 16px;
            font-weight: bold;
            text-transform: uppercase;
            // margin: 25px 0 10px 0;
            margin-bottom: 12px;
        }
        .text {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            .phone {
                border-right: 1px #707070 solid;
                padding-right: 15px;
            }
            .email {
                padding-left: 15px;
            }
        }
    }
}

// content 1
@media (max-width: map-get($media, ps)){
    .wholesale-factory__content1{
        width: 40%;
    }
}
@media (max-width: map-get($media, tl)){
    .wholesale-factory__content1{
        left: 10%;
    }
}

@media (max-width: map-get($media, ml)){
    .wholesale-factory__content1{
        width: 90%;
        padding: 12px;
        background-color: rgba(map-get($mau, dark-gray), .4);
        max-height: 330px;
        overflow: hidden;
        left: 5%;
    }
    .wholesale-factory__background{
        max-height: 360px;
    }
}

// content menu
@media (max-width: map-get($media, ts)){
    .wholesale-factory__menu{
        flex-direction: column;
    }
    .wholesale-factory__menu .menu-1{
        padding-bottom: 24px;
    }
    .wholesale-factory__menu .menu-2{
        border-left: 0;
        border-right: 0;
        border-top: 1px dashed #041e42;
        border-bottom: 1px dashed #041e42;
        
        padding: 24px 0;
    }
    .wholesale-factory__menu .menu-3{
        padding-top: 24px;
    }
    .wholesale-factory__menu .menu-1 .title,
    .wholesale-factory__menu .menu-2 .title, 
    .wholesale-factory__menu .menu-3 .title{
        margin: 20px 0;
    }
}

// content 2
@media (max-width: map-get($media, pm)){
    .wholesale-factory__content2{
        max-height: 560px;
    }
}
@media (max-width: map-get($media, ps)){
    .wholesale-factory__content2 
    {
        .left{
            width: 40%;
            &::after{
                width: 64px;
                height: 64px;
            }
        }
        .right{
            width: 60%;
        }
        .center{
            left: 40%;
        }
    }
    .wholesale-factory__content2 .right .content{
        top: 30%;
        left: 30%;
    }
}
@media (max-width: map-get($media, tl)){
    .wholesale-factory__content2{
        position: relative;
        max-height: 460px;
        .left{
            position: absolute;
            width: 100%;
            left: 0;
            top:0;
            &::after{
                width: 56px;
                height: 56px;
            }
        }
        .right{
            position: absolute;
            width: 50%;
            top: 0;
            left: 50%;
            .content{
                left: 0;
                top: 50%;
                right: 5%;
                bottom: 5%;
                transform: translateY(-50%);

                .title{
                    color: white;
                }
                .subtitle{
                    color: rgba(255, 255, 255, 0.7);
                    &::after{
                        border-top: 1px solid #fff;
                    }
                }
                .text{
                    color: map-get($mau, gray-1);
                    @include p-line-clamp(5);
                }
            }
        }
        .center{
            position: absolute;
            top: 0;
            left: 0;
            right: 50%;
            bottom: 0;
            width: 50%;
            transform: none;

            display: flex;
            justify-content: center;
            align-items: flex-end;
            img{
                width: 80%;
                max-height: 80%;
                object-fit: contain;
                object-position: center;
            }
        }
    }
}

@media (max-width: map-get($media, ml)){
    .wholesale-factory__content2{
        max-height: 520px;
        .left{
            &::after{
                width: 56px;
                height: 56px;
                display: none;
            }
        }
        .right{
           top: 50%;
           left: 0;
           bottom: 0;
           right: 0;
           width: 100%;
           height: auto;
           .content{
                top: 24px;
                left: 10%;
                bottom: 24px;
                right: 10%;
                transform: none;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                .subtitle{
                    padding-bottom: 12px;
                    &::after{
                        top: 100%;
                        left: 0;
                        border-top: 1px solid gray;
                    }
                }
            }
        }
        .center{
            top: 32px;
            left: 0;
            right: auto;
            bottom: 50%;
            height: auto;
            width: 100%;
            transform: none;
            img{
                height: 100%;
                max-height: 100%;
                object-fit: contain;
            }
        }
    }
}

// content 3
@media (max-width: map-get($media, ps)){
    .wholesale-factory__content3 .left .content{
        width: auto;
    }
}
// @media (max-width: map-get($media, tl)){
//     .wholesale-factory__content3{
//         padding: 56px 0;
//     }
// }
@media (max-width: map-get($media, ml)){
    .wholesale-factory__content3 .left .content .text{
        margin: 24px 0
    }
}


// content 4 
@media (max-width: map-get($media, pm)) {
    .wholesale-factory__content4 .content4__item{
        &::after{
            display: none;
        }
    }
    .wholesale-factory__content4 .content4__item:nth-child(2n+1) .text{
        right: 50%;
    }
    .wholesale-factory__content4 .content4__item:nth-child(2n) .text{
        left: 50%;
    }
    .wholesale-factory__content4 .content4__item .images{
        width: 45%;
    }

    .wholesale-factory__content4 .content4__item .text{
        left: 50% !important;
        top: calc(50% + 24px) !important;
        transform: translateX(-50%);
        width: 90%;
        height: calc(50% - 48px);

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;
    }
    .wholesale-factory__content4 .content4__item .text .subtitle{
        margin: 12px 0;
    }
    .wholesale-factory__content4 .content4__item .images{
        top: 24px;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        height: calc(50% - 24px);
    }
}
@media (max-width: map-get($media, mm)) {
    .wholesale-factory__content4{
        flex-direction: column;
        height: auto;
    }
    .wholesale-factory__content4 .content4__item{
        height: 380px;
        width: 100%;
        &:not(:first-child){
            margin-top: 12px;
        }
    }
}

// // content 5 
// @media (max-width: map-get($media, tm)){
//     .wholesale-factory__content5{
//         margin: 36px 0;
//     }
// }
