.faq-content__wrapper {
    .faq-content {
        display: flex;
        justify-content: space-between;
        width: 100%;
        @include spaceL(padding, 0px);

        .faq-content__left {
            width: 23%;
            overflow: hidden;
            .faq-cateheading {
                display: inline-block;
                position: relative;
                font-weight: 700;
                
                &::after {
                    position: absolute;
                    content: "";
                    top: 50%;
                    left: calc(100% + 12px);
                    height: 1px;
                    width: 100%;
                    background: map-get($mau, color-primary);
                    opacity: 0.8;
                    transform: translateY(-50%);
                }
            }
            .faq-left__items {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                
                // margin-top: map-get($size, margin-block-small);
                @include spaceM(margin-top);
                width: 100%;
                
                background: map-get($mau, gray-2);
                border-radius: 12px;

                // .faq-left__listt {
                //     display: flex;
                //     align-items: center;
                //     justify-content: center;
                //     padding: 20px 0;
                //     .item__desc {
                //         font-size: 20px;
                //         padding-left: 10px;
                //     }
                // }
                .faq-left__list {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    // padding: 20px 0;
                    @include spaceButtonS(padding, 0);
                    position: relative;
                    cursor: pointer;
                    transition: .2s all ease;

                    @include font-p;

                    color: map-get($mau, color-primary);
                    text-transform: uppercase;
                    font-weight: 700;

                    &:first-child{
                        border-top-left-radius: 12px;
                        border-top-right-radius: 12px;
                    }
                    &:last-child{
                        border-bottom-left-radius: 12px;
                        border-bottom-right-radius: 12px;
                    }
                    &.active{
                        background-color: rgba(map-get($mau, color-primary), .8);
                        color: white;
                    }
                    &:hover{
                        background-color: rgba(map-get($mau, color-primary), .8);
                        color: white;
                    }
                    
                    &:not(:first-child)::after {
                        width: 100%;
                        position: absolute;
                        content: "";
                        top: 0%;
                        left: 50%;
                        height: 1px;
                        background: map-get($mau, color-primary);
                        transform: translateX(-50%);
                        opacity: 0.7;
                    }

                    .item__desc {
                        font-size: 20px;
                        padding-left: 12px;
                    }
                    .item__img{
                        width: 35px;
                        height: 35px;
                        
                        border-radius: 50%;
                        object-fit: cover;
                        object-position: center;

                        pointer-events: none;

                        box-shadow: 0 0 8px rgba(0,0,0,.3);
                    }
                }
            }
        }
        .faq-content__right {
            width: 75%;
            padding-left: 60px;
            margin-top: -20px;
            .content-title {
                .title{
                    // margin-bottom: map-get($size, margin-block-medium);
                    @include spaceButton(margin-bottom);
                }
            }
            .faq-right__items {
                .faq-right__list {
                    &.active{
                        transition: .3s all linear;
                        .content__img2{
                            height: 100% !important;
                            margin-top: 8px;
                        }

                        .right-list__items::after{
                            content: "\268A";
                        }
                    }
                    .right-list__items {
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        position: relative;
                        // padding: map-get($size, margin-block-small); 
                        @include spaceButton(padding);
                        border-top: 1px solid map-get($mau, color-primary);
                        &:hover{
                            background-color: map-get($mau, gray-2);
                        }
                        &::after{
                            content: "\271A";
                            position: absolute;
                            top: 50%;
                            right: 0;
                            transform: translate(-50%, -50%);
                            @include font-heading(4);
                            vertical-align: middle;
                            color: map-get($mau, color-primary);
                            
                            font-weight: bold;
                        }

                        .item__desc2 {
                            padding-left: 10px;
                            padding-right: 25px;
                        }
 
                        // .item__btn{
                        //     border: none;
                        //     font-size: 50px;
                        //     background: map-get($mau , white) ;
                        //     margin-left: auto;
                        // }
                        
                        .item_img2 {
                            margin-left: auto;
                        }
                    }

                    .content__img2 {
                        height: 0;
                        overflow: hidden;
                        // transition: height 0.6s ease-in-out;
                        letter-spacing: 1.15px;
                        .text-content__img2 {
                            // margin: map-get($size, margin-block-medium) 0;
                            @include spaceM(margin, 0);
                            p {
                                color: map-get($mau, dark-gray);
                            }
                            .content-img2__img {
                                margin: 30px 0;
                            }     
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: map-get($media, ps)){
    .faq-content__wrapper .faq-content{
        flex-direction: column;
    }
    .faq-content__wrapper .faq-content .faq-content__right{
        width: 100%;
        margin-top: 0;
        padding-left: 0;
    }
    .faq-content__wrapper .faq-content .faq-content__left{
        width: 100%;
    }
    .faq-content__wrapper .faq-content .faq-content__left .faq-left__items{
        flex-direction: row;
        justify-content: flex-start;
        overflow-x: scroll;
        border-radius: 0;

        // &::-webkit-scrollbar {
        //     width: 3px;
        //     height: 3px;
        // }
        // /* Track */
        // &::-webkit-scrollbar-track {
        //     box-shadow: inset 0 0 1px map-get($mau, gray-1);
        //     border-radius: 5px;
        // }
        // /* Handle */
        // &::-webkit-scrollbar-thumb {
        //     background: map-get($mau, color-primary);
        //     border-radius: 5px;
        // }
        // /* Handle on hover */
        // &::-webkit-scrollbar-thumb:hover {
        //     background: rgba(map-get($mau, dark-gray), 0.7);
        // }

        .faq-left__list {
            min-width: 180px;
            // padding: 8px;

            &:first-child{
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
            }
            &:last-child{
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
            }
        }
    }
    .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list{
        min-width: 240px;
        &:not(:first-child){
            margin-left: 4px;
        }
    }
    .faq-content__wrapper .faq-content .faq-content__left .faq-cateheading::after{
        display: none;
    }
    .faq-content__wrapper .faq-content .faq-content__left{
        margin-bottom: 36px;
    }
    .faq-content__wrapper .faq-content .faq-content__right .content-title .title{
        margin-bottom: 16px;
    }
}

@media (max-width: map-get($media, ml)){
    .faq-content__wrapper .faq-content .faq-content__right .content-title .title{
        margin-bottom: 8px;
    }
    .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .content__img2 .text-content__img2{
        margin: 16px 0;
    }
}