.wholesale-service {
    // padding: map-get($size, margin-block-large) 0;
    @include spaceL(margin, 0);
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    &-left {
        flex-basis: 50%;
        width: 50%;
        position: relative;
        padding-right: 42px;
        padding-left: 42px;

        .swiper-button-next,
        .swiper-button-prev{
            &::after{
                content:"";
            }
            background-color: map-get($mau, gray-1);
            background-repeat: no-repeat;
            background-size: auto;
            background-position: center;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            box-shadow: 0 0 5px rgba(0,0,0,.3);

            &:hover{
                background-color: white;
            }
        }
        .swiper-button-next{
            background-image: url('../assets/icons/arrowRight.png');
        }
        .swiper-button-prev{
            background-image: url('../assets/icons/arrowLeft.png');
        }
        .title {
            @include font-heading(3);
            color: #041e42;
            position: relative;
            span {
                // font-size: 70px;
                @include font-heading(2);
            }
            span::after {
                position: absolute;
                bottom: 25px;
                left: 0;
                width: 80px;
                transform: rotate(120deg);
                height: 1px;
                background: #041e42;
                content: "";
            }
        }
        .text {
            // margin: 30px 0;
            @include spaceM(margin, 0);
            p {
                margin: 10px 0;
                padding-left: 60px;
            }
        }
        .images {
            max-width: 100%;
            margin-left: 0;
            
            position: relative;

            .swiper-pagination{
                left: 50%;
                transform: translateX(-50%);

                &-bullet{
                    margin: 0 6px;
                    position: relative;
                    &-active{
                        background-color: #977;
                    }
                    &-active::before{
                        content: "";
                        position: absolute;
                        width: 5px;
                        height: 5px;
                        background-color: map-get($mau, color-primary);
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        border-radius: 50%;
                    }
                }
            }
            .swiper-container{
                height: 100%;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
    &-right {
        flex-basis: 50%;
        width: 50%;
        padding-left: 3%;
        .images {
            width: 100%;
            height: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
}

@media (max-width: map-get($media, pm)) {
    .wholesale-service {
        padding: 0 0 64px 0;
    }
}
@media (max-width: map-get($media, tl)) {
    .wholesale-service-right .images {
        height: auto;
    }
}
@media (max-width: map-get($media, ts)) {
    .wholesale-service {
        flex-direction: column;
        align-items: center;
    }
    .wholesale-service-left,
    .wholesale-service-right {
        width: 100%;
        flex: 0 0 100%;
    }
    .wholesale-service-left {
        padding-right: 0;
    }
    .wholesale-service-right {
        padding-left: 0;
        margin-top: 32px;
    }
    .wholesale-service-left .images {
        margin: 0 auto;
    }
    .wholesale-service-left .text {
        text-align: center;
    }
    .wholesale-service-left .title {
        text-align: center;
        h3 {
            display: inline-block;
            position: relative;
        }
    }
    .wholesale-service-left .text p {
        padding-left: 0;
    }
}
@media (max-width: map-get($media, ml)) {
    // .wholesale{
    //     margin-bottom: 36px;
    // }
    .wholesale-service {
        padding: 0;
    }
    .wholesale-service-right .images {
        img{
            max-height: 450px;
        }
    }

}
