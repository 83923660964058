.our-tech {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    position: relative;
    @include spaceXL(margin, 0);
    .title-our-technology {
        .title {
            @include font-heading(3);
            // margin: 70px;
            @include spaceL(margin-bottom);
        }
    }
    .our-tech-box-logo {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        .box {
            background-color: #d9d9d6;
            width: 1024px;
            // padding-top: 73px;
            // padding-bottom: 54px;
            @include spaceM(padding, 0);
            height: 540px;
            position: relative;
            .swiper-pagination {
                top: 100%;
                left: 50%;
                transform: translateX(-50%);
                &-bullet {
                    margin: 0 12px;
                        // margin: 0 6px;
                    position: relative;
                    &-active{
                        background-color: #977;
                    }
                    &-active::before{
                        content: "";
                        position: absolute;
                        width: 5px;
                        height: 5px;
                        background-color: map-get($mau, color-primary);
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        border-radius: 50%;
                    }
                }
            }


            .box-content {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 1100px;
                transform: translate(-50%, -50%);

                .swiper-slide {
                    .box-each {
                        .box-logo {
                            position: relative;
                            &::after {
                                position: absolute;
                                content: "";
                                bottom: -35px;
                                left: 50%;
                                transform: translateX(-50%);
                                height: 3px;
                                width: 0%;
                                // transition: .3s all ease;
                                transform-origin: left;
                                background: #041e42;
                            }
                        }
                    }
                }

                .swiper-slide-active {
                    .box-each {
                        .box-logo {
                            position: relative;
                            &::after {
                                position: absolute;
                                content: "";
                                bottom: -35px;
                                left: 50%;
                                transform: translateX(-50%);
                                height: 3px;
                                // width: 80%;
                                background: #041e42;
                                @include animationRight(0.4s);
                            }
                        }
                    }
                }
                .box-each {
                    margin: 0 auto;
                    width: 315px;
                    .box-logo {
                        background-color: white;
                        width: 315px;
                        margin-left: 1px;
                        height: 150px;
                        box-shadow: 0px 11px 6px rgba(0, 0, 0, 0.3);
                        img {
                            height: inherit;
                        }
                    }
                    .box-description {
                        // margin-top: 30%;
                        @include spaceL(margin-top);
                        padding: 0 17px;
                        color: #041e42;

                        .tittle-description {
                            text-transform: uppercase;
                            font-weight: 500;
                            padding-top: 12px;
                        }
                        .tittle-content {
                            padding-top: 12px;
                        }
                    }
                }
            }
        }
    }
}

// @media (max-width: map-get($media, tl)){
//     .our-tech .title-our-technology .title{
//         margin: 56px;
//     }
// }
// @media (max-width: map-get($media, ml)){
//     .our-tech .title-our-technology .title{
//         margin: 36px;
//     }
// }

.tech-youtube__wrapper {
    width: 100%;
    height: 100vh;
    @media (max-width: map-get($media, tl)) {
        max-height: 640px !important;
    }
    @media (max-width: map-get($media, ml)) {
        max-height: 520px !important;
    }

    @include spaceXL(margin, 0);
    #player,
    iframe {
        width: 100% !important;
        // height: 100% !important;
        pointer-events: none;
    }
}
