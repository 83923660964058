@keyframes show-hamberger {
  0% {
    display: none;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0); }
  100% {
    display: block;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); } }

@keyframes show-popup-block {
  0% {
    display: none;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0); }
  100% {
    display: block;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); } }

@keyframes showFlexRL {
  0% {
    display: none;
    clip-path: inset(0 0 0 100%); }
  100% {
    display: flex;
    clip-path: inset(0 0 0 0); } }

@keyframes widthRight {
  0% {
    width: 0%; }
  100% {
    width: 80%; } }

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0; }

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 52.5%;
  scroll-behavior: smooth;
  top: -100px; }
  @media (min-width: 768px) {
    html {
      font-size: 57.5%; } }
  @media (min-width: 1200px) {
    html {
      font-size: 62.5%; } }

body {
  margin: 0;
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
  color: #707070;
  background-color: #ffffff; }

@media (max-width: 1024px) {
  body {
    font-size: 18px; } }

@media (max-width: 640px) {
  body {
    font-size: 16px; } }

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0; }

p {
  margin-top: 0;
  margin-bottom: 0; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 0;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: 0;
  margin-left: 0; }

blockquote {
  margin: 0; }

b,
strong {
  font-weight: bolder;
  font-weight: 700; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

a {
  text-decoration: none;
  background-color: transparent;
  margin: 0; }

pre,
code,
kbd,
samp {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 0;
  overflow: auto; }

figure {
  margin: 0; }

img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0;
  padding-bottom: 0;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0; }

button {
  border-radius: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

*:focus,
*:active {
  outline: none; }

::-webkit-input-placeholder {
  /* Edge */
  color: rgba(4, 30, 66, 0.7);
  font-style: italic; }

:-ms-input-placeholder {
  /* Internet Explorer */
  color: rgba(4, 30, 66, 0.7);
  font-style: italic; }

::placeholder {
  color: rgba(4, 30, 66, 0.7);
  font-style: italic; }

@font-face {
  font-family: HELVETICANEUELTSTD-BDOU;
  src: url("../fonts/HELVETICANEUELTSTD-BDOU.otf"); }

@font-face {
  font-family: HELVETICANEUELTSTD-ROMAN;
  src: url("../fonts/HELVETICANEUELTSTD-ROMAN.otf"); }

@font-face {
  font-family: SEGOEUI;
  src: url("../fonts/SEGOEUI.otf"); }

@font-face {
  font-family: SEGOEUIB;
  src: url("../fonts/SEGOEUIB.otf"); }

@font-face {
  font-family: SEGOEUII;
  src: url("../fonts/SEGOEUII.otf"); }

@font-face {
  font-family: SEGOEUIL;
  src: url("../fonts/SEGOEUIL.otf"); }

@font-face {
  font-family: SEGOEUISL;
  src: url("../fonts/SEGOEUISL.otf"); }

@font-face {
  font-family: SEGOEUIZ;
  src: url("../fonts/SEGOEUIZ.otf"); }

@font-face {
  font-family: SEGUIBL;
  src: url("../fonts/SEGUIBL.otf"); }

@font-face {
  font-family: SEGUIBLI;
  src: url("../fonts/SEGUIBLI.otf"); }

@font-face {
  font-family: SEGUIEMJ;
  src: url("../fonts/SEGUIEMJ.otf"); }

@font-face {
  font-family: SEGUIHIS;
  src: url("../fonts/SEGUIHIS.otf"); }

@font-face {
  font-family: SEGUILI;
  src: url("../fonts/SEGUILI.otf"); }

@font-face {
  font-family: SEGUISB;
  src: url("../fonts/SEGUISB.otf"); }

@font-face {
  font-family: SEGUISBI;
  src: url("../fonts/SEGUISBI.otf"); }

@font-face {
  font-family: SEGUISLI;
  src: url("../fonts/SEGUISLI.otf"); }

@font-face {
  font-family: SEGUISYM;
  src: url("../fonts/SEGUISYM.otf"); }

@font-face {
  font-family: HELVETICA-NEUE;
  src: url("../fonts/HELVETICANEUELTSTD-ROMAN.otf"); }

.clearfix:after {
  content: "";
  display: table;
  clear: both; }

.fullsize {
  width: 100%;
  height: 100%; }

.object-cover {
  object-fit: contain;
  object-position: center;
  background-size: cover; }

.object-contain {
  object-fit: contain;
  object-position: center;
  background-size: contain; }

.blur-up {
  filter: blur(10px);
  transition: filter 1s; }
  .blur-up.lazyloaded {
    filter: blur(0); }

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; }

.flex-center, .nav-bar-center, .dropdown-content .nav-bar-section {
  display: flex;
  justify-content: center;
  align-items: center; }

header {
  overflow: hidden; }

main {
  overflow: hidden; }

footer {
  overflow: hidden; }

.product-infor .product-infor__title {
  color: #041E42;
  font-family: "HELVETICA-NEUE", "Segoe UI", sans-serif;
  font-size: 50px;
  font-weight: 500; }
  @media (max-width: 1024px) {
    .product-infor .product-infor__title {
      font-size: 40px; } }
  @media (max-width: 640px) {
    .product-infor .product-infor__title {
      font-size: 32px; } }

.product-infor .product-infor__content {
  margin: 40px 0;
  color: #707070;
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  overflow: hidden; }
  @media (max-width: 1280px) {
    .product-infor .product-infor__content {
      margin: 32px 0; } }
  @media (max-width: 640px) {
    .product-infor .product-infor__content {
      margin: 12px 0; } }

.product-infor .product-infor__more {
  color: #041E42;
  font-weight: bold;
  padding-top: 24px;
  border-top: 1px solid #707070; }
  .product-infor .product-infor__more + .product-infor__more {
    margin-top: 12px;
    padding-top: 0;
    border: none; }
  .product-infor .product-infor__more span {
    color: #707070; }

.more {
  display: flex;
  width: fit-content;
  align-items: center;
  padding: 5px 10px;
  border: solid 2px #041E42;
  border-radius: 5px;
  margin: 40px 0;
  cursor: pointer; }
  .more p {
    text-transform: uppercase;
    margin-right: 5px;
    white-space: nowrap;
    color: #041E42;
    font-weight: bold; }

.btn--download p {
  font-weight: 600; }

.btn a {
  background-color: white;
  border: 1px solid #041E42;
  color: #041E42;
  padding: 0px 12px;
  border-radius: 3px;
  font-weight: bold;
  position: relative;
  line-height: 36px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  transition: .3s all ease; }
  .btn a:hover {
    background-color: #041e42;
    color: white; }
  .btn a svg {
    margin-left: 4px; }
    .btn a svg path {
      stroke: #041E42; }

.btn .btn-arrow.btn-arrow--white {
  background-color: white;
  border: 1px solid #041E42;
  color: #041E42; }
  .btn .btn-arrow.btn-arrow--white:hover {
    background-color: #041e42;
    color: white; }
    .btn .btn-arrow.btn-arrow--white:hover svg path {
      stroke: white; }
  .btn .btn-arrow.btn-arrow--white svg path {
    stroke: #041E42; }

.btn .btn-arrow.btn-arrow--transparent {
  background-color: transparent;
  border: 1px solid #041E42;
  color: #041E42; }
  .btn .btn-arrow.btn-arrow--transparent:hover {
    background-color: #041e42;
    color: white; }
    .btn .btn-arrow.btn-arrow--transparent:hover svg path {
      stroke: white; }
  .btn .btn-arrow.btn-arrow--transparent svg path {
    stroke: #041E42; }

.btn .btn-arrow.btn-arrow--primary {
  background-color: #041E42;
  border: 1px solid white;
  color: white; }
  .btn .btn-arrow.btn-arrow--primary:hover {
    background-color: white;
    color: #041E42; }
    .btn .btn-arrow.btn-arrow--primary:hover svg path {
      stroke: #041E42; }
  .btn .btn-arrow.btn-arrow--primary svg path {
    stroke: white; }

.swiper-btn__nav {
  position: relative; }
  .swiper-btn__nav .swiper-button-prev,
  .swiper-btn__nav .swiper-button-next {
    position: absolute;
    top: 50%;
    user-select: none; }
    .swiper-btn__nav .swiper-button-prev::after,
    .swiper-btn__nav .swiper-button-next::after {
      display: none; }

.font-heading-1 {
  color: #041E42;
  font-family: "HELVETICA-NEUE", "Segoe UI", sans-serif;
  font-size: 80px;
  font-weight: 500; }

@media (max-width: 1024px) {
  .font-heading-1 {
    font-size: 56px; } }

@media (max-width: 640px) {
  .font-heading-1 {
    font-size: 30px; } }

.font-heading-2 {
  color: #041E42;
  font-family: "HELVETICA-NEUE", "Segoe UI", sans-serif;
  font-size: 65px;
  font-weight: 500; }

@media (max-width: 1024px) {
  .font-heading-2 {
    font-size: 50px; } }

@media (max-width: 640px) {
  .font-heading-2 {
    font-size: 45px; } }

.font-heading-3 {
  color: #041E42;
  font-family: "HELVETICA-NEUE", "Segoe UI", sans-serif;
  font-size: 50px;
  font-weight: 500; }

@media (max-width: 1024px) {
  .font-heading-3 {
    font-size: 40px; } }

@media (max-width: 640px) {
  .font-heading-3 {
    font-size: 32px; } }

.font-heading-4 {
  color: #041E42;
  font-family: "HELVETICA-NEUE", "Segoe UI", sans-serif;
  font-size: 30px;
  font-weight: 500; }

@media (max-width: 1024px) {
  .font-heading-4 {
    font-size: 26px; } }

@media (max-width: 640px) {
  .font-heading-4 {
    font-size: 22px; } }

.font-heading-5 {
  color: #041E42;
  font-family: "HELVETICA-NEUE", "Segoe UI", sans-serif;
  font-size: 20px;
  font-weight: 500; }

@media (max-width: 1024px) {
  .font-heading-5 {
    font-size: 19px; } }

@media (max-width: 640px) {
  .font-heading-5 {
    font-size: 18px; } }

.font-heading-6 {
  color: #041E42;
  font-family: "HELVETICA-NEUE", "Segoe UI", sans-serif;
  font-size: 17px;
  font-weight: 500; }

@media (max-width: 1024px) {
  .font-heading-6 {
    font-size: 16px; } }

@media (max-width: 640px) {
  .font-heading-6 {
    font-size: 15px; } }

.font-p {
  color: #707070;
  font-family: "Segoe UI", sans-serif;
  font-size: 20px;
  font-weight: 400; }

@media (max-width: 1024px) {
  .font-p {
    font-size: 18px; } }

@media (max-width: 640px) {
  .font-p {
    font-size: 16px; } }

.font-p-small {
  color: #707070;
  font-family: "HELVETICA-NEUE", "Segoe UI", sans-serif;
  font-size: 16px;
  font-weight: 500; }

@media (max-width: 1024px) {
  .font-p-small {
    font-size: 15px; } }

.p-line-clamp--1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.p-line-clamp--2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.p-line-clamp--3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.p-line-clamp--4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.p-line-clamp--5 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.overlay-black {
  position: relative; }
  .overlay-black::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(0, 0, 0, 0.4);
    z-index: 1; }

.span--date, .about-media-releases__wrapper .about-media-releases__content .releases-box-item__date, .about-story-blog__wrapper .about-story-blog--left .blog-item__content-date, .about-story-blog__wrapper .about-story-blog--right .blog-item__content-date, .blog-detail__wrapper .blog-detail--right .blog-item__content-date {
  color: #707070;
  font-family: "Segoe UI", sans-serif;
  font-size: 16px;
  font-weight: 400;
  display: block;
  font-style: italic;
  margin-top: 32px;
  color: #041E42;
  text-transform: uppercase; }
  @media (max-width: 1024px) {
    .span--date, .about-media-releases__wrapper .about-media-releases__content .releases-box-item__date, .about-story-blog__wrapper .about-story-blog--left .blog-item__content-date, .about-story-blog__wrapper .about-story-blog--right .blog-item__content-date, .blog-detail__wrapper .blog-detail--right .blog-item__content-date {
      font-size: 15px; } }

::-webkit-scrollbar {
  width: 3px;
  height: 3px; }

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px #C0C0C0;
  border-radius: 5px; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #041E42;
  border-radius: 5px; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(112, 112, 112, 0.7); }

[hidden],
.hidden,
.d-none {
  display: none !important; }

@media (max-width: 1024px) {
  .desktop {
    display: none !important; } }

@media (min-width: 1025px) {
  .mobile {
    display: none !important; } }

.d-block {
  display: block; }

.d-inline-block {
  display: inline-block; }

.d-flex {
  display: flex; }

.d-inline-flex {
  display: inline-flex; }

.flex-wrap {
  flex-wrap: wrap; }

.align-center {
  align-items: center; }

.align-end {
  align-items: flex-end; }

.justify-center {
  justify-content: center; }

.justify-end {
  justify-content: flex-end; }

.justify-space-between {
  justify-content: space-between; }

.flex-center, .nav-bar-center, .dropdown-content .nav-bar-section {
  justify-content: center;
  align-items: center; }

.list-style-none {
  list-style-type: none; }

.ml-auto {
  margin-left: auto; }

.mr-auto {
  margin-right: auto; }

.mx-auto {
  margin-right: auto;
  margin-left: auto; }

.overflow-hidden {
  overflow: hidden; }

.font-italic {
  font-style: italic; }

.text-uppercase {
  text-transform: uppercase; }

.text-capitalize {
  text-transform: capitalize; }

.text-center {
  text-align: center; }

.field-validation-error {
  display: inline-block;
  margin: 0.5rem 0;
  font-size: 12px;
  color: red; }

.container-fluid {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto; }

.container {
  width: 96%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
  position: relative; }

@media (min-width: 576px) {
  .container {
    max-width: 95%; } }

@media (min-width: 1025px) {
  .container {
    max-width: 900px; } }

@media (min-width: 1280px) {
  .container {
    max-width: 1200px;
    padding-right: 3%;
    padding-left: 3%; } }

@media (min-width: 1440px) {
  .container {
    max-width: 1280px;
    padding-right: 3%;
    padding-left: 3%; } }

@media (min-width: 1600px) {
  .container {
    max-width: 1366px;
    padding-right: 3%;
    padding-left: 3%; } }

@media (min-width: 1800px) {
  .container {
    max-width: 1640px;
    padding-right: 5%;
    padding-left: 5%; } }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1025px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1360px) {
  .col-2xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-2xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-2xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-2xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-2xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-2xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-2xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-2xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-2xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-2xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-2xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-2xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-2xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-2xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

.col,
[class*="col-"] {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.header_wapper {
  position: fixed;
  line-height: 103px;
  z-index: 1000;
  background-color: white;
  box-shadow: 0px 7px 10px #00000036;
  top: 0;
  left: 0;
  right: 0; }
  .header_wapper .container {
    position: static;
    display: flex;
    justify-content: space-between;
    padding: 0; }

.header-top-section-left {
  justify-content: center;
  align-items: center;
  width: 260px; }
  .header-top-section-left .as-logo {
    height: 78px;
    width: 260px;
    display: inline-block;
    transition: 0.3s; }
    .header-top-section-left .as-logo:hover, .header-top-section-left .as-logo:active, .header-top-section-left .as-logo:focus {
      opacity: 0.7; }

.btn-menu {
  display: none; }

.nav-bar-center {
  flex-direction: row;
  white-space: nowrap; }
  .nav-bar-center .navbar-item:not(:first-child) {
    margin-left: 48px; }
  .nav-bar-center .navbar-item .nav-bar-link {
    display: block;
    color: #707070;
    font-family: "Segoe UI", sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: #041E42; }
    @media (max-width: 1024px) {
      .nav-bar-center .navbar-item .nav-bar-link {
        font-size: 17px; } }
    @media (max-width: 640px) {
      .nav-bar-center .navbar-item .nav-bar-link {
        font-size: 16px; } }
  .nav-bar-center .navbar-item .nav-bar-link:hover,
  .nav-bar-center .navbar-item .nav-bar-link:active,
  .nav-bar-center .navbar-item .nav-bar-link:focus {
    width: 100%;
    height: 100%;
    font-weight: bold; }

@media (max-width: 1280px) {
  .nav-bar-center .navbar-item {
    font-size: 16px; } }

.header-top-section-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; }
  .header-top-section-right__list {
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    vertical-align: middle; }
    .header-top-section-right__list li {
      align-items: center;
      list-style: none;
      line-height: normal; }
      .header-top-section-right__list li a {
        padding: 0 7px;
        color: #041e42;
        transition: 0.3s; }
        .header-top-section-right__list li a:hover, .header-top-section-right__list li a:active, .header-top-section-right__list li a:focus {
          opacity: 0.7; }
    .header-top-section-right__list li + li {
      border-left: 1px solid gray; }
    .header-top-section-right__list .user {
      position: relative;
      user-select: none; }
      .header-top-section-right__list .user__img.active ~ .user-box {
        display: block; }
      .header-top-section-right__list .user .user-box {
        position: absolute;
        top: 100%;
        left: 50%;
        background-color: white;
        transform: translatex(-50%);
        display: none; }
        .header-top-section-right__list .user .user-box__content {
          border-top: 2px solid #041E42;
          margin-top: 16px;
          padding: 5px 12px; }
        .header-top-section-right__list .user .user-box__item {
          display: block;
          position: relative; }
          .header-top-section-right__list .user .user-box__item::before {
            content: "\2022";
            position: absolute;
            top: 50%;
            left: -2%;
            transform: translateY(-50%); }
    .header-top-section-right__list .shop-cart {
      position: relative;
      color: #707070;
      font-family: "Segoe UI", sans-serif;
      font-size: 16px;
      font-weight: 400; }
      @media (max-width: 1024px) {
        .header-top-section-right__list .shop-cart {
          font-size: 15px; } }
      .header-top-section-right__list .shop-cart .shop-cart__icon.active ~ .shop-cart-box {
        animation: show-popup-block 0.1s linear forwards;
        display: block; }
      .header-top-section-right__list .shop-cart .shop-cart__icon.after--hidden::after {
        display: none; }
      .header-top-section-right__list .shop-cart .shop-cart__icon::after {
        content: attr(data-number);
        position: absolute;
        top: 0%;
        left: 40%;
        transform: translate(0%, -60%);
        border-radius: 50%;
        color: #041e42;
        font-weight: bold;
        font-size: 14px;
        min-width: 24px;
        min-height: 24px;
        padding: 2px 8px;
        background-color: #ECECEC;
        text-align: center;
        line-height: 20px; }
      .header-top-section-right__list .shop-cart .shop-cart__icon img {
        pointer-events: none; }
      .header-top-section-right__list .shop-cart-box {
        display: none;
        position: absolute;
        top: 100%;
        right: 0%;
        margin-top: 12px;
        width: 500px;
        max-height: 450px;
        overflow-y: scroll;
        background-color: white;
        box-shadow: 0 0 5px rgba(112, 112, 112, 0.3);
        clip-path: circle(14.6% at 50% 0); }
        .header-top-section-right__list .shop-cart-box--close {
          position: absolute;
          top: 0;
          right: 0;
          width: 36px;
          height: 36px;
          cursor: pointer; }
          .header-top-section-right__list .shop-cart-box--close:hover {
            background-color: #C0C0C0; }
          .header-top-section-right__list .shop-cart-box--close::before, .header-top-section-right__list .shop-cart-box--close::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 70%;
            height: 2px;
            background-color: #707070;
            border-radius: 1px; }
          .header-top-section-right__list .shop-cart-box--close::before {
            transform: translate(-50%, -50%) rotate(45deg); }
          .header-top-section-right__list .shop-cart-box--close::after {
            transform: translate(-50%, -50%) rotate(-45deg); }
        .header-top-section-right__list .shop-cart-box--margin {
          position: relative;
          margin: 16px; }
        .header-top-section-right__list .shop-cart-box__title {
          font-weight: bold;
          letter-spacing: 1.5px;
          line-height: 24px;
          margin-bottom: 32px;
          display: block; }
        .header-top-section-right__list .shop-cart-box__button {
          outline: none;
          background-color: #C0C0C0;
          width: 100%;
          line-height: 46px;
          text-align: center;
          font-weight: bold;
          border: 0;
          display: block; }
          .header-top-section-right__list .shop-cart-box__button:hover, .header-top-section-right__list .shop-cart-box__button:active {
            box-shadow: 0 0 8px rgba(112, 112, 112, 0.3); }
        .header-top-section-right__list .shop-cart-box__list {
          margin: 24px 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          max-height: 156px;
          overflow-y: scroll;
          box-shadow: 0 0 4px rgba(112, 112, 112, 0.1); }
        .header-top-section-right__list .shop-cart-box__item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding-right: 12px;
          border: 0; }
          .header-top-section-right__list .shop-cart-box__item:not(:first-child) {
            margin: 12px 0; }
          .header-top-section-right__list .shop-cart-box__item--img {
            background-color: #C0C0C0;
            width: 70px;
            height: 55px;
            object-fit: cover;
            object-position: center; }
          .header-top-section-right__list .shop-cart-box__item--right {
            flex-basis: 30%;
            width: 30%;
            display: flex;
            justify-content: space-between;
            align-items: center; }
          .header-top-section-right__list .shop-cart-box__item--content {
            flex-basis: 50%;
            width: 50%; }
          .header-top-section-right__list .shop-cart-box__item--amount {
            width: 46px;
            line-height: 46px;
            white-space: pre-wrap;
            font-weight: bold;
            background-color: #C0C0C0;
            text-align: center; }
          .header-top-section-right__list .shop-cart-box__item--x {
            width: 36px;
            height: 36px;
            position: relative; }
            .header-top-section-right__list .shop-cart-box__item--x::before, .header-top-section-right__list .shop-cart-box__item--x::after {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 70%;
              height: 2px;
              background-color: #707070;
              border-radius: 1px; }
            .header-top-section-right__list .shop-cart-box__item--x::before {
              transform: translate(-50%, -50%) rotate(45deg); }
            .header-top-section-right__list .shop-cart-box__item--x::after {
              transform: translate(-50%, -50%) rotate(-45deg); }
          .header-top-section-right__list .shop-cart-box__item--price {
            font-weight: bold;
            color: #707070;
            font-family: "Segoe UI", sans-serif;
            font-size: 20px;
            font-weight: 400; }
            @media (max-width: 1024px) {
              .header-top-section-right__list .shop-cart-box__item--price {
                font-size: 18px; } }
            @media (max-width: 640px) {
              .header-top-section-right__list .shop-cart-box__item--price {
                font-size: 16px; } }
        .header-top-section-right__list .shop-cart-box__total {
          width: 100%;
          height: 56px;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          padding-right: 20%;
          background-color: #C0C0C0; }
          .header-top-section-right__list .shop-cart-box__total--desc {
            font-size: 20px; }
          .header-top-section-right__list .shop-cart-box__total--unit {
            font-weight: bold; }
          .header-top-section-right__list .shop-cart-box__total--price {
            font-weight: bold; }
    .header-top-section-right__list .find {
      position: relative; }
      .header-top-section-right__list .find__img.active ~ .find-box {
        display: flex; }
      .header-top-section-right__list .find-box {
        position: absolute;
        top: calc(100% + 38px);
        right: -10px;
        padding: 8px 12px;
        background: white;
        box-shadow: 0 0 5px rgba(112, 112, 112, 0.7);
        display: none;
        justify-content: center;
        align-items: center; }
        .header-top-section-right__list .find-box input[type="search"] {
          padding: 5px 40px 5px 12px;
          height: 36px;
          width: 320px;
          max-width: 100%;
          border-radius: 5px;
          border: 1px solid rgba(4, 30, 66, 0.3); }
          .header-top-section-right__list .find-box input[type="search"]:focus {
            border: 2px solid rgba(4, 30, 66, 0.7); }
        .header-top-section-right__list .find-box--submit {
          width: 40px;
          line-height: 36px;
          background: transparent;
          border: none;
          position: absolute;
          top: 50%;
          right: 12px;
          transform: translateY(-50%);
          background: transparent;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px; }
          .header-top-section-right__list .find-box--submit:hover {
            background-color: rgba(112, 112, 112, 0.4); }
  .header-top-section-right__ham {
    margin-left: 12px;
    height: 45px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none; }
    .header-top-section-right__ham .hamberger__img {
      cursor: pointer;
      height: 19px;
      width: 23px;
      background-image: url("../assets/icons/ham.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center; }
      .header-top-section-right__ham .hamberger__img.active {
        background-image: url("../assets/icons/ham-close.png"); }
        .header-top-section-right__ham .hamberger__img.active ~ .hamberger-box {
          animation: show-hamberger 0.1s linear forwards;
          display: block; }
    .header-top-section-right__ham:hover {
      background-color: #ECECEC; }
      .header-top-section-right__ham:hover .hamberger__img {
        opacity: 0.8; }
  .header-top-section-right .hamberger {
    position: absolute;
    top: 103px;
    right: 0;
    width: 100%;
    height: calc(100vh - 103px);
    background-color: rgba(112, 112, 112, 0.1);
    display: none; }
  .header-top-section-right .hamberger-box {
    display: none;
    position: absolute;
    top: 103px;
    right: 0;
    width: 100%;
    max-width: 450px;
    max-height: calc(100vh - 103px);
    overflow-y: scroll;
    background-color: #C0C0C0;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3); }
    .header-top-section-right .hamberger-box__list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start; }
    .header-top-section-right .hamberger-box__item {
      width: 100%;
      transition: max-height 2s linear; }
      .header-top-section-right .hamberger-box__item:not(:first-child) .hamberger-box__link {
        border-top: 1px solid #707070; }
      .header-top-section-right .hamberger-box__item:hover {
        background-color: #041E42;
        opacity: 0.8; }
        .header-top-section-right .hamberger-box__item:hover .hamberger-box__link {
          color: white; }
      .header-top-section-right .hamberger-box__item.hamberger-box__item-nav {
        display: none;
        visibility: hidden;
        opacity: 0; }
      .header-top-section-right .hamberger-box__item.active {
        background-color: #041E42; }
        .header-top-section-right .hamberger-box__item.active .hamberger-box__link {
          color: white; }
        .header-top-section-right .hamberger-box__item.active .box-sub {
          max-height: initial;
          background-color: #C0C0C0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start; }
          .header-top-section-right .hamberger-box__item.active .box-sub__item {
            width: 100%; }
            .header-top-section-right .hamberger-box__item.active .box-sub__item:hover {
              background-color: rgba(4, 30, 66, 0.7);
              opacity: 0.8; }
              .header-top-section-right .hamberger-box__item.active .box-sub__item:hover .box-sub__link {
                color: white; }
            .header-top-section-right .hamberger-box__item.active .box-sub__item:not(:first-child) .box-sub__link {
              border-top: 1px solid rgba(112, 112, 112, 0.7); }
          .header-top-section-right .hamberger-box__item.active .box-sub__link {
            display: block;
            width: 60%;
            margin: 0 auto;
            color: #041E42;
            text-transform: uppercase;
            font-size: 16px;
            line-height: 45px; }
        .header-top-section-right .hamberger-box__item.active .hamberger-box__link.box-flus::after {
          content: "\268A"; }
      .header-top-section-right .hamberger-box__item .box-sub {
        max-height: 0;
        display: none;
        transition: max-height 2s linear; }
    .header-top-section-right .hamberger-box__link {
      display: block;
      width: 80%;
      margin: 0 auto;
      line-height: 64px;
      color: #041E42;
      text-transform: uppercase; }
      .header-top-section-right .hamberger-box__link.box-flus {
        position: relative; }
        .header-top-section-right .hamberger-box__link.box-flus::after {
          position: absolute;
          top: 0;
          right: 0;
          width: 20px;
          height: 20px;
          content: "\271A"; }

@media (max-width: 1280px) {
  .header-top-section-right .hamberger-box__item.hamberger-box__item-nav {
    display: block;
    opacity: 1;
    visibility: visible; } }

.dropdown {
  display: inline-block;
  width: 100%;
  left: 0; }
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #041e42;
    line-height: 103px;
    z-index: 1;
    width: 100%;
    left: 0; }
    .dropdown-content a.nav-bar-link {
      text-decoration: none; }
    .dropdown-content .nav-bar-section {
      flex-direction: row;
      height: 103px; }
      .dropdown-content .nav-bar-section .nav {
        display: flex;
        text-align: center;
        justify-content: center;
        align-self: center;
        padding: 0 26px; }
        .dropdown-content .nav-bar-section .nav a {
          line-height: normal; }
          .dropdown-content .nav-bar-section .nav a:hover {
            transform: scale(1.3); }
          .dropdown-content .nav-bar-section .nav a p {
            color: white;
            line-height: normal; }

.dropdown:hover .dropdown-content {
  display: block; }

.dropdown:hover .nav-bar-link {
  font-weight: bold; }

.btn-menu:hover {
  cursor: pointer; }

@media (max-width: 1280px) {
  .header_wapper {
    line-height: 86px; }
  .header-top-section-right__list .find-box {
    top: calc(100% + 30px); }
  .header-top-section-right .hamberger-box {
    top: 86px;
    max-height: calc(100vh - 86px); }
  .header-top-section-right .header-top-section-right__list .shop-cart-box {
    margin: 0; }
  .header-top-section-right .shop-cart {
    position: static;
    max-height: calc(100vh - 86px);
    max-width: 100%; } }

@media (max-width: 1280px) {
  .nav-bar-center {
    display: none; } }

@media (max-width: 768px) {
  .header_wapper {
    line-height: 78px; }
    .header_wapper .header-top-section-right .hamberger-box {
      top: 78px;
      max-height: calc(100vh - 78px); }
  .header-top-section-right__list .find-box {
    top: calc(100% + 27px); } }

@media (max-width: 640px) {
  .header-top-section-right .hamberger-box {
    max-width: 100%; }
  .header-top-section-right .shop-cart-box {
    max-height: calc(100vh - 78px);
    max-width: 100%; }
  .header-top-section-left {
    width: 150px; }
    .header-top-section-left .as-logo {
      width: 100%; } }

@media (max-width: 425px) {
  .header-top-section-right__list li.phone {
    display: none; }
  .header-top-section-right__list li + li {
    border-left: 0; }
  .header-top-section-right__list .find {
    position: static; }
  .header-top-section-right__list .find-box {
    top: 100%;
    right: 0;
    width: 100%; }
  .header-top-section-right__list .find-box input[type="search"] {
    width: 100%; } }

@keyframes popup {
  0% {
    opacity: 0;
    visibility: hidden;
    clip-path: inset(0 0 0 100%); }
  100% {
    opacity: 1;
    visibility: visible;
    clip-path: inset(0 0 0 0); } }

@keyframes closePopup {
  0% {
    opacity: 1;
    visibility: visible;
    clip-path: inset(0 0 0 0); }
  100% {
    opacity: 0;
    visibility: hidden;
    clip-path: inset(0 0 0 100%); } }

#form-forgot__wrapper,
#form-login__wrapper,
#form-user__wrapper,
#form-dealer__wrapper,
#form-custom__wrapper {
  display: none; }
  #form-forgot__wrapper.active,
  #form-login__wrapper.active,
  #form-user__wrapper.active,
  #form-dealer__wrapper.active,
  #form-custom__wrapper.active {
    display: flex;
    animation: popup 0.2s ease-in-out forwards; }

.loginform-content__wrapper {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  background-color: white; }
  .loginform-content__wrapper .loginform__close {
    position: absolute;
    top: 0;
    right: 0;
    background: #041E42;
    color: #ffffff;
    width: 50px;
    height: 50px;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border: none; }
    .loginform-content__wrapper .loginform__close::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #ffffff;
      content: "\2715";
      font-size: 22px;
      font-weight: 700; }
  .loginform-content__wrapper .loginform-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80vh; }
    .loginform-content__wrapper .loginform-content .register {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #041E42; }
      .loginform-content__wrapper .loginform-content .register-heading {
        margin-top: 50px;
        color: #C0C0C0; }
      .loginform-content__wrapper .loginform-content .register-customer {
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center; }
        .loginform-content__wrapper .loginform-content .register-customer__namelist {
          font-size: 18px;
          color: #C0C0C0;
          margin: 10px 20px;
          width: 60%; }
        .loginform-content__wrapper .loginform-content .register-customer__checklist {
          margin-right: auto;
          width: 24px;
          height: 24px;
          border-radius: 20px; }
      .loginform-content__wrapper .loginform-content .register-sendmess {
        width: 70%;
        display: flex;
        justify-content: space-between; }
        .loginform-content__wrapper .loginform-content .register-sendmess__textlist {
          padding-left: 10px;
          margin: 15px 0px;
          width: 49%;
          height: 50px;
          border-radius: 10px; }
      .loginform-content__wrapper .loginform-content .register-enduser {
        padding: 0px 0px;
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .loginform-content__wrapper .loginform-content .register-enduser__btn {
          width: 120px;
          height: 40px;
          margin: 20px 75px;
          border: solid 3px #ffffff;
          background: #041E42;
          border-radius: 20px;
          justify-content: center;
          font-family: SEGOEUIB;
          font-size: 16px;
          color: #C0C0C0; }
        .loginform-content__wrapper .loginform-content .register-enduser__textlist {
          padding-left: 10px;
          width: 100%;
          height: 50px;
          margin: 15px 0px;
          border-radius: 10px; }
        .loginform-content__wrapper .loginform-content .register-enduser__forgot {
          text-decoration: none;
          color: #C0C0C0; }
    .loginform-content__wrapper .loginform-content .login {
      margin-left: 40px;
      width: 50%;
      height: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #041E42; }
      .loginform-content__wrapper .loginform-content .login-heading {
        margin: 20px;
        color: #C0C0C0; }
      .loginform-content__wrapper .loginform-content .login-customer {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center; }
        .loginform-content__wrapper .loginform-content .login-customer__namelist {
          font-size: 18px;
          color: #C0C0C0;
          margin: 10px 20px;
          width: 60%; }
        .loginform-content__wrapper .loginform-content .login-customer__checklist {
          margin-right: auto;
          width: 24px;
          height: 24px;
          border-radius: 20px; }
      .loginform-content__wrapper .loginform-content .login-sendmess {
        width: 80%;
        display: flex;
        justify-content: space-between; }
        .loginform-content__wrapper .loginform-content .login-sendmess__textlist {
          padding-left: 10px;
          margin: 15px 0px;
          width: 49%;
          height: 50px;
          border-radius: 10px; }
      .loginform-content__wrapper .loginform-content .login-enduser {
        padding: 0px 0px;
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .loginform-content__wrapper .loginform-content .login-enduser__btn {
          width: 120px;
          height: 40px;
          margin: 20px 75px;
          border: solid 3px #ffffff;
          background: #041E42;
          border-radius: 20px;
          justify-content: center;
          font-family: SEGOEUIB;
          font-size: 16px;
          color: #C0C0C0; }
        .loginform-content__wrapper .loginform-content .login-enduser__textlist {
          padding-left: 10px;
          width: 100%;
          height: 50px;
          margin: 20px 0px;
          border-radius: 10px; }
        .loginform-content__wrapper .loginform-content .login-enduser__forgot {
          text-decoration: none;
          color: #C0C0C0; }
  .loginform-content__wrapper .login-right {
    width: 50%;
    height: 100%; }
    .loginform-content__wrapper .login-right .cart-section {
      padding: 0px 50px;
      width: 100%;
      position: relative; }
      .loginform-content__wrapper .login-right .cart-section::after {
        position: absolute;
        content: "";
        top: 50%;
        right: 47%;
        height: 1px;
        width: 100%;
        transform: rotate(90deg);
        background: #041E42;
        opacity: 0.2; }
      .loginform-content__wrapper .login-right .cart-section .cart-content__title {
        margin: 40px 0px;
        display: flex;
        justify-content: center; }
      .loginform-content__wrapper .login-right .cart-section .cart-header {
        border: solid #041E42 1px;
        margin: 15px 0px;
        padding: 0; }
      .loginform-content__wrapper .login-right .cart-section .cart-content {
        margin: 40px 0px;
        position: relative; }
        .loginform-content__wrapper .login-right .cart-section .cart-content::after {
          position: absolute;
          content: "";
          top: 120%;
          right: 0%;
          height: 1px;
          width: 100%;
          background: #041E42;
          opacity: 0.2; }
        .loginform-content__wrapper .login-right .cart-section .cart-content .cart-items__textview {
          width: 200px;
          height: 50px; }
        .loginform-content__wrapper .login-right .cart-section .cart-content .cart-items__symbolprice {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 85%;
          height: 50px;
          margin-left: 20px; }
        .loginform-content__wrapper .login-right .cart-section .cart-content .cart-items__symboltotal {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 85%;
          height: 50px;
          margin-left: 20px;
          background: #C0C0C0;
          position: relative; }
          .loginform-content__wrapper .login-right .cart-section .cart-content .cart-items__symboltotal::after {
            position: absolute;
            top: 50%;
            left: 90%;
            transform: translate(-50%, -50%);
            color: #ffffff;
            content: "\2715";
            font-size: 22px;
            font-weight: 700; }
        .loginform-content__wrapper .login-right .cart-section .cart-content .cart-items__quanlity {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 55%;
          height: 50px;
          margin-left: 25px;
          background: #C0C0C0; }
      .loginform-content__wrapper .login-right .cart-section .cart-footer {
        width: 100%; }
        .loginform-content__wrapper .login-right .cart-section .cart-footer__click {
          justify-content: space-between;
          border: none;
          width: 180px;
          height: 50px; }

.form__wrapper {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white; }
  .form__wrapper .loginform__close {
    position: absolute;
    top: 0;
    right: 0;
    background: #041E42;
    color: #ffffff;
    width: 50px;
    height: 50px;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border: none; }
    .form__wrapper .loginform__close::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #ffffff;
      content: "\2715";
      font-size: 22px;
      font-weight: 700; }
  .form__wrapper .form__title {
    color: #041E42;
    margin: 42px 0;
    text-align: center; }
  .form__wrapper .form-content {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.7);
    padding: 24px;
    width: 560px;
    max-width: 100%;
    max-height: 90%;
    overflow-y: scroll;
    background: #041E42; }
    .form__wrapper .form-content__dealer {
      width: 720px; }
    .form__wrapper .form-content__title {
      color: #C0C0C0;
      font-weight: bold;
      margin: 64px 0;
      text-align: center;
      text-transform: uppercase; }
    .form__wrapper .form-content--group {
      width: 100%; }
    .form__wrapper .form-content .input-group {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center; }
      .form__wrapper .form-content .input-group:not(:first-child) {
        margin-top: 24px; }
      .form__wrapper .form-content .input-group label {
        width: 50%;
        justify-self: center; }
      .form__wrapper .form-content .input-group input[type="checkbox"] {
        width: 20px;
        height: 20px; }
      .form__wrapper .form-content .input-group input + input {
        margin-left: 2%; }
      .form__wrapper .form-content .input-group input,
      .form__wrapper .form-content .input-group textarea {
        border-radius: 10px;
        padding: 5px 16px;
        height: 56px;
        line-height: 36px;
        background-color: #ffffff;
        letter-spacing: 1px;
        font-size: 20px; }
      .form__wrapper .form-content .input-group input[type="text"],
      .form__wrapper .form-content .input-group input[type="email"],
      .form__wrapper .form-content .input-group input[type="password"],
      .form__wrapper .form-content .input-group input[type="tel"],
      .form__wrapper .form-content .input-group input[type="date"],
      .form__wrapper .form-content .input-group textarea {
        width: 100%; }
      .form__wrapper .form-content .input-group textarea {
        height: 120px;
        resize: none; }
      .form__wrapper .form-content .input-group input[type="submit"] {
        width: 240px;
        max-width: 100%;
        border-radius: 56px;
        border: 3px solid #C0C0C0;
        background-color: transparent;
        color: #C0C0C0; }
        .form__wrapper .form-content .input-group input[type="submit"]:hover {
          background-color: #C0C0C0;
          color: #041E42; }
      .form__wrapper .form-content .input-group input:focus {
        box-shadow: 0 0 5px rgba(112, 112, 112, 0.3); }
    .form__wrapper .form-content--link {
      display: inline-block;
      color: #C0C0C0;
      margin: 24px 0;
      font-size: 16px;
      font-style: italic; }
      .form__wrapper .form-content--link:hover, .form__wrapper .form-content--link:active {
        font-weight: bold;
        text-decoration: underline; }
    .form__wrapper .form-content-register {
      margin-top: 24px;
      border-top: 1px solid #C0C0C0;
      display: flex;
      justify-content: flex-start;
      flex-direction: column; }
      .form__wrapper .form-content-register .form-content--link {
        margin: 12px 0; }
  .form__wrapper .form-flex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: white;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-left: 2%; }
    .form__wrapper .form-flex--left {
      width: 30%;
      min-width: 360px; }
    .form__wrapper .form-flex--right {
      width: 60%;
      min-width: 660px;
      margin-left: 2%;
      border-left: 1px solid #C0C0C0;
      align-self: stretch; }
      .form__wrapper .form-flex--right .form-cart {
        padding: 24px;
        width: 100%;
        max-width: 100%;
        height: 100%;
        background-color: #ffffff;
        color: #707070;
        display: flex;
        flex-direction: column; }
        .form__wrapper .form-flex--right .form-cart__title {
          color: #041E42;
          margin: 24px 0;
          text-align: center;
          font-weight: bold;
          text-transform: uppercase; }
        .form__wrapper .form-flex--right .form-cart-table {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          flex-grow: 1; }
          .form__wrapper .form-flex--right .form-cart-table__product {
            margin: 24px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            height: 250px;
            max-height: 250px;
            overflow-y: scroll;
            box-shadow: 0 0 4px rgba(112, 112, 112, 0.1); }
            .form__wrapper .form-flex--right .form-cart-table__product--item:first-child {
              position: sticky;
              top: 0;
              z-index: 1;
              border: 1px solid #041E42;
              box-shadow: 0 5px 10px rgba(112, 112, 112, 0.3);
              background-color: white; }
            .form__wrapper .form-flex--right .form-cart-table__product--item {
              display: flex;
              flex-direction: row;
              width: 100%; }
            .form__wrapper .form-flex--right .form-cart-table__product .product__header {
              line-height: 46px; }
            .form__wrapper .form-flex--right .form-cart-table__product .product__item {
              min-height: 120px; }
              .form__wrapper .form-flex--right .form-cart-table__product .product__item:not(:first-child) {
                margin: 12px 0; }
              .form__wrapper .form-flex--right .form-cart-table__product .product__item--center {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center; }
              .form__wrapper .form-flex--right .form-cart-table__product .product__item--span {
                display: block;
                min-width: 72px;
                min-height: 64px;
                padding: 0px 8px;
                line-height: 64px;
                border-radius: 3px;
                background-color: #C0C0C0;
                font-weight: bold;
                color: #041E42; }
              .form__wrapper .form-flex--right .form-cart-table__product .product__item--product {
                padding-left: 12px;
                flex-basis: 40%; }
                .form__wrapper .form-flex--right .form-cart-table__product .product__item--product-img {
                  width: 84px;
                  height: 64px;
                  max-height: 100%;
                  margin-right: 2%; }
                .form__wrapper .form-flex--right .form-cart-table__product .product__item--product-content {
                  padding-right: 8px;
                  flex: 1; }
              .form__wrapper .form-flex--right .form-cart-table__product .product__item--price {
                flex-basis: 20%;
                text-align: center; }
                .form__wrapper .form-flex--right .form-cart-table__product .product__item--price span {
                  background-color: transparent; }
              .form__wrapper .form-flex--right .form-cart-table__product .product__item--quanlity {
                flex-basis: 10%;
                text-align: center; }
              .form__wrapper .form-flex--right .form-cart-table__product .product__item--total {
                flex-basis: 20%;
                text-align: center; }
              .form__wrapper .form-flex--right .form-cart-table__product .product__item--close {
                cursor: pointer;
                flex-basis: 10%;
                text-align: center; }
                .form__wrapper .form-flex--right .form-cart-table__product .product__item--close span {
                  min-width: 36px;
                  min-height: 36px;
                  line-height: 36px;
                  position: relative;
                  background-color: transparent;
                  transition: 0.3s all linear; }
                  .form__wrapper .form-flex--right .form-cart-table__product .product__item--close span:hover {
                    background-color: #C0C0C0; }
          .form__wrapper .form-flex--right .form-cart-table__footer {
            flex-grow: 1;
            display: flex;
            padding-top: 24px;
            border-top: 1px solid #C0C0C0;
            position: relative; }
            .form__wrapper .form-flex--right .form-cart-table__footer .footer__note {
              flex-basis: 40%;
              resize: none;
              height: 100%;
              padding: 12px; }
            .form__wrapper .form-flex--right .form-cart-table__footer .footer-subtotal {
              position: absolute;
              top: 24px;
              right: 10%; }
              .form__wrapper .form-flex--right .form-cart-table__footer .footer-subtotal p {
                color: #041E42; }
                .form__wrapper .form-flex--right .form-cart-table__footer .footer-subtotal p span {
                  font-weight: bold; }
            .form__wrapper .form-flex--right .form-cart-table__footer .footer-submit {
              flex-grow: 1;
              display: flex;
              justify-content: center;
              align-items: flex-end;
              flex-direction: row; }
              .form__wrapper .form-flex--right .form-cart-table__footer .footer-submit input {
                border-radius: 8px;
                border: 0;
                background-color: transparent;
                min-width: 180px;
                min-height: 48px;
                padding: 5px 8px;
                background-color: #ECECEC;
                margin: 0 12px;
                color: #041E42;
                transition: .3s ease-in-out; }
                .form__wrapper .form-flex--right .form-cart-table__footer .footer-submit input:focus, .form__wrapper .form-flex--right .form-cart-table__footer .footer-submit input:hover {
                  font-size: 22px;
                  opacity: .7; }
              .form__wrapper .form-flex--right .form-cart-table__footer .footer-submit input[type="submit"] {
                background-color: #041E42;
                color: white; }
    .form__wrapper .form-flex .form-content {
      overflow-y: auto; }

@media (max-width: 1024px) {
  #form-custom__wrapper .form-flex {
    justify-content: flex-start; } }

@media (max-width: 768px) {
  .form__wrapper .form-content {
    width: 96%; } }

@media (max-width: 1280px) {
  #form-custom__wrapper .form-flex {
    flex-direction: column; }
  #form-custom__wrapper.form__wrapper .form-flex {
    margin: 0; }
    #form-custom__wrapper.form__wrapper .form-flex--left {
      min-width: auto;
      width: 90%; }
    #form-custom__wrapper.form__wrapper .form-flex--right {
      border: 0;
      width: 90%;
      min-width: auto;
      margin-left: 0;
      align-self: center;
      margin-bottom: 24px; }
      #form-custom__wrapper.form__wrapper .form-flex--right .form-cart-table__product {
        max-height: none;
        height: auto;
        overflow-x: scroll;
        overflow-y: hidden;
        align-items: flex-start;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); }
        #form-custom__wrapper.form__wrapper .form-flex--right .form-cart-table__product--item {
          min-width: 650px; }
      #form-custom__wrapper.form__wrapper .form-flex--right .form-cart-table__footer {
        flex-direction: column;
        padding: 64px 0 0 0; }
        #form-custom__wrapper.form__wrapper .form-flex--right .form-cart-table__footer .footer__note {
          width: 100%;
          height: 160px;
          flex-basis: initial;
          margin-bottom: 24px;
          border: 1px solid #041E42;
          outline: none;
          transition: .3 ease-in-out; }
          #form-custom__wrapper.form__wrapper .form-flex--right .form-cart-table__footer .footer__note:focus {
            padding-left: 24px; }
    #form-custom__wrapper.form__wrapper .form-flex .form-content {
      overflow-y: visible;
      width: 100%; }
  #form-custom__wrapper.form__wrapper .form-content {
    max-height: none; } }

@media (max-width: 640px) {
  #form-custom__wrapper.form__wrapper .form-flex--left {
    width: 98%; }
  #form-custom__wrapper.form__wrapper .form-flex--right {
    width: 98%; }
    #form-custom__wrapper.form__wrapper .form-flex--right .form-cart-table__footer .footer-submit {
      flex-direction: column; }
      #form-custom__wrapper.form__wrapper .form-flex--right .form-cart-table__footer .footer-submit > * {
        margin: 0; }
      #form-custom__wrapper.form__wrapper .form-flex--right .form-cart-table__footer .footer-submit > *:not(:first-child) {
        margin-top: 12px; } }

.footer__wrapper {
  background-color: #ECECEC;
  padding: 0 50px; }
  .footer__wrapper .footer-link {
    display: inline-block;
    transition: .3s all linear; }
    .footer__wrapper .footer-link p {
      display: inline-block; }
    .footer__wrapper .footer-link:hover p, .footer__wrapper .footer-link:focus p {
      color: #707070 !important;
      font-weight: bold; }
  .footer__wrapper .container {
    position: relative; }
    .footer__wrapper .container::after {
      position: absolute;
      content: "";
      margin-top: 15px;
      bottom: 0;
      height: 1px;
      width: 100%;
      background: gray; }
  .footer__wrapper .row {
    padding-top: 35px;
    justify-content: space-around; }
  .footer__wrapper .footer-support .support--title {
    margin: 4% 0; }
  .footer__wrapper .footer-support .support--content p {
    color: gray;
    font-size: 15px;
    padding: 3px 0; }
  .footer__wrapper .footer-shop-mattresses .shop-mattresses--title {
    margin: 4% 0; }
    .footer__wrapper .footer-shop-mattresses .shop-mattresses--title p {
      letter-spacing: 2px;
      font-size: 20px;
      font-weight: 450;
      color: #041E42; }
  .footer__wrapper .footer-shop-mattresses .shop-mattresses--content {
    display: flex;
    flex-direction: column; }
    .footer__wrapper .footer-shop-mattresses .shop-mattresses--content p {
      color: gray;
      padding: 3px 0;
      font-size: 15px; }
  .footer__wrapper .footer-latest-news .latest-news--title {
    margin: 4% 0; }
    .footer__wrapper .footer-latest-news .latest-news--title p {
      letter-spacing: 2px;
      font-size: 20px;
      font-weight: 450;
      color: #041E42; }
  .footer__wrapper .footer-latest-news .latest-news--content {
    display: flex;
    flex-direction: column; }
    .footer__wrapper .footer-latest-news .latest-news--content .content {
      color: gray;
      font-size: 15px;
      margin-bottom: 30px; }
    .footer__wrapper .footer-latest-news .latest-news--content .content-1 {
      position: relative; }
      .footer__wrapper .footer-latest-news .latest-news--content .content-1 a {
        color: gray; }
      .footer__wrapper .footer-latest-news .latest-news--content .content-1::after {
        position: absolute;
        content: "";
        margin-top: 10px;
        bottom: -12px;
        left: 0;
        height: 1px;
        width: 70%;
        background: gray; }
    .footer__wrapper .footer-latest-news .latest-news--content .content-2 p {
      margin-bottom: 20px;
      font-weight: 3500;
      font-size: 20px;
      color: #041E42; }
    .footer__wrapper .footer-latest-news .latest-news--content .content-3 {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      width: 95%; }
      .footer__wrapper .footer-latest-news .latest-news--content .content-3 input[type="email"] {
        width: 60%;
        height: 48px;
        padding-left: 10px;
        border: solid 2px #041e42;
        border-right: none;
        border-radius: 5px 0 0 5px;
        background-color: #ececec; }
        .footer__wrapper .footer-latest-news .latest-news--content .content-3 input[type="email"]::placeholder {
          font-style: italic;
          font-size: 18px; }
      .footer__wrapper .footer-latest-news .latest-news--content .content-3 input[type="submit"] {
        min-width: 30%;
        padding: 5px 8px;
        font-style: italic;
        height: 48px;
        background-color: #041E42;
        border-radius: 0 5px 5px 0;
        border: 2px solid #041E42;
        color: #ECECEC;
        right: 0;
        text-transform: uppercase;
        transition: .3s; }
        .footer__wrapper .footer-latest-news .latest-news--content .content-3 input[type="submit"]:hover {
          background-color: #ECECEC;
          color: #041E42;
          border: 2px solid #041E42; }
      .footer__wrapper .footer-latest-news .latest-news--content .content-3 .form-info {
        font-size: 14px;
        padding: 5px 0;
        width: 100%; }
      .footer__wrapper .footer-latest-news .latest-news--content .content-3 .error {
        color: rgba(255, 0, 0, 0.658); }
      .footer__wrapper .footer-latest-news .latest-news--content .content-3 .success {
        color: #4bb543; }
  .footer__wrapper .footer-contact-us .contact-us--title {
    margin: 4% 0; }
    .footer__wrapper .footer-contact-us .contact-us--title p {
      letter-spacing: 2px;
      font-size: 20px;
      font-weight: 450;
      color: #041E42; }
  .footer__wrapper .footer-contact-us .contact-us--content {
    display: flex;
    flex-direction: column; }
    .footer__wrapper .footer-contact-us .contact-us--content p {
      color: gray;
      padding: 3px 0;
      font-size: 15px; }
  .footer__wrapper .footer-contact-us .contact-us--social {
    position: relative;
    padding-top: 15px; }
    .footer__wrapper .footer-contact-us .contact-us--social .social__link {
      display: inline-block;
      margin-right: 20px; }
    .footer__wrapper .footer-contact-us .contact-us--social .social__img {
      width: 36px;
      height: 36px;
      object-fit: contain;
      object-position: center; }
    .footer__wrapper .footer-contact-us .contact-us--social::after {
      position: absolute;
      content: "";
      top: 4px;
      left: 0;
      height: 1px;
      width: 80%;
      background: rgba(128, 128, 128, 0.432); }
  .footer__wrapper .copyright {
    padding: 15px 0;
    border-top: 2px solid rgba(192, 192, 192, 0.9);
    opacity: 1;
    text-align: center; }

@media (max-width: 1440px) {
  .footer__wrapper .font-heading-4 {
    font-size: 26px; } }

@media (max-width: 768px) {
  .footer__wrapper .row {
    padding-bottom: 12px; }
  .footer__wrapper .footer-contact-us .contact-us--social::after {
    width: 100%; } }

@media (max-width: 640px) {
  .footer__wrapper .footer-latest-news .latest-news--content .content-3 {
    flex-direction: column; }
  .footer__wrapper .footer-latest-news .latest-news--content .content-3 input[type="email"] {
    width: 100%;
    height: 48px;
    padding-left: 10px;
    border: solid 2px #041e42;
    border-radius: 5px;
    background-color: #ececec; }
  .footer__wrapper .footer-latest-news .latest-news--content .content-3 input[type="submit"] {
    border-radius: 5px; }
  .footer__wrapper .footer-latest-news .latest-news--content .content-3 input + input {
    margin-top: 12px; } }

@media (max-width: 425px) {
  .footer-contact-us {
    padding-bottom: 12px; }
  .footer__wrapper {
    padding: 0 20px; } }

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 0;
  appearance: none; }

.tab-container .list-tab .item {
  cursor: pointer; }

.tab-container .tab-content .content {
  display: none;
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.2, 0.5, 0.5, 0.8); }
  .tab-container .tab-content .content.show {
    opacity: 1; }

#loading-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  transition: all 0.3s ease-in-out;
  background-color: #ffffff; }
  #loading-container .loading-wrapper {
    position: absolute;
    padding-bottom: 75px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 450px;
    padding: 0 20px; }
  #loading-container .progress {
    height: 15px;
    background-color: #e9ecef;
    box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 3px black;
    border-radius: 8px;
    overflow: hidden; }
  #loading-container .progress-status {
    margin-top: 15px;
    text-align: center;
    font-size: 16px;
    letter-spacing: 3px;
    font-weight: 600; }
  #loading-container #progress-bar {
    max-width: 100%;
    width: 0%;
    height: 100%;
    background-color: #ff9e0c;
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.3) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.3) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
    background-repeat: repeat;
    animation: background 10s linear infinite forwards; }
  #loading-container #loading-logo {
    text-align: center;
    padding: 20px 0; }
    #loading-container #loading-logo img,
    #loading-container #loading-logo svg {
      max-width: 200px;
      width: 100%; }

@keyframes slideDown {
  0% {
    transform: translate3d(0, 100%, 0); }
  100% {
    transform: translate3d(0, 0%, 0); } }

@keyframes slideUp {
  0% {
    transform: translate3d(0, 0%, 0); }
  100% {
    transform: translate3d(0, 100%, 0); } }

.fp-container {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden; }

.fp-wrapper {
  width: 100%;
  height: 100%;
  position: relative; }

.fp-slide {
  width: 100%;
  height: 100%;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #e6e6e6;
  box-shadow: 0 0 10px black;
  left: 0;
  top: 0;
  z-index: 1; }
  .fp-slide.active {
    z-index: 2; }
  .fp-slide.changing {
    z-index: 3; }

.fp-dots {
  position: fixed;
  z-index: 1001;
  right: 30px;
  bottom: 50px;
  width: 20px; }
  .fp-dots .fp-dot {
    width: 8px;
    height: 8px;
    border: 1px solid white;
    margin: 5px auto;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
    position: relative;
    transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1); }
    .fp-dots .fp-dot .fp-number {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      color: white;
      display: none; }
    .fp-dots .fp-dot .fp-title {
      position: absolute;
      right: 100%;
      top: 50%;
      transform: translateY(-50%);
      margin-right: -15px;
      padding-right: 10px;
      white-space: nowrap;
      opacity: 0;
      transition: all 0.5s ease;
      display: none; }
    .fp-dots .fp-dot.active {
      background-color: transparent;
      height: 16px; }
    @media (min-width: 1025px) {
      .fp-dots .fp-dot:hover .fp-title {
        margin-right: 0;
        opacity: 1; } }

.breadcrumb-wrapper {
  display: flex;
  align-items: center;
  padding-top: 36px;
  margin: 0 -3%;
  max-width: 100%;
  overflow-x: scroll;
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  .breadcrumb-wrapper .breadcrumb-items {
    font-size: 16px;
    color: #707070;
    text-transform: uppercase;
    padding-right: 20px;
    position: relative;
    white-space: nowrap;
    line-height: 20px; }
    .breadcrumb-wrapper .breadcrumb-items::after {
      content: ">";
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translate(-50%, -50%);
      line-height: 20px;
      font-size: inherit; }
  .breadcrumb-wrapper .active {
    font-weight: 700; }
    .breadcrumb-wrapper .active::after {
      content: ""; }
  .breadcrumb-wrapper::-webkit-scrollbar {
    width: 0px; }
  .breadcrumb-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px #C0C0C0;
    border-radius: 10px; }
  .breadcrumb-wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px; }
  .breadcrumb-wrapper::-webkit-scrollbar-thumb:hover {
    background: rgba(112, 112, 112, 0.7); }

@media (max-width: 1024px) {
  .breadcrumb-wrapper {
    margin: 0; } }

.dropdown-value .dropdown-item {
  margin-right: 10px;
  padding: 5px 10px;
  border: solid 2px #041E42;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  background: none;
  color: #707070; }
  .dropdown-value .dropdown-item .dropdown-caret {
    height: 100%;
    margin-left: auto; }
    .dropdown-value .dropdown-item .dropdown-caret img {
      max-width: none;
      margin-left: 10px; }

.web4g {
  position: fixed;
  width: 100%;
  z-index: 10; }

.animated,
.fadein {
  animation-duration: 2s; }

body {
  position: relative; }

.index-intro .intro-pic-txt {
  position: relative;
  width: 100%;
  height: 100vh; }
  @media (max-width: 1024px) {
    .index-intro .intro-pic-txt {
      max-height: 720px; } }
  @media (max-width: 640px) {
    .index-intro .intro-pic-txt {
      max-height: 520px; } }

.index-intro .intro-pic {
  width: 100%;
  height: 100%; }
  .index-intro .intro-pic .intro__background {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center; }

.index-intro .intro-txt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 500;
  width: 100%;
  text-align: center; }
  .index-intro .intro-txt .intro__heading {
    text-transform: uppercase;
    display: inline-block;
    color: white;
    letter-spacing: 40px;
    font-family: inherit; }
  .index-intro .intro-txt .intro__desc {
    text-transform: uppercase;
    color: white;
    letter-spacing: 5px;
    margin-top: 40px; }

@media (max-width: 1024px) {
  .index-intro .intro-txt .intro__heading {
    letter-spacing: 15px; } }

@media (max-width: 1024px) {
  .index-intro .intro-txt .intro__heading {
    letter-spacing: 5px; }
  .index-intro .intro-txt .intro__desc {
    margin-top: 32px; } }

.featured {
  background-color: #ECECEC;
  width: 100%; }
  .featured-content {
    width: 96%;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    height: auto; }
    @media (min-width: 576px) {
      .featured-content {
        max-width: 95%; } }
    @media (min-width: 1025px) {
      .featured-content {
        max-width: 900px; } }
    @media (min-width: 1280px) {
      .featured-content {
        max-width: 1200px;
        padding-right: 3%;
        padding-left: 3%; } }
    @media (min-width: 1440px) {
      .featured-content {
        max-width: 1280px;
        padding-right: 3%;
        padding-left: 3%; } }
    @media (min-width: 1600px) {
      .featured-content {
        max-width: 1366px;
        padding-right: 3%;
        padding-left: 3%; } }
    @media (min-width: 1800px) {
      .featured-content {
        max-width: 1640px;
        padding-right: 5%;
        padding-left: 5%; } }
    .featured-content .block-featured {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 0;
      text-align: unset; }
      .featured-content .block-featured .featured-left {
        overflow: visible;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%; }
      .featured-content .block-featured .featured-main-title .title .title-left {
        margin-bottom: 40px;
        color: #041E42;
        font-weight: 500; }
        @media (max-width: 1280px) {
          .featured-content .block-featured .featured-main-title .title .title-left {
            margin-bottom: 32px; } }
        @media (max-width: 640px) {
          .featured-content .block-featured .featured-main-title .title .title-left {
            margin-bottom: 12px; } }
      .featured-content .block-featured .featured-main-title .content {
        margin-bottom: 40px;
        white-space: wrap; }
        @media (max-width: 1280px) {
          .featured-content .block-featured .featured-main-title .content {
            margin-bottom: 32px; } }
        @media (max-width: 640px) {
          .featured-content .block-featured .featured-main-title .content {
            margin-bottom: 12px; } }
        .featured-content .block-featured .featured-main-title .content p {
          color: #707070;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 8;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          white-space: wrap; }
      @media (max-width: 1024px) {
        .featured-content .block-featured .featured-main-title {
          max-width: 100%; }
          .featured-content .block-featured .featured-main-title .block-list-item {
            margin-bottom: 10px; } }

.featured-right {
  user-select: none;
  margin-left: 24px;
  width: 70%; }
  .featured-right .block-pic {
    width: 100%;
    height: 100%; }
    .featured-right .block-pic .pic {
      width: 100%;
      height: 100%; }

@media (max-width: 1280px) {
  .featured-content .block-featured .featured-left {
    margin-top: 24px;
    margin-left: 0px;
    width: 80%; }
  .featured-right {
    width: 100%; }
  .featured-content .block-featured .block-main-title .title p {
    font-size: 28px; }
  .featured-content .block-featured {
    width: 100%;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding-bottom: 64px; } }
  @media (max-width: 1280px) and (max-width: 1280px) {
    .featured-content .block-featured {
      padding-bottom: 40px; } }
  @media (max-width: 1280px) and (max-width: 640px) {
    .featured-content .block-featured {
      padding-bottom: 32px; } }

@media (max-width: 1280px) {
    .featured-content .block-featured .featured-left {
      margin-left: 0px;
      text-align: center; }
    .featured-content .block-featured .block-main-title .title, .featured-content .block-featured .block-main-title .content {
      margin-bottom: 24px; } }

@media (max-width: 1024px) {
  .featured-content .block-featured .featured-main-title .content p {
    -webkit-line-clamp: 4; }
  .featured-content .block-featured .featured-left {
    width: 90%; } }

@media (max-width: 768px) {
  .featured-content .block-featured .featured-left {
    width: 100%; } }

@media (max-width: 425px) {
  .pic .icons {
    display: none; } }

.pic {
  position: relative;
  height: 100%; }
  .pic .index-2__bg {
    display: block;
    width: 100%;
    height: 100%; }
  .pic .icons {
    position: absolute;
    top: 0;
    left: 0;
    list-style-type: none;
    width: 100%;
    height: 100%;
    padding: 0; }
    .pic .icons-item {
      background-color: white;
      text-align: center;
      color: black;
      font-size: 15px;
      position: absolute;
      display: inline-block;
      min-width: 32px;
      height: 32px;
      line-height: 32px;
      width: 32px;
      border-radius: 50%;
      transition: 0.3s all;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
      display: block;
      justify-content: center;
      align-items: center; }
      .pic .icons-item.active {
        width: auto;
        z-index: 1;
        height: auto; }
        .pic .icons-item.active .icons-item__img {
          opacity: 1; }
        .pic .icons-item.active .icons-item__desc {
          width: 200px;
          height: auto;
          transform: scale(1);
          transform-origin: right;
          position: absolute;
          top: 0%;
          left: 8px;
          background: white;
          z-index: -1;
          text-align: left;
          padding-left: 32px;
          padding-right: 5px; }
      .pic .icons-item.active-right {
        width: auto;
        z-index: 1;
        height: auto; }
        .pic .icons-item.active-right .icons-item__img {
          opacity: 1; }
        .pic .icons-item.active-right .icons-item__desc {
          width: 200px;
          height: auto;
          transform: scale(1);
          transform-origin: right;
          position: absolute;
          top: -100%;
          left: calc(-200px + 16px);
          background: white;
          z-index: -1;
          text-align: right;
          padding-right: 32px;
          padding-left: 5px; }
      .pic .icons-item__img {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #8f8fa5;
        border: 1px solid rgba(45, 45, 48, 0.3);
        width: 32px;
        height: 32px;
        line-height: 32px;
        border-radius: 50%;
        opacity: .7;
        transform-origin: center;
        transition: .3s all; }
      .pic .icons-item__desc {
        display: block;
        line-height: 1.5;
        height: 32px;
        width: 32px;
        text-align: start;
        border-radius: 8px;
        transition: .3s all ease-in-out;
        transform: scale(0); }

.pic .icons-item-1 {
  bottom: 27.2%;
  left: 36.3%; }

.pic .icons-item-2 {
  bottom: 21.5%;
  left: 36.2%; }

.pic .icons-item-3 {
  bottom: 24.9%;
  left: 42.5%; }

.pic .icons-item-4 {
  bottom: 28.3%;
  left: 51.2%; }

.pic .icons-item-5 {
  top: 67%;
  left: 53.89%; }

.pic .icons-item-6 {
  bottom: 25.9%;
  left: 58.2%; }

.pic .icons-item-7 {
  bottom: 24.6%;
  left: 61.0%; }

.pic .icons-item-8 {
  bottom: 14.66%;
  left: 61.7%; }

.pic .icons-item-9 {
  bottom: 24.3%;
  left: 63.9%; }

.pic .icons-item-10 {
  bottom: 17.2%;
  left: 66.1%; }

.pic .icons-item-11 {
  top: 63%;
  left: 71.5%; }

.pic .icons-item-12 {
  top: 70%;
  left: 78%; }

.pic .icons-item-13 {
  bottom: 28.3%;
  left: 82.5%; }

@media (max-width: 1280px) {
  .pic .icons-item {
    font-size: 16px;
    min-width: 26px;
    height: 26px;
    line-height: 26px;
    width: 26px; }
    .pic .icons-item__img {
      width: 26px;
      height: 26px;
      line-height: 26px; }
    .pic .icons-item__desc {
      width: 26px; } }

@media (max-width: 1024px) {
  .pic .icons-item {
    font-size: 14px;
    min-width: 25px;
    height: 25px;
    line-height: 25px;
    width: 25px; }
    .pic .icons-item__img {
      width: 25px;
      height: 25px;
      line-height: 25px; }
    .pic .icons-item__desc {
      width: 25px; } }

@media (max-width: 768px) {
  .pic .icons-item {
    font-size: 13px;
    min-width: 24px;
    height: 24px;
    line-height: 24px;
    width: 24px; }
    .pic .icons-item__img {
      width: 24px;
      height: 24px;
      line-height: 24px; }
    .pic .icons-item__desc {
      width: 24px; } }

@media (max-width: 640px) {
  .pic .icons-item {
    font-size: 16px;
    min-width: 22px;
    height: 22px;
    line-height: 22px;
    width: 22px; }
    .pic .icons-item__img {
      width: 22px;
      height: 22px;
      line-height: 22px; }
    .pic .icons-item__desc {
      width: 22px; } }

.mattress-type {
  margin: 64px 0; }
  @media (max-width: 1280px) {
    .mattress-type {
      margin: 40px 0; } }
  @media (max-width: 640px) {
    .mattress-type {
      margin: 32px 0; } }

.title-mattress-type {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .title-mattress-type .title h3 {
    text-align: center; }
  .title-mattress-type .menu {
    margin-top: 64px;
    display: flex;
    text-align: center;
    justify-content: space-evenly;
    align-self: center;
    width: 90%;
    flex-wrap: wrap; }
    @media (max-width: 1280px) {
      .title-mattress-type .menu {
        margin-top: 40px; } }
    @media (max-width: 640px) {
      .title-mattress-type .menu {
        margin-top: 32px; } }
    .title-mattress-type .menu__item {
      width: 160px;
      height: 100px;
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      align-items: center; }
    .title-mattress-type .menu p {
      color: #041e42;
      margin-top: 12px;
      white-space: nowrap;
      font-weight: 700;
      text-transform: uppercase; }
    .title-mattress-type .menu img {
      width: 54px;
      height: 54px; }

@media (max-width: 1280px) {
  .title-mattress-type .menu {
    width: 100%;
    justify-content: space-evenly; } }

@media (max-width: 1024px) {
  .title-mattress-type .menu {
    width: 90%;
    justify-content: space-between; } }

@media (max-width: 900px) {
  .title-mattress-type .menu {
    width: 100%;
    justify-content: space-between; } }

@media (max-width: 768px) {
  .title-mattress-type .menu__item {
    width: 50%; } }

.featured-content-master .block-featured {
  margin-top: 128px;
  justify-content: center;
  display: flex;
  flex-direction: row;
  text-align: right; }
  @media (max-width: 1280px) {
    .featured-content-master .block-featured {
      margin-top: 64px; } }
  .featured-content-master .block-featured:nth-child(even) {
    flex-direction: row-reverse;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start; }
    .featured-content-master .block-featured:nth-child(even) .featured-left {
      margin-left: 5%;
      margin-right: 10%; }
  .featured-content-master .block-featured .featured-left {
    max-width: 35%;
    margin-left: 10%;
    margin-right: 5%; }
    .featured-content-master .block-featured .featured-left .block-main-title .title p {
      color: #041E42;
      font-family: "HELVETICA-NEUE", "Segoe UI", sans-serif;
      font-size: 50px;
      font-weight: 500;
      color: #041e42;
      font-weight: 500; }
      @media (max-width: 1024px) {
        .featured-content-master .block-featured .featured-left .block-main-title .title p {
          font-size: 40px; } }
      @media (max-width: 640px) {
        .featured-content-master .block-featured .featured-left .block-main-title .title p {
          font-size: 32px; } }
    .featured-content-master .block-featured .featured-left .block-main-title .content {
      margin: 30px 0; }
      .featured-content-master .block-featured .featured-left .block-main-title .content p {
        color: #707070;
        font-family: "Segoe UI", sans-serif;
        font-size: 20px;
        font-weight: 400;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space: pre-line; }
        @media (max-width: 1024px) {
          .featured-content-master .block-featured .featured-left .block-main-title .content p {
            font-size: 18px; } }
        @media (max-width: 640px) {
          .featured-content-master .block-featured .featured-left .block-main-title .content p {
            font-size: 16px; } }
    .featured-content-master .block-featured .featured-left .featured-right .block-pic .pic {
      width: 660px;
      height: 400px;
      margin: 0 auto;
      transition: 0.5s all ease-in-out;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }

@media (max-width: 1024px) {
  .featured-content-master .block-featured .featured-left {
    max-width: 40%;
    margin-left: 5%; }
  .featured-content-master .block-featured:nth-child(even) .featured-left {
    margin-right: 5%; } }

@media (max-width: 768px) {
  .featured-content-master .block-featured {
    flex-direction: column-reverse; }
  .featured-content-master .block-featured:nth-child(even) {
    flex-direction: column-reverse; }
  .featured-content-master .block-featured .featured-left {
    max-width: 100%;
    text-align: start;
    margin-left: 0;
    margin-right: 0;
    padding: 0px 10% 0 10%; }
  .featured-content-master .featured-right .block-pic {
    padding: 16px 10% 0 10%; }
  .featured-content-master .mattress-type {
    margin-top: 56px;
    margin-bottom: 56px; } }

@media (max-width: 425px) {
  .featured-content-master .block-featured .featured-left {
    padding: 0px; }
  .featured-content-master .featured-right .block-pic {
    padding: 12px 0 0 0; }
  .featured-content-master .mattress-type {
    margin-top: 56px;
    margin-bottom: 56px; } }

.advertise .advertise-content {
  position: relative;
  text-align: center;
  width: 100%; }

.advertise .pic {
  height: 100%;
  width: 100%; }

.advertise .advertise-pic a {
  display: block; }

.advertise .advertise-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  min-height: 560px; }

.advertise .advertise-text {
  width: 96%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  color: #041e42;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: inline-flex;
  flex-direction: column;
  align-items: center; }
  @media (min-width: 576px) {
    .advertise .advertise-text {
      max-width: 95%; } }
  @media (min-width: 1025px) {
    .advertise .advertise-text {
      max-width: 900px; } }
  @media (min-width: 1280px) {
    .advertise .advertise-text {
      max-width: 1200px;
      padding-right: 3%;
      padding-left: 3%; } }
  @media (min-width: 1440px) {
    .advertise .advertise-text {
      max-width: 1280px;
      padding-right: 3%;
      padding-left: 3%; } }
  @media (min-width: 1600px) {
    .advertise .advertise-text {
      max-width: 1366px;
      padding-right: 3%;
      padding-left: 3%; } }
  @media (min-width: 1800px) {
    .advertise .advertise-text {
      max-width: 1640px;
      padding-right: 5%;
      padding-left: 5%; } }

.advertise .header {
  color: #041E42;
  font-family: "HELVETICA-NEUE", "Segoe UI", sans-serif;
  font-size: 50px;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 40px;
  width: 60%;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 7px; }
  @media (max-width: 1024px) {
    .advertise .header {
      font-size: 40px; } }
  @media (max-width: 640px) {
    .advertise .header {
      font-size: 32px; } }
  @media (max-width: 1280px) {
    .advertise .header {
      margin-bottom: 32px; } }
  @media (max-width: 640px) {
    .advertise .header {
      margin-bottom: 12px; } }

.advertise .content {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 40px 0;
  color: #041E42;
  font-family: "HELVETICA-NEUE", "Segoe UI", sans-serif;
  font-size: 30px;
  font-weight: 500;
  font-weight: bold;
  text-align: center;
  width: 70%;
  letter-spacing: 1px;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.3); }
  @media (max-width: 1280px) {
    .advertise .content {
      margin-bottom: 32px 0; } }
  @media (max-width: 640px) {
    .advertise .content {
      margin-bottom: 12px 0; } }
  @media (max-width: 1024px) {
    .advertise .content {
      font-size: 26px; } }
  @media (max-width: 640px) {
    .advertise .content {
      font-size: 22px; } }

.advertise .readmore a {
  color: #041e42;
  font-weight: bold;
  padding: 15px 25px;
  border-radius: 8px;
  border: 4px solid #041e42;
  display: inline-block;
  font-size: 26px; }

.advertise .readmore a:hover {
  background-color: #041e42;
  color: white; }

@media (max-width: 1280px) {
  .advertise .header {
    width: 90%; }
  .advertise .content {
    width: 80%; } }

@media (max-width: 1024px) {
  .advertise .readmore a {
    padding: 12px 20px;
    font-size: 21px;
    border: 3px solid #041e42; } }

@media (max-width: 640px) {
  .advertise .advertise-text {
    padding: 0; }
  .advertise .header {
    width: 100%; }
  .advertise .content {
    width: 100%; }
  .advertise .readmore a {
    padding: 8px 16px;
    font-size: 20px;
    border: 2px solid #041e42; }
  .advertise .advertise-pic img {
    min-height: 420px; } }

.our-tech {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 128px 0; }
  @media (max-width: 1280px) {
    .our-tech {
      margin: 64px 0; } }
  @media (max-width: 640px) {
    .our-tech {
      margin: 0; } }
  .our-tech .title-our-technology .title {
    color: #041E42;
    font-family: "HELVETICA-NEUE", "Segoe UI", sans-serif;
    font-size: 50px;
    font-weight: 500;
    margin-bottom: 64px; }
    @media (max-width: 1024px) {
      .our-tech .title-our-technology .title {
        font-size: 40px; } }
    @media (max-width: 640px) {
      .our-tech .title-our-technology .title {
        font-size: 32px; } }
    @media (max-width: 1280px) {
      .our-tech .title-our-technology .title {
        margin-bottom: 40px; } }
    @media (max-width: 640px) {
      .our-tech .title-our-technology .title {
        margin-bottom: 32px; } }
  .our-tech .our-tech-box-logo {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center; }
    .our-tech .our-tech-box-logo .box {
      background-color: #d9d9d6;
      width: 1024px;
      padding: 40px 0;
      height: 540px;
      position: relative; }
      @media (max-width: 1280px) {
        .our-tech .our-tech-box-logo .box {
          padding: 32px 0; } }
      @media (max-width: 640px) {
        .our-tech .our-tech-box-logo .box {
          padding: 12px 0; } }
      .our-tech .our-tech-box-logo .box .swiper-pagination {
        top: 100%;
        left: 50%;
        transform: translateX(-50%); }
        .our-tech .our-tech-box-logo .box .swiper-pagination-bullet {
          margin: 0 12px;
          position: relative; }
          .our-tech .our-tech-box-logo .box .swiper-pagination-bullet-active {
            background-color: #977; }
          .our-tech .our-tech-box-logo .box .swiper-pagination-bullet-active::before {
            content: "";
            position: absolute;
            width: 5px;
            height: 5px;
            background-color: #041E42;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%; }
      .our-tech .our-tech-box-logo .box .box-content {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1100px;
        transform: translate(-50%, -50%); }
        .our-tech .our-tech-box-logo .box .box-content .swiper-slide .box-each .box-logo {
          position: relative; }
          .our-tech .our-tech-box-logo .box .box-content .swiper-slide .box-each .box-logo::after {
            position: absolute;
            content: "";
            bottom: -35px;
            left: 50%;
            transform: translateX(-50%);
            height: 3px;
            width: 0%;
            transform-origin: left;
            background: #041e42; }
        .our-tech .our-tech-box-logo .box .box-content .swiper-slide-active .box-each .box-logo {
          position: relative; }
          .our-tech .our-tech-box-logo .box .box-content .swiper-slide-active .box-each .box-logo::after {
            position: absolute;
            content: "";
            bottom: -35px;
            left: 50%;
            transform: translateX(-50%);
            height: 3px;
            background: #041e42;
            animation: widthRight 0.4s ease forwards; }
        .our-tech .our-tech-box-logo .box .box-content .box-each {
          margin: 0 auto;
          width: 315px; }
          .our-tech .our-tech-box-logo .box .box-content .box-each .box-logo {
            background-color: white;
            width: 315px;
            margin-left: 1px;
            height: 150px;
            box-shadow: 0px 11px 6px rgba(0, 0, 0, 0.3); }
            .our-tech .our-tech-box-logo .box .box-content .box-each .box-logo img {
              height: inherit; }
          .our-tech .our-tech-box-logo .box .box-content .box-each .box-description {
            margin-top: 64px;
            padding: 0 17px;
            color: #041e42; }
            @media (max-width: 1280px) {
              .our-tech .our-tech-box-logo .box .box-content .box-each .box-description {
                margin-top: 40px; } }
            @media (max-width: 640px) {
              .our-tech .our-tech-box-logo .box .box-content .box-each .box-description {
                margin-top: 32px; } }
            .our-tech .our-tech-box-logo .box .box-content .box-each .box-description .tittle-description {
              text-transform: uppercase;
              font-weight: 500;
              padding-top: 12px; }
            .our-tech .our-tech-box-logo .box .box-content .box-each .box-description .tittle-content {
              padding-top: 12px; }

.tech-youtube__wrapper {
  width: 100%;
  height: 100vh;
  margin: 128px 0; }
  @media (max-width: 1024px) {
    .tech-youtube__wrapper {
      max-height: 640px !important; } }
  @media (max-width: 640px) {
    .tech-youtube__wrapper {
      max-height: 520px !important; } }
  @media (max-width: 1280px) {
    .tech-youtube__wrapper {
      margin: 64px 0; } }
  @media (max-width: 640px) {
    .tech-youtube__wrapper {
      margin: 0; } }
  .tech-youtube__wrapper #player,
  .tech-youtube__wrapper iframe {
    width: 100% !important;
    pointer-events: none; }

.AS-container {
  background-color: #041e42;
  color: #C0C0C0; }

.AS-header {
  color: #041E42;
  font-family: "HELVETICA-NEUE", "Segoe UI", sans-serif;
  font-size: 50px;
  font-weight: 500;
  font-family: inherit;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: inherit; }
  @media (max-width: 1024px) {
    .AS-header {
      font-size: 40px; } }
  @media (max-width: 640px) {
    .AS-header {
      font-size: 32px; } }
  @media (max-width: 1280px) {
    .AS-header {
      padding-top: 40px; } }
  @media (max-width: 640px) {
    .AS-header {
      padding-top: 32px; } }

.AS-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  letter-spacing: 1px; }
  .AS-content-1 {
    color: #041E42;
    font-family: "HELVETICA-NEUE", "Segoe UI", sans-serif;
    font-size: 50px;
    font-weight: 500;
    font-family: inherit;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    width: 70%;
    margin-inline: 5px;
    padding: 0 12px;
    color: inherit; }
    @media (max-width: 1024px) {
      .AS-content-1 {
        font-size: 40px; } }
    @media (max-width: 640px) {
      .AS-content-1 {
        font-size: 32px; } }
    @media (max-width: 1280px) {
      .AS-content-1 {
        margin-top: 32px; } }
    @media (max-width: 640px) {
      .AS-content-1 {
        margin-top: 12px; } }
    .AS-content-1 .before, .AS-content-1 .after {
      height: fit-content;
      width: 48px;
      height: 48px;
      object-fit: contain;
      padding-right: 12px;
      margin-top: -16px; }
    .AS-content-1 .before {
      align-items: flex-start; }
    .AS-content-1 .after {
      transform: rotate(180deg) translateY(-16px);
      align-self: flex-end; }
    .AS-content-1 p {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden; }
  .AS-content-2 {
    color: #707070;
    font-family: "Segoe UI", sans-serif;
    font-size: 20px;
    font-weight: 400;
    font-weight: 300;
    margin-top: 40px;
    color: inherit;
    font-style: italic; }
    @media (max-width: 1024px) {
      .AS-content-2 {
        font-size: 18px; } }
    @media (max-width: 640px) {
      .AS-content-2 {
        font-size: 16px; } }
    @media (max-width: 1280px) {
      .AS-content-2 {
        margin-top: 32px; } }
    @media (max-width: 640px) {
      .AS-content-2 {
        margin-top: 12px; } }
    .AS-content-2 p {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden; }
  .AS-content-3 {
    margin-top: 40px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 10px;
    border-radius: 5px;
    background-color: transparent;
    border: 3px solid #C0C0C0;
    display: inline-block;
    text-transform: inherit; }
    @media (max-width: 1280px) {
      .AS-content-3 {
        margin-top: 32px; } }
    @media (max-width: 640px) {
      .AS-content-3 {
        margin-top: 12px; } }
    .AS-content-3 a {
      color: #C0C0C0; }
    .AS-content-3:hover {
      border: 3px solid #041E42;
      background-color: #C0C0C0; }
      .AS-content-3:hover a {
        color: #041E42; }

.AS-footer {
  padding: 64px 0; }
  @media (max-width: 1280px) {
    .AS-footer {
      padding: 40px 0; } }
  @media (max-width: 640px) {
    .AS-footer {
      padding: 32px 0; } }
  .AS-footer .AS-logo {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
    width: 100%; }
    .AS-footer .AS-logo .AS-logo-slogan {
      object-fit: contain;
      object-position: center;
      flex: 1;
      width: 170px;
      height: 61px; }
      .AS-footer .AS-logo .AS-logo-slogan:not(:first-child) {
        border-left: 3px solid rgba(236, 236, 236, 0.3); }

@media (max-width: 1024px) {
  .AS-content-1 {
    width: 95%; }
  .AS-content-1 .before, .AS-content-1 .after {
    width: 36px;
    height: 36px; } }

@media (max-width: 640px) {
  .AS-content-1 {
    width: 95%; }
  .AS-content-1 .before, .AS-content-1 .after {
    width: 26px;
    height: 26px; } }

@media (max-width: 768px) {
  .AS-footer .AS-logo .AS-logo-slogan {
    margin: 24px;
    flex: initial; }
    .AS-footer .AS-logo .AS-logo-slogan:not(:first-child) {
      border-left: 0px solid rgba(236, 236, 236, 0.3); } }

.mattress-collections__wrapper {
  background: #ECECEC; }
  .mattress-collections__wrapper .container {
    text-align: center; }
    .mattress-collections__wrapper .container .mattress-collections {
      padding: 64px 0; }
      @media (max-width: 1280px) {
        .mattress-collections__wrapper .container .mattress-collections {
          padding: 40px 0; } }
      @media (max-width: 640px) {
        .mattress-collections__wrapper .container .mattress-collections {
          padding: 32px 0; } }
      .mattress-collections__wrapper .container .mattress-collections .collections-section {
        margin: 64px 0; }
        @media (max-width: 1280px) {
          .mattress-collections__wrapper .container .mattress-collections .collections-section {
            margin: 40px 0; } }
        @media (max-width: 640px) {
          .mattress-collections__wrapper .container .mattress-collections .collections-section {
            margin: 32px 0; } }
        .mattress-collections__wrapper .container .mattress-collections .collections-section .options {
          color: #041E42;
          font-family: "HELVETICA-NEUE", "Segoe UI", sans-serif;
          font-size: 30px;
          font-weight: 500;
          color: #041E42;
          padding: 10px 20px;
          cursor: pointer;
          display: inline-block; }
          @media (max-width: 1024px) {
            .mattress-collections__wrapper .container .mattress-collections .collections-section .options {
              font-size: 26px; } }
          @media (max-width: 640px) {
            .mattress-collections__wrapper .container .mattress-collections .collections-section .options {
              font-size: 22px; } }
          .mattress-collections__wrapper .container .mattress-collections .collections-section .options + .options {
            margin-left: 120px;
            position: relative; }
            .mattress-collections__wrapper .container .mattress-collections .collections-section .options + .options::after {
              position: absolute;
              content: "";
              top: 50%;
              left: -60px;
              height: 60%;
              transform: translateY(-50%);
              width: 2px;
              background: #041E42; }
        .mattress-collections__wrapper .container .mattress-collections .collections-section .active {
          color: #041E42;
          font-family: "HELVETICA-NEUE", "Segoe UI", sans-serif;
          font-size: 32px;
          font-weight: 500;
          border: solid 2px #041E42;
          border-radius: 6px; }
          @media (max-width: 1024px) {
            .mattress-collections__wrapper .container .mattress-collections .collections-section .active {
              font-size: 28px; } }
          @media (max-width: 640px) {
            .mattress-collections__wrapper .container .mattress-collections .collections-section .active {
              font-size: 24px; } }
        .mattress-collections__wrapper .container .mattress-collections .collections-section .collections__products {
          padding: 64px 0;
          display: flex;
          align-items: center; }
          .mattress-collections__wrapper .container .mattress-collections .collections-section .collections__products img {
            width: 57%; }
          @media (max-width: 1280px) {
            .mattress-collections__wrapper .container .mattress-collections .collections-section .collections__products {
              padding: 40px 0; } }
          @media (max-width: 640px) {
            .mattress-collections__wrapper .container .mattress-collections .collections-section .collections__products {
              padding: 32px 0; } }
          .mattress-collections__wrapper .container .mattress-collections .collections-section .collections__products .product-infor {
            width: 43%;
            text-align: start;
            padding: 0 20px;
            font-size: inherit; }
      .mattress-collections__wrapper .container .mattress-collections .collections-scroll {
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(270deg);
        text-transform: uppercase;
        font-size: 16px;
        font-family: SEGUISB;
        color: #707070;
        opacity: 0.7;
        user-select: none;
        -moz-user-select: none;
        margin-bottom: 10px; }
        .mattress-collections__wrapper .container .mattress-collections .collections-scroll img {
          transform: rotate(90deg);
          margin-right: 20px;
          margin-top: 3px; }
        .mattress-collections__wrapper .container .mattress-collections .collections-scroll .text-scroll {
          background-size: 200%;
          background-image: linear-gradient(to right, rgba(255, 255, 255, 0.1), #041E42);
          background-clip: text;
          -webkit-text-fill-color: transparent;
          animation: gradient 1.3s;
          animation-iteration-count: infinite; }

@keyframes gradient {
  100% {
    background-position: 200%; } }

@media (max-width: 768px) {
  .collections__products {
    flex-direction: column; }
  .mattress-collections__wrapper .container .mattress-collections .collections-section .collections__products img {
    width: 100%; }
  .mattress-collections__wrapper .container .mattress-collections .collections-section .collections__products .product-infor {
    width: 100%;
    margin-top: 12px; }
  .mattress-collections__wrapper .container .mattress-collections .collections-scroll {
    display: inline-flex; } }

.mattress-recommend__wrapper {
  padding: 128px 0; }
  @media (max-width: 1280px) {
    .mattress-recommend__wrapper {
      padding: 64px 0; } }
  @media (max-width: 640px) {
    .mattress-recommend__wrapper {
      padding: 0; } }
  .mattress-recommend__wrapper .options-recommend {
    display: flex;
    align-items: center;
    margin-bottom: 40px; }
    @media (max-width: 1280px) {
      .mattress-recommend__wrapper .options-recommend {
        margin-bottom: 32px; } }
    @media (max-width: 640px) {
      .mattress-recommend__wrapper .options-recommend {
        margin-bottom: 12px; } }
    .mattress-recommend__wrapper .options-recommend__link {
      padding: 5px 25px;
      font-weight: 700;
      cursor: pointer;
      background: #ECECEC;
      color: #041E42; }
      .mattress-recommend__wrapper .options-recommend__link:first-child {
        border-radius: 5px 0px 0px 5px; }
      .mattress-recommend__wrapper .options-recommend__link:last-child {
        border-radius: 0px 5px 5px 0px; }
    .mattress-recommend__wrapper .options-recommend .active {
      background: #041E42;
      color: #ffffff; }
  .mattress-recommend__wrapper .mattress-recommend .left-recommend {
    padding: 0;
    padding-right: 64px; }
    @media (max-width: 1280px) {
      .mattress-recommend__wrapper .mattress-recommend .left-recommend {
        padding-right: 40px; } }
    @media (max-width: 640px) {
      .mattress-recommend__wrapper .mattress-recommend .left-recommend {
        padding-right: 32px; } }
    .mattress-recommend__wrapper .mattress-recommend .left-recommend .product-infor .product-infor__more {
      padding-top: 0;
      border-top: 0; }
      .mattress-recommend__wrapper .mattress-recommend .left-recommend .product-infor .product-infor__more span {
        font-weight: 400; }
    .mattress-recommend__wrapper .mattress-recommend .left-recommend .more-wrapper {
      padding-top: 40px; }
      @media (max-width: 1280px) {
        .mattress-recommend__wrapper .mattress-recommend .left-recommend .more-wrapper {
          padding-top: 32px; } }
      @media (max-width: 640px) {
        .mattress-recommend__wrapper .mattress-recommend .left-recommend .more-wrapper {
          padding-top: 12px; } }
  .mattress-recommend__wrapper .mattress-recommend .right-recommend .swiper-btn__nav .swiper-button-prev {
    left: -40px;
    padding: 7px; }
  .mattress-recommend__wrapper .mattress-recommend .right-recommend .swiper-btn__nav .swiper-button-next {
    right: -40px;
    padding: 7px; }

@media (max-width: 768px) {
  .mattress-recommend__wrapper .mattress-recommend {
    padding: 0 24px; }
  .mattress-recommend__wrapper .mattress-recommend .left-recommend .more-wrapper {
    padding: 40px 0; } }
  @media (max-width: 768px) and (max-width: 1280px) {
    .mattress-recommend__wrapper .mattress-recommend .left-recommend .more-wrapper {
      padding: 32px 0; } }
  @media (max-width: 768px) and (max-width: 640px) {
    .mattress-recommend__wrapper .mattress-recommend .left-recommend .more-wrapper {
      padding: 12px 0; } }

.mattress-recommend-video__wrapper {
  padding: 128px 0;
  background: #041E42;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; }
  @media (max-width: 1280px) {
    .mattress-recommend-video__wrapper {
      padding: 64px 0; } }
  @media (max-width: 640px) {
    .mattress-recommend-video__wrapper {
      padding: 0; } }
  .mattress-recommend-video__wrapper .mattress-recommend-video {
    display: flex;
    align-items: center; }
    .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video {
      width: 50%; }
      .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video .product-infor {
        padding-right: 64px; }
        @media (max-width: 1280px) {
          .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video .product-infor {
            padding-right: 40px; } }
        @media (max-width: 640px) {
          .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video .product-infor {
            padding-right: 32px; } }
        .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video .product-infor > * {
          color: #C0C0C0; }
        .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video .product-infor span {
          text-transform: uppercase;
          font-weight: 400; }
        .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video .product-infor .product-infor__title {
          margin-top: 40px; }
          @media (max-width: 1280px) {
            .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video .product-infor .product-infor__title {
              margin-top: 32px; } }
          @media (max-width: 640px) {
            .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video .product-infor .product-infor__title {
              margin-top: 12px; } }
  .mattress-recommend-video__wrapper .right-recommend-video {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 50%;
    height: auto; }
    .mattress-recommend-video__wrapper .right-recommend-video .video-section img {
      object-fit: contain;
      object-position: center;
      width: 100%;
      padding: 0 24px; }

@media (max-width: 1024px) {
  .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video {
    width: 100%; }
  .mattress-recommend-video__wrapper {
    flex-direction: column; }
  .mattress-recommend-video__wrapper .right-recommend-video {
    transform: none;
    position: static;
    top: initial;
    right: initial;
    width: 96%; }
  .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video .product-infor {
    padding: 0; } }

.about-story-down__wrapper .btn a {
  margin-top: 40px;
  padding: 8px 16px 8px 5px;
  border: solid 2px #041e42;
  border-radius: 5px;
  cursor: pointer; }
  @media (max-width: 1280px) {
    .about-story-down__wrapper .btn a {
      margin-top: 32px; } }
  @media (max-width: 640px) {
    .about-story-down__wrapper .btn a {
      margin-top: 12px; } }

.about-story-down__wrapper .our-story-down {
  margin: 64px 0;
  display: flex;
  justify-content: center; }
  @media (max-width: 1280px) {
    .about-story-down__wrapper .our-story-down {
      margin: 40px 0; } }
  @media (max-width: 640px) {
    .about-story-down__wrapper .our-story-down {
      margin: 32px 0; } }
  .about-story-down__wrapper .our-story-down__title {
    flex: 0 0 40%; }
    .about-story-down__wrapper .our-story-down__title--heading {
      text-align: left;
      padding-right: 20%; }
  .about-story-down__wrapper .our-story-down__content {
    flex: 0 0 60%; }
    .about-story-down__wrapper .our-story-down__content--padding {
      padding-right: 20%; }
    .about-story-down__wrapper .our-story-down__content .btn-arrow.btn-arrow--white {
      border-radius: 12px;
      padding-left: 12px;
      border: 2px solid #041E42;
      box-shadow: 0 0 5px rgba(4, 30, 66, 0.3); }
  .about-story-down__wrapper .our-story-down__paragraph {
    position: relative; }
    .about-story-down__wrapper .our-story-down__paragraph::before {
      position: absolute;
      content: "";
      background-image: url("../assets/icons/our-story.png");
      background-size: contain;
      width: 48px;
      height: 48px;
      top: calc(100% - 24px);
      left: 100%;
      transform-origin: left; }

@media (max-width: 1024px) {
  .about-story-down__wrapper .our-story-down {
    flex-direction: column; }
    .about-story-down__wrapper .our-story-down__paragraph {
      margin: 18px 0; }
      .about-story-down__wrapper .our-story-down__paragraph::before {
        width: 36px;
        height: 36px;
        top: calc(100% - 18px); }
    .about-story-down__wrapper .our-story-down__content--padding {
      padding-right: 36px; } }

@media (max-width: 425px) {
  .about-story-down__wrapper .our-story-down__title--heading {
    padding-right: initial;
    text-align: center; }
  .about-story-down__wrapper .our-story-down__paragraph {
    margin: 18px 0; }
  .about-story-down__wrapper .our-story-down__content--padding {
    padding-right: 36px; }
  .about-story-down__wrapper .btn .btn-arrow.btn-arrow--white {
    font-size: 14px;
    padding: 8px 8px; } }

.about-story-business__wrapper {
  background-color: #ECECEC; }
  .about-story-business__wrapper .our-story-business {
    padding: 64px 0; }
    @media (max-width: 1280px) {
      .about-story-business__wrapper .our-story-business {
        padding: 40px 0; } }
    @media (max-width: 640px) {
      .about-story-business__wrapper .our-story-business {
        padding: 32px 0; } }
    .about-story-business__wrapper .our-story-business__list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%; }
    .about-story-business__wrapper .our-story-business__item {
      width: 18%;
      display: flex;
      flex-direction: column;
      margin-top: 64px; }
      @media (max-width: 1280px) {
        .about-story-business__wrapper .our-story-business__item {
          margin-top: 40px; } }
      @media (max-width: 640px) {
        .about-story-business__wrapper .our-story-business__item {
          margin-top: 32px; } }
      .about-story-business__wrapper .our-story-business__item-img {
        width: 65px;
        height: 65px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
        margin-bottom: 40px; }
        @media (max-width: 1280px) {
          .about-story-business__wrapper .our-story-business__item-img {
            margin-bottom: 32px; } }
        @media (max-width: 640px) {
          .about-story-business__wrapper .our-story-business__item-img {
            margin-bottom: 12px; } }
      .about-story-business__wrapper .our-story-business__item-desc {
        margin-top: 8px;
        color: #707070; }

@media (max-width: 1024px) {
  .about-story-business__wrapper .our-story-business__item {
    flex-basis: 45%; }
    .about-story-business__wrapper .our-story-business__item-img {
      margin-bottom: 32px; }
    .about-story-business__wrapper .our-story-business__item-desc {
      width: 80%; }
  .about-story-business__wrapper .our-story-business {
    padding: 36px 0; } }

@media (max-width: 425px) {
  .about-story-business__wrapper .our-story-business__title--heading {
    text-align: center; }
  .about-story-business__wrapper .our-story-business__item {
    flex-basis: 100%;
    justify-content: center;
    align-items: center; }
    .about-story-business__wrapper .our-story-business__item-desc {
      text-align: center; } }

.about-story-our__wrapper {
  margin: 64px 0; }
  @media (max-width: 1280px) {
    .about-story-our__wrapper {
      margin: 40px 0; } }
  @media (max-width: 640px) {
    .about-story-our__wrapper {
      margin: 32px 0; } }
  .about-story-our__wrapper .about-story-our__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .about-story-our__wrapper .about-story-our__item {
    width: 100%; }
    .about-story-our__wrapper .about-story-our__item .our-item {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center; }
      .about-story-our__wrapper .about-story-our__item .our-item__img {
        height: 383px;
        flex-basis: 50%;
        width: 50%;
        object-fit: cover;
        object-position: center; }
      .about-story-our__wrapper .about-story-our__item .our-item__content {
        flex-basis: 50%; }
        .about-story-our__wrapper .about-story-our__item .our-item__content-title {
          font-weight: 500; }
        .about-story-our__wrapper .about-story-our__item .our-item__content-desc {
          margin: 40px 0;
          -webkit-line-clamp: 3; }
          @media (max-width: 1280px) {
            .about-story-our__wrapper .about-story-our__item .our-item__content-desc {
              margin: 32px 0; } }
          @media (max-width: 640px) {
            .about-story-our__wrapper .about-story-our__item .our-item__content-desc {
              margin: 12px 0; } }
        .about-story-our__wrapper .about-story-our__item .our-item__content-caption {
          font-style: italic;
          font-weight: 500;
          color: #707070; }
    .about-story-our__wrapper .about-story-our__item:nth-child(2n) .our-item {
      flex-direction: row-reverse; }
      .about-story-our__wrapper .about-story-our__item:nth-child(2n) .our-item__content {
        padding-right: 64px; }
    .about-story-our__wrapper .about-story-our__item:not(:nth-child(2n)) .our-item__content {
      padding-left: 64px; }
    .about-story-our__wrapper .about-story-our__item:not(:first-child) {
      margin-top: 28px; }

@media (max-width: 1024px) {
  .about-story-our__wrapper .about-story-our__item:nth-child(2n) .our-item__content {
    padding-right: 40px; }
  .about-story-our__wrapper .about-story-our__item:not(:nth-child(2n)) .our-item__content {
    padding-left: 40px; } }

@media (max-width: 640px) {
  .about-story-our__wrapper .about-story-our__item:nth-child(2n) .our-item__content {
    padding: 32px 12px; }
  .about-story-our__wrapper .about-story-our__item:not(:nth-child(2n)) .our-item__content {
    padding: 32px 12px; }
  .about-story-our__wrapper .about-story-our__item .our-item {
    background-color: #ECECEC; }
    .about-story-our__wrapper .about-story-our__item .our-item__content {
      flex-basis: 100%;
      width: 100%; }
    .about-story-our__wrapper .about-story-our__item .our-item__img {
      flex-basis: 100%;
      width: 100%; } }

.about-story-mg__wrapper {
  background-color: #ECECEC; }
  .about-story-mg__wrapper .about-story-global {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto; }
    .about-story-mg__wrapper .about-story-global__title {
      width: 56%; }
    .about-story-mg__wrapper .about-story-global__paragraph {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      width: 56%; }
    .about-story-mg__wrapper .about-story-global__img {
      margin: 128px 0;
      position: relative; }
      @media (max-width: 1280px) {
        .about-story-mg__wrapper .about-story-global__img {
          margin: 64px 0; } }
      @media (max-width: 640px) {
        .about-story-mg__wrapper .about-story-global__img {
          margin: 0; } }
      .about-story-mg__wrapper .about-story-global__img--bg {
        min-width: 1200px;
        width: 1200px;
        max-width: 1200px; }
      .about-story-mg__wrapper .about-story-global__img-list {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
      .about-story-mg__wrapper .about-story-global__img--icon {
        position: absolute;
        width: 160px; }
      .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(1) {
        top: 7%;
        left: 8%; }
      .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(2) {
        top: 45%;
        left: 30%; }
      .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(3) {
        top: 18%;
        left: 47%; }
      .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(4) {
        top: -4%;
        left: 75%; }
      .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(5) {
        top: 59%;
        left: 88%; }
  .about-story-mg__wrapper .about-story-mg__title {
    padding-top: 128px;
    padding-bottom: 64px;
    text-align: center; }
    @media (max-width: 1280px) {
      .about-story-mg__wrapper .about-story-mg__title {
        padding-top: 64px; } }
    @media (max-width: 1280px) {
      .about-story-mg__wrapper .about-story-mg__title {
        padding-bottom: 40px; } }
    @media (max-width: 640px) {
      .about-story-mg__wrapper .about-story-mg__title {
        padding-bottom: 32px; } }

@media (max-width: 1440px) {
  .about-story-mg__wrapper .about-story-mg__title,
  .about-story-mg__wrapper .about-story-global__paragraph {
    width: 72%; }
  .about-story-mg__wrapper .about-story-global__paragraph {
    -webkit-line-clamp: 4; }
  .about-story-mg__wrapper .about-story-global__img--bg {
    min-width: 900px;
    width: 900px;
    max-width: 900px; }
  .about-story-mg__wrapper .about-story-global__img--icon {
    width: 156px; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(1) {
    top: 0%;
    left: 7%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(2) {
    top: 38%;
    left: 29.5%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(3) {
    top: 15%;
    left: 47%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(4) {
    top: -10%;
    left: 75%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(5) {
    top: 52%;
    left: 87%; } }

@media (max-width: 1024px) {
  .about-story-mg__wrapper .about-story-global__img--bg {
    min-width: 720px;
    width: 720px;
    max-width: 720px; }
  .about-story-mg__wrapper .about-story-global__img--icon {
    width: 120px; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(1) {
    top: 1.5%;
    left: 7%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(2) {
    top: 38%;
    left: 29.5%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(3) {
    top: 15%;
    left: 47%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(4) {
    top: -10%;
    left: 75%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(5) {
    top: 52%;
    left: 87%; } }

@media (max-width: 768px) {
  .about-story-mg__wrapper .about-story-global__img--bg {
    min-width: 540px;
    width: 540px;
    max-width: 540px; }
  .about-story-mg__wrapper .about-story-global__img--icon {
    width: 96px; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(1) {
    top: 0%;
    left: 7%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(2) {
    top: 38%;
    left: 29.5%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(3) {
    top: 15%;
    left: 47%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(4) {
    top: -10%;
    left: 75%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(5) {
    top: 52%;
    left: 87%; } }

@media (max-width: 640px) {
  .about-story-mg__wrapper .about-story-global__img--bg {
    min-width: 360px;
    width: 360px;
    max-width: 360px; }
  .about-story-mg__wrapper .about-story-global__img--icon {
    width: 64px; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(1) {
    top: 0%;
    left: 7%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(2) {
    top: 38%;
    left: 29.5%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(3) {
    top: 15%;
    left: 47%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(4) {
    top: -10%;
    left: 75%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(5) {
    top: 52%;
    left: 87%; } }

@media (max-width: 425px) {
  .about-story-mg__wrapper .about-story-global__img--bg {
    min-width: 300px;
    width: 300px;
    max-width: 300px; }
  .about-story-mg__wrapper .about-story-global__img--icon {
    width: 52px; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(1) {
    top: 7%;
    left: 8%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(2) {
    top: 38%;
    left: 29.5%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(3) {
    top: 15%;
    left: 47%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(4) {
    top: -10%;
    left: 75%; }
  .about-story-mg__wrapper .about-story-global__img--icon:nth-of-type(5) {
    top: 52%;
    left: 87%; } }

@media (max-width: 1280px) {
  .about-story-mg__wrapper .about-story-mg__title,
  .about-story-mg__wrapper .about-story-global__paragraph {
    width: 88%;
    -webkit-line-clamp: 5; } }

.process-year {
  display: flex;
  flex-direction: row;
  height: 590px;
  align-items: center; }
  .process-year-item {
    flex: 1;
    position: relative; }
    .process-year-item.active .process-year-item__number::before {
      background-color: #041E42; }
    .process-year-item.active .process-year-item__number--year {
      color: white; }
    .process-year-item:not(:first-child) .process-year-item--dash {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 0;
      transform: translate(-100%, -50%);
      border-top: 2px dashed #041E42;
      z-index: 1; }
    .process-year-item--center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative; }
    .process-year-item__content {
      position: absolute;
      top: -320%;
      left: 50%;
      width: 150%;
      transform: translate(-50%, -100%);
      color: #041E42;
      text-align: center; }
      .process-year-item__content--title {
        width: 100%;
        margin-bottom: 24px;
        color: #041E42;
        font-family: "HELVETICA-NEUE", "Segoe UI", sans-serif;
        font-size: 20px;
        font-weight: 500;
        font-family: inherit;
        -webkit-line-clamp: 1;
        font-weight: 700;
        text-transform: uppercase; }
        @media (max-width: 1024px) {
          .process-year-item__content--title {
            font-size: 19px; } }
        @media (max-width: 640px) {
          .process-year-item__content--title {
            font-size: 18px; } }
      .process-year-item__content--desc {
        width: 100%;
        font-family: inherit;
        -webkit-line-clamp: 2; }
    .process-year-item:nth-of-type(2n) .process-year-item__content {
      position: absolute;
      top: 420%;
      left: 50%;
      transform: translate(-50%, 0%);
      width: 150%; }
    .process-year-item:nth-of-type(2n) .process-year-item__icon {
      top: -60px; }
    .process-year-item:nth-of-type(2n) .process-year-item__number--solid {
      top: initial;
      bottom: 0;
      left: 50%;
      transform: rotate(90deg); }
    .process-year-item__number {
      position: relative;
      z-index: 2;
      padding: 8px 12px;
      cursor: pointer;
      transition: color 0.3s linear; }
      .process-year-item__number--year {
        color: #041E42;
        font-family: "HELVETICA-NEUE", "Segoe UI", sans-serif;
        font-size: 20px;
        font-weight: 500;
        font-family: inherit;
        font-weight: bold;
        text-align: center;
        color: #041E42; }
        @media (max-width: 1024px) {
          .process-year-item__number--year {
            font-size: 19px; } }
        @media (max-width: 640px) {
          .process-year-item__number--year {
            font-size: 18px; } }
      .process-year-item__number::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        transform: skew(20deg);
        border-top: 2px solid #041E42;
        border-bottom: 2px solid #041E42;
        border-left: 2px solid #041E42;
        border-right: 2px solid #041E42;
        background-color: #ECECEC;
        transition: background 0.3s linear; }
      .process-year-item__number--solid {
        position: absolute;
        background-color: #041E42;
        transform-origin: left;
        width: 150%;
        height: 1px;
        top: 0;
        left: 50%;
        transform: rotate(-90deg); }
        .process-year-item__number--solid::before {
          content: "";
          position: absolute;
          top: 0;
          left: 100%;
          width: 8px;
          height: 8px;
          transform: translate(0%, -50%);
          border-radius: 50%;
          background-color: #041e42; }
    .process-year-item__icon {
      position: absolute;
      top: 60px;
      left: 50%;
      transform: translateX(-50%);
      width: 35px;
      height: 35px;
      object-fit: cover;
      border-radius: 50%;
      border: 1px solid #041E42; }

@media (min-width: 1800px) {
  .process-year-item__content {
    width: 135%; }
  .process-year-item:nth-of-type(2n) .process-year-item__content {
    width: 135%; } }

@media (max-width: 1024px) {
  .process-year-item__number {
    padding: 5px 8px; }
  .process-year {
    margin-top: 40px;
    flex-direction: column;
    min-height: 100vh; }
    .process-year-item {
      width: 100%; }
      .process-year-item__content {
        position: absolute;
        top: 50%;
        left: calc(50% - 100px);
        width: 30%;
        transform: translate(-100%, -50%); }
        .process-year-item__content--title {
          -webkit-line-clamp: 5;
          text-align: right;
          font-size: 16px; }
        .process-year-item__content--desc {
          display: none; }
      .process-year-item__icon {
        top: 50%;
        left: calc(50% + 100px);
        transform: translateY(-50%); }
      .process-year-item__number--solid {
        position: absolute;
        top: 50%;
        left: 0;
        width: 50px;
        height: 2px;
        transform: rotate(180deg);
        transform-origin: left;
        background-color: #041e42; }
      .process-year-item:not(:first-child) .process-year-item--dash {
        width: 0;
        height: 100%;
        transform: translate(-50%, -150%);
        border-right: 3px dashed #041E42;
        border-top: 0; }
      .process-year-item:nth-of-type(2n) .process-year-item__content {
        top: 50%;
        left: calc(50% + 100px);
        width: 30%;
        transform: translate(0%, -50%); }
        .process-year-item:nth-of-type(2n) .process-year-item__content--title {
          -webkit-line-clamp: 5;
          text-align: left; }
      .process-year-item:nth-of-type(2n) .process-year-item__icon {
        top: 50%;
        left: calc(50% - 130px);
        transform: translateY(-50%); }
      .process-year-item:nth-of-type(2n) .process-year-item__number--solid {
        top: 50%;
        bottom: initial;
        left: 100%;
        right: initial;
        transform: rotate(0deg) translate(0%, -50%); }
  .process-year-item__content--title {
    margin-bottom: 0; } }

@media (max-width: 425px) {
  .process-year-item__number--solid {
    width: 20px; }
  .process-year-item__content {
    left: calc(50% - 70px); }
  .process-year-item:nth-of-type(2n) .process-year-item__content {
    left: calc(50% + 70px); }
  .process-year-item__icon {
    left: calc(50% + 70px); }
  .process-year-item:nth-of-type(2n) .process-year-item__icon {
    left: calc(50% - 105px); } }

.about-story-partner__wrapper {
  margin: 64px 0; }
  @media (max-width: 1280px) {
    .about-story-partner__wrapper {
      margin: 40px 0; } }
  @media (max-width: 640px) {
    .about-story-partner__wrapper {
      margin: 32px 0; } }
  .about-story-partner__wrapper .swiper-container {
    padding: 0 80px; }
    @media (max-width: 1439px) {
      .about-story-partner__wrapper .swiper-container {
        padding: 0 60px; } }
    @media (max-width: 1023px) {
      .about-story-partner__wrapper .swiper-container {
        padding: 0 45px; } }
  .about-story-partner__wrapper .about-story-partner {
    position: relative; }
    .about-story-partner__wrapper .about-story-partner__title {
      padding-bottom: 64px;
      text-align: center; }
      @media (max-width: 1280px) {
        .about-story-partner__wrapper .about-story-partner__title {
          padding-bottom: 40px; } }
      @media (max-width: 640px) {
        .about-story-partner__wrapper .about-story-partner__title {
          padding-bottom: 32px; } }
    .about-story-partner__wrapper .about-story-partner #about-story-partner--next, .about-story-partner__wrapper .about-story-partner #about-story-partner--prev {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat; }
      .about-story-partner__wrapper .about-story-partner #about-story-partner--next::after, .about-story-partner__wrapper .about-story-partner #about-story-partner--prev::after {
        content: ""; }
    .about-story-partner__wrapper .about-story-partner #about-story-partner--next {
      background-image: url("../assets/images/about/next.png"); }
    .about-story-partner__wrapper .about-story-partner #about-story-partner--prev {
      background-image: url("../assets/images/about/prev.png"); }

@media (max-width: 1024px) {
  .about-story-partner__wrapper {
    margin: 36px 0; }
  .about-story-partner__wrapper .about-story-partner__title {
    padding-bottom: 36px; }
  .about-story-partner__wrapper .about-story-partner {
    margin-bottom: 12px; }
  .about-story-mg__wrapper .about-story-mg__title {
    padding: 36px 0; } }

.about-story-become__wrapper {
  background-color: #041E42; }
  .about-story-become__wrapper .about-story-become {
    height: 284px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .about-story-become__wrapper .about-story-become .btn a {
      padding: 18px 24px;
      font-size: 18px;
      border-radius: 12px;
      border: 2px solid white; }

@media (max-width: 1024px) {
  .about-story-become__wrapper .about-story-become .btn a {
    padding: 16px 20px;
    font-size: 16px; }
  .about-story-become__wrapper .about-story-become {
    height: 250px; } }

@media (max-width: 640px) {
  .about-story-become__wrapper .about-story-become .btn a {
    padding: 8px 12px; }
  .about-story-become__wrapper .about-story-become {
    height: 200px; } }

.about-media-press__wrapper {
  transition: .3s; }
  .about-media-press__wrapper .about-media-press {
    margin-top: 64px;
    height: 427px;
    background-image: url("../assets/images/about/press.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (max-width: 1280px) {
      .about-media-press__wrapper .about-media-press {
        margin-top: 40px; } }
    @media (max-width: 640px) {
      .about-media-press__wrapper .about-media-press {
        margin-top: 32px; } }

@media (max-width: 900px) {
  .about-media-press__wrapper .about-media-press {
    height: 360px; } }

@media (max-width: 425px) {
  .about-media-press__wrapper .about-media-press {
    height: 300px; } }

.about-media-logo__wrapper {
  margin: 64px 0; }
  @media (max-width: 1280px) {
    .about-media-logo__wrapper {
      margin: 40px 0; } }
  @media (max-width: 640px) {
    .about-media-logo__wrapper {
      margin: 32px 0; } }
  .about-media-logo__wrapper .about-media-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -32px; }
    .about-media-logo__wrapper .about-media-logo__item {
      width: 191px;
      height: 141px;
      margin: 0 32px;
      object-fit: cover;
      object-position: center; }

@media (max-width: 900px) {
  .about-media-logo__wrapper .about-media-logo {
    justify-content: center; } }

@media (max-width: 640px) {
  .about-media-logo__wrapper .about-media-logo__item {
    width: 124px;
    height: 92px; } }

@media (max-width: 425px) {
  .about-media-logo__wrapper .about-media-logo {
    flex-direction: column; }
    .about-media-logo__wrapper .about-media-logo__item {
      width: 191px;
      height: 141px; }
    .about-media-logo__wrapper .about-media-logo__item:not(:first-child) {
      margin-top: 32px; } }

.about-media-releases__wrapper .about-media-releases {
  padding-bottom: 64px;
  margin-bottom: 64px;
  background-color: #ECECEC; }
  @media (max-width: 1280px) {
    .about-media-releases__wrapper .about-media-releases {
      padding-bottom: 40px; } }
  @media (max-width: 640px) {
    .about-media-releases__wrapper .about-media-releases {
      padding-bottom: 32px; } }
  @media (max-width: 1280px) {
    .about-media-releases__wrapper .about-media-releases {
      margin-bottom: 40px; } }
  @media (max-width: 640px) {
    .about-media-releases__wrapper .about-media-releases {
      margin-bottom: 32px; } }
  .about-media-releases__wrapper .about-media-releases__title {
    text-align: center;
    padding: 64px 0; }
    @media (max-width: 1280px) {
      .about-media-releases__wrapper .about-media-releases__title {
        padding: 40px 0; } }
    @media (max-width: 640px) {
      .about-media-releases__wrapper .about-media-releases__title {
        padding: 32px 0; } }
  .about-media-releases__wrapper .about-media-releases__content {
    width: 80%;
    margin: auto; }
    .about-media-releases__wrapper .about-media-releases__content .releases-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center; }
      .about-media-releases__wrapper .about-media-releases__content .releases-box-item:not(:nth-of-type(4n + 1)) {
        border-top: 3px solid #041E42;
        padding-top: 40px; }
      .about-media-releases__wrapper .about-media-releases__content .releases-box-item__title {
        font-weight: 700;
        letter-spacing: 1.5px;
        line-height: 28px; }
      .about-media-releases__wrapper .about-media-releases__content .releases-box-item--padding {
        padding: 0 32px; }
      .about-media-releases__wrapper .about-media-releases__content .releases-box-item__content {
        display: flex;
        justify-content: space-between;
        flex-direction: row; }
        .about-media-releases__wrapper .about-media-releases__content .releases-box-item__content-desc {
          flex-basis: 70%;
          width: 70%;
          color: #707070;
          margin: 32px 0; }
        .about-media-releases__wrapper .about-media-releases__content .releases-box-item__content-btn {
          flex-basis: 30%;
          width: 30%; }
          .about-media-releases__wrapper .about-media-releases__content .releases-box-item__content-btn .more {
            margin: 0;
            text-transform: uppercase; }

.releases-pagination {
  width: 315px;
  margin: 0 auto; }
  .releases-pagination .swiper-container {
    padding: 0 30px; }
  .releases-pagination .releases-box-item {
    transition: .3s all linear;
    opacity: 1;
    visibility: hidden; }
  .releases-pagination .releases-box-item.hidden {
    display: none;
    opacity: 0;
    visibility: visible; }
  .releases-pagination .active {
    background-color: rgba(4, 30, 66, 0.9);
    color: white;
    font-weight: bold; }
  .releases-pagination__button {
    border: 1px solid #041E42;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px !important;
    width: 42px !important;
    line-height: 42px !important;
    transition: .3s all linear; }
    .releases-pagination__button span {
      display: block;
      pointer-events: none; }
    .releases-pagination__button:hover {
      background-color: #041E42;
      color: white;
      font-weight: bold; }
  .releases-pagination__next, .releases-pagination__prev {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 12px; }
    .releases-pagination__next::after, .releases-pagination__prev::after {
      content: ""; }
  .releases-pagination__next {
    background-image: url("../assets/images/about/pagination_next.png"); }
  .releases-pagination__prev {
    background-image: url("../assets/images/about/pagination_prev.png"); }

@media (max-width: 1024px) {
  .about-media-releases__wrapper .about-media-releases__content .releases-box {
    padding-bottom: 40px; }
  .about-media-releases__wrapper .about-media-releases__content .releases-box-item__title {
    line-height: 23px; }
  .about-media-releases__wrapper .about-media-releases__content .releases-box-item--padding {
    padding: 0; } }

@media (max-width: 900px) {
  .about-media-releases__wrapper .about-media-releases__content .releases-box-item:nth-of-type(4n) .releases-box-item__content-btn {
    margin-bottom: 0; }
  .about-media-releases__wrapper .about-media-releases__content .releases-box-item__content {
    flex-direction: column; }
    .about-media-releases__wrapper .about-media-releases__content .releases-box-item__content-desc, .about-media-releases__wrapper .about-media-releases__content .releases-box-item__content-btn {
      flex: 0 0 100%;
      width: 100%; }
    .about-media-releases__wrapper .about-media-releases__content .releases-box-item__content-btn {
      display: block;
      margin-bottom: 32px; }
    .about-media-releases__wrapper .about-media-releases__content .releases-box-item__content-desc {
      -webkit-line-clamp: 3; } }

@media (max-width: 425px) {
  .about-media-releases__wrapper .about-media-releases {
    padding-bottom: 32px; }
  .about-media-releases__wrapper .about-media-releases__content .releases-box {
    padding-bottom: 32px; } }

.about-media-video__wrapper {
  margin: 64px 0; }
  @media (max-width: 1280px) {
    .about-media-video__wrapper {
      margin: 40px 0; } }
  @media (max-width: 640px) {
    .about-media-video__wrapper {
      margin: 32px 0; } }
  .about-media-video__wrapper .about-media-video {
    margin-bottom: 64px; }
    @media (max-width: 1280px) {
      .about-media-video__wrapper .about-media-video {
        margin-bottom: 40px; } }
    @media (max-width: 640px) {
      .about-media-video__wrapper .about-media-video {
        margin-bottom: 32px; } }
    .about-media-video__wrapper .about-media-video .model {
      position: fixed;
      top: 0;
      left: 0;
      display: none;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: 100vh;
      background-color: #041E42;
      z-index: 10000; }
      .about-media-video__wrapper .about-media-video .model.active {
        display: flex;
        animation: showFlexRL 0.1s linear forwards; }
      .about-media-video__wrapper .about-media-video .model-closed {
        background-color: white;
        content: "";
        position: absolute;
        top: 0;
        right: 0px;
        width: 80px;
        height: 80px;
        border-bottom-left-radius: 50%; }
        .about-media-video__wrapper .about-media-video .model-closed::before, .about-media-video__wrapper .about-media-video .model-closed::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 50%;
          height: 3px;
          border-radius: 2px;
          background-color: #041E42; }
        .about-media-video__wrapper .about-media-video .model-closed::before {
          transform: translate(-50%, -50%) rotate(45deg); }
        .about-media-video__wrapper .about-media-video .model-closed::after {
          transform: translate(-50%, -50%) rotate(-45deg); }
      .about-media-video__wrapper .about-media-video .model .video__model {
        width: 900px;
        height: 768px; }
        .about-media-video__wrapper .about-media-video .model .video__model-item {
          width: 100%;
          height: 100%;
          position: relative; }
          .about-media-video__wrapper .about-media-video .model .video__model-item--img {
            width: 100%; }
          .about-media-video__wrapper .about-media-video .model .video__model-item--title {
            text-align: center;
            color: white;
            padding-top: 40px;
            font-weight: 500; }
            @media (max-width: 1280px) {
              .about-media-video__wrapper .about-media-video .model .video__model-item--title {
                padding-top: 32px; } }
            @media (max-width: 640px) {
              .about-media-video__wrapper .about-media-video .model .video__model-item--title {
                padding-top: 12px; } }
        .about-media-video__wrapper .about-media-video .model .video__model .swiper-container {
          padding: 45px; }
        .about-media-video__wrapper .about-media-video .model .video__model .video__model-swiper .model-button--next, .about-media-video__wrapper .about-media-video .model .video__model .video__model-swiper .model-button--prev {
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat; }
          .about-media-video__wrapper .about-media-video .model .video__model .video__model-swiper .model-button--next::after, .about-media-video__wrapper .about-media-video .model .video__model .video__model-swiper .model-button--prev::after {
            content: ""; }
        .about-media-video__wrapper .about-media-video .model .video__model .video__model-swiper .model-button--next {
          background-image: url("../assets/images/about/pagination_next--white.png"); }
        .about-media-video__wrapper .about-media-video .model .video__model .video__model-swiper .model-button--prev {
          background-image: url("../assets/images/about/pagination_prev--white.png"); }
    .about-media-video__wrapper .about-media-video__list {
      display: grid;
      grid-auto-rows: 55px;
      gap: 12px;
      margin-bottom: 40px; }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(1) {
      grid-column: 1 / span 9;
      grid-row: 1 / span 8; }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(2) {
      grid-column: 10 / span 6;
      grid-row: 1 / span 4;
      padding-left: 38px; }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(3) {
      grid-column: 10 / span 6;
      grid-row: 5 / span 4;
      padding-left: 38px; }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(4) {
      grid-column: 1 / span 5;
      grid-row: 9 / span 4;
      padding-top: 38px;
      padding-right: 18px; }
      .about-media-video__wrapper .about-media-video__item:nth-of-type(4)::after {
        top: calc(50% + 19px);
        left: calc(50% + 9px); }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(5) {
      grid-column: 6 / span 5;
      grid-row: 9 / span 4;
      padding-top: 38px;
      padding-left: 9px;
      padding-right: 9px; }
      .about-media-video__wrapper .about-media-video__item:nth-of-type(5)::after {
        top: calc(50% + 19px);
        left: calc(50% + 9px); }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(6) {
      grid-column: 11 / span 5;
      grid-row: 9 / span 4;
      padding-top: 38px;
      padding-left: 18px; }
      .about-media-video__wrapper .about-media-video__item:nth-of-type(6)::after {
        top: calc(50% + 19px);
        left: calc(50% + 9px); }
    .about-media-video__wrapper .about-media-video__item {
      position: relative; }
      .about-media-video__wrapper .about-media-video__item img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
      .about-media-video__wrapper .about-media-video__item:hover::after {
        opacity: .7; }
      .about-media-video__wrapper .about-media-video__item iframe {
        width: 100% !important;
        height: 100% !important;
        pointer-events: none; }
      .about-media-video__wrapper .about-media-video__item.about-media-video__item--video::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 83px;
        height: 83px;
        border-radius: 50%;
        background-image: url("../assets/images/about/btn-pause.png");
        z-index: 1;
        transition: .3s all linear;
        cursor: pointer; }

@media (max-width: 1024px) {
  .about-media-video__wrapper .about-media-video__list {
    gap: 8px;
    grid-auto-rows: 40px;
    margin-bottom: 10px; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(1) {
    grid-column: 1 / span 9;
    grid-row: 1 / span 8; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(2) {
    grid-column: 10 / span 6;
    grid-row: 1 / span 4;
    padding-left: 22px; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(3) {
    grid-column: 10 / span 6;
    grid-row: 5 / span 4;
    padding-left: 22px; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(4) {
    grid-column: 1 / span 5;
    grid-row: 9 / span 4;
    padding-top: 22px;
    padding-right: 10px; }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(4)::after {
      top: calc(50% + 11px);
      left: calc(50% + 5px); }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(5) {
    grid-column: 6 / span 5;
    grid-row: 9 / span 4;
    padding-top: 22px;
    padding-left: 5px;
    padding-right: 5px; }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(5)::after {
      top: calc(50% + 11px);
      left: calc(50% + 5px); }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(6) {
    grid-column: 11 / span 5;
    grid-row: 9 / span 4;
    padding-top: 22px;
    padding-left: 10px; }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(6)::after {
      top: calc(50% + 11px);
      left: calc(50% + 5px); } }

@media (max-width: 900px) {
  .about-media-video__wrapper .about-media-video__list {
    gap: 8px; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(1) {
    grid-column: 1 / span 8;
    grid-row: 1 / span 8; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(2) {
    grid-column: 9 / span 4;
    grid-row: 1 / span 4;
    padding-left: 0px; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(3) {
    grid-column: 9 / span 4;
    grid-row: 5 / span 4;
    padding-left: 0px; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(4) {
    grid-column: 1 / span 4;
    grid-row: 9 / span 4;
    padding-top: 0px;
    padding-right: 0px; }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(4)::after {
      top: 50%;
      left: 50%; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(5) {
    grid-column: 5 / span 4;
    grid-row: 9 / span 4;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px; }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(5)::after {
      top: 50%;
      left: 50%; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(6) {
    grid-column: 9 / span 4;
    grid-row: 9 / span 4;
    padding-top: 0px;
    padding-left: 0px; }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(6)::after {
      top: 50%;
      left: 50%; } }

@media (max-width: 640px) {
  .about-media-video__wrapper .about-media-video__list {
    gap: 8px;
    grid-auto-rows: 45px; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(1) {
    grid-column: 1 / span 12;
    grid-row: 1 / span 8; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(2) {
    grid-column: 1 / span 6;
    grid-row: 9 / span 4;
    padding-left: 0px; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(3) {
    grid-column: 7 / span 6;
    grid-row: 9 / span 4;
    padding-left: 0px; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(4) {
    grid-column: 1 / span 4;
    grid-row: 13 / span 3;
    padding-top: 0px;
    padding-right: 0px; }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(4)::after {
      top: 50%;
      left: 50%; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(5) {
    grid-column: 5 / span 4;
    grid-row: 13 / span 3;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px; }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(5)::after {
      top: 50%;
      left: 50%; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(6) {
    grid-column: 9 / span 4;
    grid-row: 13 / span 3;
    padding-top: 0px;
    padding-left: 0px; }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(6)::after {
      top: 50%;
      left: 50%; } }

@media (max-width: 425px) {
  .about-media-video__wrapper .about-media-video__list {
    gap: 4px; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(1) {
    grid-column: 1 / span 4;
    grid-row: 1 / span 4; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(2) {
    grid-column: 1 / span 4;
    grid-row: 5 / span 4;
    padding-left: 0px; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(3) {
    grid-column: 1 / span 4;
    grid-row: 9 / span 4;
    padding-left: 0px; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(4) {
    grid-column: 1 / span 4;
    grid-row: 13 / span 4;
    padding-top: 0px;
    padding-right: 0px; }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(4)::after {
      top: 50%;
      left: 50%; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(5) {
    grid-column: 1 / span 4;
    grid-row: 17 / span 4;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px; }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(5)::after {
      top: 50%;
      left: 50%; }
  .about-media-video__wrapper .about-media-video__item:nth-of-type(6) {
    grid-column: 1 / span 4;
    grid-row: 21 / span 4;
    padding-top: 0px;
    padding-left: 0px; }
    .about-media-video__wrapper .about-media-video__item:nth-of-type(6)::after {
      top: 50%;
      left: 50%; } }

@media (max-width: 1280px) {
  .about-media-video__wrapper .about-media-video .model .video__model {
    width: 90%;
    height: 90%; } }

.about-story-blog__wrapper .about-story-blog {
  margin: 64px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  @media (max-width: 1280px) {
    .about-story-blog__wrapper .about-story-blog {
      margin: 40px 0; } }
  @media (max-width: 640px) {
    .about-story-blog__wrapper .about-story-blog {
      margin: 32px 0; } }
  .about-story-blog__wrapper .about-story-blog .title-blog, .about-story-blog__wrapper .about-story-blog .about-story-blog--left .blog-item__content-title, .about-story-blog__wrapper .about-story-blog--left .about-story-blog .blog-item__content-title, .about-story-blog__wrapper .about-story-blog .about-story-blog--right .about-story-blog__title--right, .about-story-blog__wrapper .about-story-blog--right .about-story-blog .about-story-blog__title--right, .about-story-blog__wrapper .about-story-blog .about-story-blog--right .blog-item__content-title, .about-story-blog__wrapper .about-story-blog--right .about-story-blog .blog-item__content-title, .about-story-blog__wrapper .about-story-blog .blog-detail__wrapper .blog-detail--right .blog-item__content-title, .blog-detail__wrapper .blog-detail--right .about-story-blog__wrapper .about-story-blog .blog-item__content-title {
    font-weight: 700;
    text-transform: uppercase; }
  .about-story-blog__wrapper .about-story-blog--left {
    flex: 0 0 70%;
    width: 70%; }
    .about-story-blog__wrapper .about-story-blog--left .blog {
      margin-bottom: 64px;
      display: grid;
      grid-template-columns: auto auto;
      gap: 38px 50px; }
      @media (max-width: 1280px) {
        .about-story-blog__wrapper .about-story-blog--left .blog {
          margin-bottom: 40px; } }
      @media (max-width: 640px) {
        .about-story-blog__wrapper .about-story-blog--left .blog {
          margin-bottom: 32px; } }
      .about-story-blog__wrapper .about-story-blog--left .blog-item {
        grid-column: 1fr;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        transition: 0.3s all linear; }
        .about-story-blog__wrapper .about-story-blog--left .blog-item:hover {
          opacity: 0.8; }
        .about-story-blog__wrapper .about-story-blog--left .blog-item__img {
          width: 100%;
          height: 271px;
          object-fit: cover;
          object-position: center; }
        .about-story-blog__wrapper .about-story-blog--left .blog-item__content {
          margin-right: 32px;
          margin-left: 32px; }
          @media (max-width: 1280px) {
            .about-story-blog__wrapper .about-story-blog--left .blog-item__content {
              margin-right: 24px; } }
          @media (max-width: 640px) {
            .about-story-blog__wrapper .about-story-blog--left .blog-item__content {
              margin-right: 16px; } }
          @media (max-width: 1280px) {
            .about-story-blog__wrapper .about-story-blog--left .blog-item__content {
              margin-left: 24px; } }
          @media (max-width: 640px) {
            .about-story-blog__wrapper .about-story-blog--left .blog-item__content {
              margin-left: 16px; } }
          .about-story-blog__wrapper .about-story-blog--left .blog-item__content-title {
            margin: 32px 0; }
            @media (max-width: 1280px) {
              .about-story-blog__wrapper .about-story-blog--left .blog-item__content-title {
                margin: 24px 0; } }
            @media (max-width: 640px) {
              .about-story-blog__wrapper .about-story-blog--left .blog-item__content-title {
                margin: 16px 0; } }
          .about-story-blog__wrapper .about-story-blog--left .blog-item__content-desc {
            color: #707070;
            font-family: "Segoe UI", sans-serif;
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 32px;
            color: #707070; }
            @media (max-width: 1024px) {
              .about-story-blog__wrapper .about-story-blog--left .blog-item__content-desc {
                font-size: 15px; } }
            @media (max-width: 1280px) {
              .about-story-blog__wrapper .about-story-blog--left .blog-item__content-desc {
                margin-bottom: 24px; } }
            @media (max-width: 640px) {
              .about-story-blog__wrapper .about-story-blog--left .blog-item__content-desc {
                margin-bottom: 16px; } }
    .about-story-blog__wrapper .about-story-blog--left .releases-pagination {
      margin: 0 auto; }
  .about-story-blog__wrapper .about-story-blog--right {
    flex: 0 0 25%;
    width: 25%; }
    .about-story-blog__wrapper .about-story-blog--right .about-story-blog__title--right {
      margin-bottom: 40px;
      color: #707070;
      font-family: "Segoe UI", sans-serif;
      font-size: 20px;
      font-weight: 400;
      color: #041e42;
      font-family: "HELVETICA-NEUE", "Segoe UI", sans-serif; }
      @media (max-width: 1280px) {
        .about-story-blog__wrapper .about-story-blog--right .about-story-blog__title--right {
          margin-bottom: 32px; } }
      @media (max-width: 640px) {
        .about-story-blog__wrapper .about-story-blog--right .about-story-blog__title--right {
          margin-bottom: 12px; } }
      @media (max-width: 1024px) {
        .about-story-blog__wrapper .about-story-blog--right .about-story-blog__title--right {
          font-size: 18px; } }
      @media (max-width: 640px) {
        .about-story-blog__wrapper .about-story-blog--right .about-story-blog__title--right {
          font-size: 16px; } }
    .about-story-blog__wrapper .about-story-blog--right .blog {
      display: flex;
      flex-direction: column; }
      .about-story-blog__wrapper .about-story-blog--right .blog-item {
        transition: 0.3s all linear;
        padding-top: 8px;
        border-bottom: 1px solid rgba(4, 30, 66, 0.9); }
        .about-story-blog__wrapper .about-story-blog--right .blog-item:hover {
          opacity: 0.8; }
        .about-story-blog__wrapper .about-story-blog--right .blog-item__img {
          height: 238px;
          object-fit: cover;
          object-position: center; }
        .about-story-blog__wrapper .about-story-blog--right .blog-item__content-title {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          margin: 12px 0; }

@media (max-width: 1024px) {
  .about-story-blog__wrapper .about-story-blog {
    flex-direction: column; }
  .about-story-blog__wrapper .about-story-blog--left {
    flex: 0 0 100%;
    width: 100%; }
  .about-story-blog__wrapper .about-story-blog--right {
    flex: 0 0 100%;
    width: 100%;
    margin-top: 64px; } }
  @media (max-width: 1024px) and (max-width: 1280px) {
    .about-story-blog__wrapper .about-story-blog--right {
      margin-top: 40px; } }
  @media (max-width: 1024px) and (max-width: 640px) {
    .about-story-blog__wrapper .about-story-blog--right {
      margin-top: 32px; } }

@media (max-width: 1024px) {
    .about-story-blog__wrapper .about-story-blog--right .blog {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-left: 1%; }
    .about-story-blog__wrapper .about-story-blog--right .blog-item {
      padding-top: 12px;
      width: 33.333%;
      padding-right: 2%;
      border-bottom: 0; }
  .about-story-blog__wrapper .about-story-blog--right .about-story-blog__title--right {
    margin-left: 1%; } }

@media (max-width: 768px) {
  .about-story-blog__wrapper .about-story-blog--right .blog-item {
    width: 50%; } }

@media (max-width: 425px) {
  .about-story-blog__wrapper .about-story-blog--right .blog-item {
    width: 100%; } }

@media (max-width: 640px) {
  .about-story-blog__wrapper .about-story-blog--left .blog {
    grid-template-columns: auto; } }

@media (max-width: 640px) {
  .releases-pagination {
    width: 302px; }
  .releases-pagination .swiper-container {
    padding: 0 35px; } }

.intro__button {
  margin-top: 40px; }
  @media (max-width: 1280px) {
    .intro__button {
      margin-top: 32px; } }
  @media (max-width: 640px) {
    .intro__button {
      margin-top: 12px; } }
  .intro__button--link {
    padding: 12px 24px !important; }
    @media (max-width: 1024px) {
      .intro__button--link {
        padding: 8px 12px !important; } }
    @media (max-width: 640px) {
      .intro__button--link {
        font-size: 14px; } }

.faq-content__wrapper .faq-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 64px 0px; }
  @media (max-width: 1280px) {
    .faq-content__wrapper .faq-content {
      padding: 40px 0px; } }
  @media (max-width: 640px) {
    .faq-content__wrapper .faq-content {
      padding: 32px 0px; } }
  .faq-content__wrapper .faq-content .faq-content__left {
    width: 23%;
    overflow: hidden; }
    .faq-content__wrapper .faq-content .faq-content__left .faq-cateheading {
      display: inline-block;
      position: relative;
      font-weight: 700; }
      .faq-content__wrapper .faq-content .faq-content__left .faq-cateheading::after {
        position: absolute;
        content: "";
        top: 50%;
        left: calc(100% + 12px);
        height: 1px;
        width: 100%;
        background: #041E42;
        opacity: 0.8;
        transform: translateY(-50%); }
    .faq-content__wrapper .faq-content .faq-content__left .faq-left__items {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      width: 100%;
      background: #ECECEC;
      border-radius: 12px; }
      @media (max-width: 1280px) {
        .faq-content__wrapper .faq-content .faq-content__left .faq-left__items {
          margin-top: 32px; } }
      @media (max-width: 640px) {
        .faq-content__wrapper .faq-content .faq-content__left .faq-left__items {
          margin-top: 12px; } }
      .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 20px 0;
        position: relative;
        cursor: pointer;
        transition: .2s all ease;
        color: #707070;
        font-family: "Segoe UI", sans-serif;
        font-size: 20px;
        font-weight: 400;
        color: #041E42;
        text-transform: uppercase;
        font-weight: 700; }
        @media (max-width: 1280px) {
          .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list {
            padding: 12px 0; } }
        @media (max-width: 640px) {
          .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list {
            padding: 8px 0; } }
        @media (max-width: 1024px) {
          .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list {
            font-size: 18px; } }
        @media (max-width: 640px) {
          .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list {
            font-size: 16px; } }
        .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list:first-child {
          border-top-left-radius: 12px;
          border-top-right-radius: 12px; }
        .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list:last-child {
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px; }
        .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list.active {
          background-color: rgba(4, 30, 66, 0.8);
          color: white; }
        .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list:hover {
          background-color: rgba(4, 30, 66, 0.8);
          color: white; }
        .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list:not(:first-child)::after {
          width: 100%;
          position: absolute;
          content: "";
          top: 0%;
          left: 50%;
          height: 1px;
          background: #041E42;
          transform: translateX(-50%);
          opacity: 0.7; }
        .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list .item__desc {
          font-size: 20px;
          padding-left: 12px; }
        .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list .item__img {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          object-fit: cover;
          object-position: center;
          pointer-events: none;
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.3); }
  .faq-content__wrapper .faq-content .faq-content__right {
    width: 75%;
    padding-left: 60px;
    margin-top: -20px; }
    .faq-content__wrapper .faq-content .faq-content__right .content-title .title {
      margin-bottom: 24px; }
      @media (max-width: 1280px) {
        .faq-content__wrapper .faq-content .faq-content__right .content-title .title {
          margin-bottom: 16px; } }
      @media (max-width: 640px) {
        .faq-content__wrapper .faq-content .faq-content__right .content-title .title {
          margin-bottom: 12px; } }
    .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list.active {
      transition: .3s all linear; }
      .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list.active .content__img2 {
        height: 100% !important;
        margin-top: 8px; }
      .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list.active .right-list__items::after {
        content: "\268A"; }
    .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .right-list__items {
      cursor: pointer;
      display: flex;
      align-items: center;
      position: relative;
      padding: 24px;
      border-top: 1px solid #041E42; }
      @media (max-width: 1280px) {
        .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .right-list__items {
          padding: 16px; } }
      @media (max-width: 640px) {
        .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .right-list__items {
          padding: 12px; } }
      .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .right-list__items:hover {
        background-color: #ECECEC; }
      .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .right-list__items::after {
        content: "\271A";
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-50%, -50%);
        color: #041E42;
        font-family: "HELVETICA-NEUE", "Segoe UI", sans-serif;
        font-size: 30px;
        font-weight: 500;
        vertical-align: middle;
        color: #041E42;
        font-weight: bold; }
        @media (max-width: 1024px) {
          .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .right-list__items::after {
            font-size: 26px; } }
        @media (max-width: 640px) {
          .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .right-list__items::after {
            font-size: 22px; } }
      .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .right-list__items .item__desc2 {
        padding-left: 10px;
        padding-right: 25px; }
      .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .right-list__items .item_img2 {
        margin-left: auto; }
    .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .content__img2 {
      height: 0;
      overflow: hidden;
      letter-spacing: 1.15px; }
      .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .content__img2 .text-content__img2 {
        margin: 40px 0; }
        @media (max-width: 1280px) {
          .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .content__img2 .text-content__img2 {
            margin: 32px 0; } }
        @media (max-width: 640px) {
          .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .content__img2 .text-content__img2 {
            margin: 12px 0; } }
        .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .content__img2 .text-content__img2 p {
          color: #707070; }
        .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .content__img2 .text-content__img2 .content-img2__img {
          margin: 30px 0; }

@media (max-width: 1280px) {
  .faq-content__wrapper .faq-content {
    flex-direction: column; }
  .faq-content__wrapper .faq-content .faq-content__right {
    width: 100%;
    margin-top: 0;
    padding-left: 0; }
  .faq-content__wrapper .faq-content .faq-content__left {
    width: 100%; }
  .faq-content__wrapper .faq-content .faq-content__left .faq-left__items {
    flex-direction: row;
    justify-content: flex-start;
    overflow-x: scroll;
    border-radius: 0; }
    .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list {
      min-width: 180px; }
      .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list:first-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0; }
      .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0; }
  .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list {
    min-width: 240px; }
    .faq-content__wrapper .faq-content .faq-content__left .faq-left__items .faq-left__list:not(:first-child) {
      margin-left: 4px; }
  .faq-content__wrapper .faq-content .faq-content__left .faq-cateheading::after {
    display: none; }
  .faq-content__wrapper .faq-content .faq-content__left {
    margin-bottom: 36px; }
  .faq-content__wrapper .faq-content .faq-content__right .content-title .title {
    margin-bottom: 16px; } }

@media (max-width: 640px) {
  .faq-content__wrapper .faq-content .faq-content__right .content-title .title {
    margin-bottom: 8px; }
  .faq-content__wrapper .faq-content .faq-content__right .faq-right__items .faq-right__list .content__img2 .text-content__img2 {
    margin: 16px 0; } }

.contactus-content__wrapper .contactus__tittle {
  position: relative;
  margin: 64px 0; }
  @media (max-width: 1280px) {
    .contactus-content__wrapper .contactus__tittle {
      margin: 40px 0; } }
  @media (max-width: 640px) {
    .contactus-content__wrapper .contactus__tittle {
      margin: 32px 0; } }
  .contactus-content__wrapper .contactus__tittle::after {
    position: absolute;
    content: "";
    top: 120%;
    left: 50%;
    height: 2px;
    width: 100%;
    background: #041E42;
    transform: translateX(-50%); }

.contactus-content__wrapper .contactus-content {
  margin-bottom: 32px; }
  .contactus-content__wrapper .contactus-content .contactus-content__left .content-main {
    text-align: left;
    margin-bottom: 32px; }
    .contactus-content__wrapper .contactus-content .contactus-content__left .content-main__title {
      width: 70%;
      position: relative;
      margin: 20px 0px;
      text-transform: uppercase;
      font-weight: bold; }
      .contactus-content__wrapper .contactus-content .contactus-content__left .content-main__title::after {
        position: absolute;
        content: "";
        top: 115%;
        left: 50%;
        height: 1px;
        width: 100%;
        opacity: 0.2;
        background: #041E42;
        transform: translateX(-50%); }
    .contactus-content__wrapper .contactus-content .contactus-content__left .content-main__desc {
      width: 98%;
      margin-bottom: 40px;
      text-align: left;
      color: inherit; }
      @media (max-width: 1280px) {
        .contactus-content__wrapper .contactus-content .contactus-content__left .content-main__desc {
          margin-bottom: 32px; } }
      @media (max-width: 640px) {
        .contactus-content__wrapper .contactus-content .contactus-content__left .content-main__desc {
          margin-bottom: 12px; } }
    .contactus-content__wrapper .contactus-content .contactus-content__left .content-main__email {
      color: inherit;
      margin-bottom: 40px; }
      @media (max-width: 1280px) {
        .contactus-content__wrapper .contactus-content .contactus-content__left .content-main__email {
          margin-bottom: 32px; } }
      @media (max-width: 640px) {
        .contactus-content__wrapper .contactus-content .contactus-content__left .content-main__email {
          margin-bottom: 12px; } }
    .contactus-content__wrapper .contactus-content .contactus-content__left .content-main__desc2 {
      width: 70%;
      text-align: left;
      margin: 20px 0px;
      font-size: 18px;
      font-family: SEGOEUIL; }
    .contactus-content__wrapper .contactus-content .contactus-content__left .content-main > *:last-child {
      margin-bottom: 0; }
  .contactus-content__wrapper .contactus-content .contactus-right .contact-map {
    width: 100%;
    display: flex;
    justify-content: flex-end; }
    .contactus-content__wrapper .contactus-content .contactus-right .contact-map__iframe {
      padding-bottom: 64px;
      padding-top: 32px;
      border: 0;
      width: 605px;
      height: 728px; }

@media (max-width: 1024px) {
  .contactus-content__left,
  .contactus-right {
    flex: 0 0 100%;
    max-width: 100%; }
  .contactus-content__wrapper .contactus-content .contactus-right .contact-map__iframe {
    width: 100%;
    padding-bottom: 36px; } }

@media (max-width: 425px) {
  .contactus-content__wrapper .contactus-content .contactus-content__left .content-main {
    flex: 0 0 100%;
    max-width: 100%; }
  .contactus-content__wrapper .contactus-content .contactus-content__left .content-main__title,
  .contactus-content__wrapper .contactus-content .contactus-content__left .content-main__desc {
    width: 100%;
    margin-bottom: 36px; } }

.details-infor__wrapper .container .details-infor {
  margin-top: 64px; }
  @media (max-width: 1280px) {
    .details-infor__wrapper .container .details-infor {
      margin-top: 40px; } }
  @media (max-width: 640px) {
    .details-infor__wrapper .container .details-infor {
      margin-top: 32px; } }
  .details-infor__wrapper .container .details-infor .details-infor__liveview {
    padding: 0 30px; }
    .details-infor__wrapper .container .details-infor .details-infor__liveview .mySwiper2 {
      margin: 10px 0; }
      .details-infor__wrapper .container .details-infor .details-infor__liveview .mySwiper2 .swiper-wrapper .swiper-slide {
        cursor: zoom-in; }
        .details-infor__wrapper .container .details-infor .details-infor__liveview .mySwiper2 .swiper-wrapper .swiper-slide * {
          width: 100%;
          height: 100%; }
        .details-infor__wrapper .container .details-infor .details-infor__liveview .mySwiper2 .swiper-wrapper .swiper-slide.swiper-slide-zoomed {
          cursor: zoom-out; }
    .details-infor__wrapper .container .details-infor .details-infor__liveview .mySwiper {
      width: 70%; }
      .details-infor__wrapper .container .details-infor .details-infor__liveview .mySwiper .swiper-wrapper .swiper-slide {
        cursor: pointer; }
        .details-infor__wrapper .container .details-infor .details-infor__liveview .mySwiper .swiper-wrapper .swiper-slide * {
          border-radius: 5px; }
    .details-infor__wrapper .container .details-infor .details-infor__liveview .swiper-btn__nav .swiper-button-prev {
      left: 80px;
      padding: 7px; }
    .details-infor__wrapper .container .details-infor .details-infor__liveview .swiper-btn__nav .swiper-button-next {
      right: 80px;
      padding: 7px; }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-infor .product-infor__title {
    margin: 0 0 30px 0;
    font-size: 40px; }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-infor .product-infor__price {
    font-family: HELVETICANEUELTSTD-ROMAN;
    font-size: 40px;
    color: #041E42;
    margin: 10px 0; }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-oftions {
    padding: 7px 0; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-oftions .dropdown-value {
      display: flex;
      align-items: center; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-oftions .dropdown-value .size {
        width: 60%; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-oftions .dropdown-value .amount {
        width: 40%; }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-rate {
    padding: 30px 0; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-rate .rate-section {
      padding: 7px 0;
      align-items: center; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-rate .rate-section .rate-title__items * {
        color: #707070;
        white-space: nowrap; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-rate .rate-section .rate-title__items a:hover {
        color: #041E42; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-rate .rate-section:not(:last-child) .rate-title__items * {
        border-right: 1px solid #707070; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-rate .rate-section .rate-star__items .items-star {
        display: flex;
        align-items: center; }
        .details-infor__wrapper .container .details-infor .detail-infor__content .product-rate .rate-section .rate-star__items .items-star * {
          margin-right: 5px;
          position: relative;
          width: 2rem;
          height: 2rem;
          text-indent: -9999rem;
          display: inline-block;
          align-items: center; }
          .details-infor__wrapper .container .details-infor .detail-infor__content .product-rate .rate-section .rate-star__items .items-star *::after {
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            width: 2rem;
            height: 2rem;
            background-image: url("../assets/icons/white-star-blueborder.png");
            background-repeat: no-repeat;
            background-position: center;
            text-indent: 0;
            display: inline; }
        .details-infor__wrapper .container .details-infor .detail-infor__content .product-rate .rate-section .rate-star__items .items-star .active::after {
          background-image: url("../assets/icons/blue-star.png"); }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .nav-feature {
    display: flex;
    align-items: center;
    font-size: 17px;
    color: #041E42;
    font-family: SEGOEUIB;
    text-transform: uppercase;
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 12px; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .nav-feature .product-feature {
      cursor: pointer; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .nav-feature .product-feature:not(:first-child) {
        margin-left: 24px; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .nav-feature::after {
      position: absolute;
      content: "";
      bottom: -4px;
      right: 0;
      width: 100%;
      height: 1px;
      background: #041E42;
      opacity: 0.4; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .nav-feature .active {
      position: relative; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .nav-feature .active::after {
        position: absolute;
        content: "";
        bottom: -5px;
        left: 0;
        width: 80%;
        border: solid 2px #BF0D3E;
        background: #BF0D3E;
        border-radius: 3px;
        z-index: 1; }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .product-selected .product-selected--value {
    display: none; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .product-selected .product-selected--value.active {
      display: block; }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-details {
    margin-top: 30px; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature {
      border-bottom: solid 1px #C0C0C0; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .dropdown-item:hover {
        color: #041E42; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature:last-child {
        border-bottom: none; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .dropdown-item {
        border: none;
        border-radius: 0;
        width: 100%;
        padding: 10px 0 25px 0; }
        .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .dropdown-item span img {
          transition: 0.4s ease-in-out; }
        .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .dropdown-item .active-caret img {
          transform: rotate(180deg); }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .feature-content {
        transition: max-height 0.4s ease-in-out;
        max-height: 0;
        overflow: hidden;
        color: #707070; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .active {
        height: auto;
        max-height: 300px;
        overflow-y: scroll;
        margin-bottom: 20px; }
        .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .active::-webkit-scrollbar {
          width: 0; }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-icons {
    border-top: solid 1px #ECECEC;
    padding: 20px 0; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-icons .swiper-slide {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-icons .swiper-slide .icon-avatar {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: #ECECEC;
        margin-bottom: 10px; }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-order {
    margin-top: 20px; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-order a {
      border: solid 3px #041E42;
      background-color: transparent;
      border-radius: 5px;
      text-align: center;
      padding: 16px 0;
      display: block;
      color: #041E42;
      font-weight: 700; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-order a:hover {
        background-color: #041E42;
        color: white; }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-ask {
    display: flex;
    flex-direction: column;
    margin-top: 40px; }
    @media (max-width: 1280px) {
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-ask {
        margin-top: 32px; } }
    @media (max-width: 640px) {
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-ask {
        margin-top: 12px; } }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-ask-item--top {
      display: flex;
      background-color: #041E42;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-ask-item--bottom {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      padding: 12px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ECECEC; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-ask-item__link {
      color: #041E42;
      font-weight: 700; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-ask-item--left {
      width: 32%;
      display: flex;
      justify-content: center;
      align-items: center; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-ask-item--right {
      width: 68%; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-ask-item__img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: flex-end; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-ask-item__img img {
        height: 64px; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-ask-item__title {
      padding: 24px 0;
      color: #707070;
      font-family: "Segoe UI", sans-serif;
      font-size: 20px;
      font-weight: 400;
      color: white;
      font-weight: 700; }
      @media (max-width: 1024px) {
        .details-infor__wrapper .container .details-infor .detail-infor__content .product-ask-item__title {
          font-size: 18px; } }
      @media (max-width: 640px) {
        .details-infor__wrapper .container .details-infor .detail-infor__content .product-ask-item__title {
          font-size: 16px; } }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-ask-item__desc {
      width: 100%;
      color: #707070;
      font-family: "Segoe UI", sans-serif;
      font-size: 20px;
      font-weight: 400;
      padding-right: 24px;
      font-weight: 300;
      color: white;
      padding-bottom: 24px; }
      @media (max-width: 1024px) {
        .details-infor__wrapper .container .details-infor .detail-infor__content .product-ask-item__desc {
          font-size: 18px; } }
      @media (max-width: 640px) {
        .details-infor__wrapper .container .details-infor .detail-infor__content .product-ask-item__desc {
          font-size: 16px; } }

@media (max-width: 1640px) and (min-width: 601px) {
  .details-infor__wrapper .container .details-infor .details-infor__liveview .swiper-btn__nav .swiper-button-prev {
    left: 60px; }
  .details-infor__wrapper .container .details-infor .details-infor__liveview .swiper-btn__nav .swiper-button-next {
    right: 60px; }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-infor .product-infor__title {
    margin: 0 0 20px 0;
    font-size: 30px; }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-infor .product-infor__price {
    font-size: 30px;
    margin: 7px 0; }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-oftions .dropdown-value .size,
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-oftions .dropdown-value .amount {
    font-size: 13px; }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-rate {
    padding: 25px 0; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-rate .rate-section {
      padding: 7px 0; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-rate .rate-section .rate-title__items {
        color: #707070;
        font-size: 13px;
        white-space: nowrap; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-rate .rate-section .rate-star__items .items-star * {
        width: 1.6rem;
        height: 1.6rem; }
        .details-infor__wrapper .container .details-infor .detail-infor__content .product-rate .rate-section .rate-star__items .items-star *::after {
          width: 1.6rem;
          height: 1.6rem; }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .nav-feature {
    font-size: 13px; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .nav-feature .active {
      position: relative; }
      .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .nav-feature .active::after {
        border: solid 1.7px #BF0D3E; }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature {
    border-bottom: solid 1px #C0C0C0; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature:last-child {
      border-bottom: none; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .dropdown-item {
      font-size: 13px;
      padding: 10px 0 20px 0; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-details .dropdown-feature .feature-content {
      font-size: 13px; }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-icons {
    padding: 14px 0; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-icons .swiper-slide .icon-avatar {
      width: 45px;
      height: 45px; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-feature-warranty .feature-icons .swiper-slide .title-icon {
      font-size: 13px; }
  .details-infor__wrapper .container .details-infor .detail-infor__content .product-order {
    padding: 15px 0; }
    .details-infor__wrapper .container .details-infor .detail-infor__content .product-order a {
      font-size: 15px; } }

@media (max-width: 1024px) {
  .details-infor__wrapper .container .details-infor {
    flex-direction: column; }
    .details-infor__wrapper .container .details-infor .details-infor__liveview {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%; }
    .details-infor__wrapper .container .details-infor .detail-infor__content {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
      margin-top: 64px; } }
    @media (max-width: 1024px) and (max-width: 1280px) {
      .details-infor__wrapper .container .details-infor .detail-infor__content {
        margin-top: 40px; } }
    @media (max-width: 1024px) and (max-width: 640px) {
      .details-infor__wrapper .container .details-infor .detail-infor__content {
        margin-top: 32px; } }

.details-recommend-video__wrapper {
  margin: calc(128px + 32px) 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; }
  .details-recommend-video__wrapper .details-recommend-video {
    display: flex;
    align-items: center; }
    .details-recommend-video__wrapper .details-recommend-video .left-recommend-video {
      width: 50%; }
      .details-recommend-video__wrapper .details-recommend-video .left-recommend-video .product-infor {
        padding-right: 64px; }
        @media (max-width: 1280px) {
          .details-recommend-video__wrapper .details-recommend-video .left-recommend-video .product-infor {
            padding-right: 40px; } }
        @media (max-width: 640px) {
          .details-recommend-video__wrapper .details-recommend-video .left-recommend-video .product-infor {
            padding-right: 32px; } }
        .details-recommend-video__wrapper .details-recommend-video .left-recommend-video .product-infor .product-infor__italic {
          color: #041E42;
          margin-top: 12px;
          font-family: SEGOEUII; }
        .details-recommend-video__wrapper .details-recommend-video .left-recommend-video .product-infor .product-infor__more {
          padding-top: 0;
          border-top: 0; }
  .details-recommend-video__wrapper .right-recommend-video {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 50%;
    height: calc(100% + 64px);
    width: 50%; }
    .details-recommend-video__wrapper .right-recommend-video .video-section {
      height: 100%; }
      .details-recommend-video__wrapper .right-recommend-video .video-section img,
      .details-recommend-video__wrapper .right-recommend-video .video-section iframe,
      .details-recommend-video__wrapper .right-recommend-video .video-section video {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover; }

@media (max-width: 1280px) {
  .details-recommend-video__wrapper {
    margin: calc(64px + 24px) 0; }
    .details-recommend-video__wrapper .right-recommend-video {
      height: calc(100% + 48px); } }

@media (max-width: 768px) {
  .details-recommend-video__wrapper {
    margin: 128px 0; } }
  @media (max-width: 768px) and (max-width: 1280px) {
    .details-recommend-video__wrapper {
      margin: 64px 0; } }
  @media (max-width: 768px) and (max-width: 640px) {
    .details-recommend-video__wrapper {
      margin: 0; } }

@media (max-width: 768px) {
    .details-recommend-video__wrapper .right-recommend-video {
      height: 100%; } }

@media (max-width: 1024px) {
  .details-recommend-video__wrapper .details-recommend-video .left-recommend-video .product-infor {
    padding-right: 64px; } }
  @media (max-width: 1024px) and (max-width: 1280px) {
    .details-recommend-video__wrapper .details-recommend-video .left-recommend-video .product-infor {
      padding-right: 40px; } }
  @media (max-width: 1024px) and (max-width: 640px) {
    .details-recommend-video__wrapper .details-recommend-video .left-recommend-video .product-infor {
      padding-right: 32px; } }

@media (max-width: 768px) {
  .details-recommend-video__wrapper {
    flex-direction: column;
    height: auto; }
  .details-recommend-video__wrapper .details-recommend-video .left-recommend-video {
    width: 100%; }
  .details-recommend-video__wrapper .details-recommend-video .left-recommend-video .product-infor {
    padding: 0; }
  .details-recommend-video__wrapper .right-recommend-video {
    position: static;
    top: auto;
    right: auto;
    width: 100%;
    max-height: 420px;
    transform: none; }
    .details-recommend-video__wrapper .right-recommend-video .video-section {
      width: 100%;
      height: 100%; }
      .details-recommend-video__wrapper .right-recommend-video .video-section img {
        max-height: 420px;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center; }
  .details-recommend-video__wrapper .right-recommend-video {
    margin-top: 24px; } }

.details-function-benefit__wrapper {
  margin-bottom: 128px; }
  @media (max-width: 1280px) {
    .details-function-benefit__wrapper {
      margin-bottom: 64px; } }
  .details-function-benefit__wrapper .details-nav {
    margin-bottom: 64px; }
    @media (max-width: 1280px) {
      .details-function-benefit__wrapper .details-nav {
        margin-bottom: 40px; } }
    @media (max-width: 640px) {
      .details-function-benefit__wrapper .details-nav {
        margin-bottom: 32px; } }
    .details-function-benefit__wrapper .details-nav .nav-function {
      display: flex;
      align-items: center;
      color: #041E42;
      font-family: SEGOEUI;
      text-transform: uppercase;
      position: relative; }
      .details-function-benefit__wrapper .details-nav .nav-function * {
        cursor: pointer;
        margin-right: 20px; }
      .details-function-benefit__wrapper .details-nav .nav-function::after {
        position: absolute;
        content: "";
        bottom: -4px;
        right: 0;
        width: 98%;
        height: 1px;
        background: #041E42;
        opacity: 0.4; }
      .details-function-benefit__wrapper .details-nav .nav-function .active {
        position: relative; }
        .details-function-benefit__wrapper .details-nav .nav-function .active::after {
          position: absolute;
          content: "";
          bottom: -5px;
          left: 0;
          width: 70%;
          border: solid 2px #BF0D3E;
          background: #BF0D3E;
          border-radius: 3px;
          z-index: 1; }
  .details-function-benefit__wrapper .details-function-benefit .content--value {
    display: none;
    flex-wrap: wrap; }
    .details-function-benefit__wrapper .details-function-benefit .content--value.active {
      display: flex; }
  .details-function-benefit__wrapper .details-function-benefit .details-function {
    width: 50%;
    padding-right: 64px; }
    @media (max-width: 1280px) {
      .details-function-benefit__wrapper .details-function-benefit .details-function {
        padding-right: 40px; } }
    @media (max-width: 640px) {
      .details-function-benefit__wrapper .details-function-benefit .details-function {
        padding-right: 32px; } }
    .details-function-benefit__wrapper .details-function-benefit .details-function .content-function .product-infor .product-infor__title {
      margin-bottom: 40px; }
      @media (max-width: 1280px) {
        .details-function-benefit__wrapper .details-function-benefit .details-function .content-function .product-infor .product-infor__title {
          margin-bottom: 32px; } }
      @media (max-width: 640px) {
        .details-function-benefit__wrapper .details-function-benefit .details-function .content-function .product-infor .product-infor__title {
          margin-bottom: 12px; } }
    .details-function-benefit__wrapper .details-function-benefit .details-function .content-function .product-infor .product-infor__more {
      margin-bottom: 12px;
      padding-top: 0;
      border-top: 0; }
    .details-function-benefit__wrapper .details-function-benefit .details-function .content-function .product-infor .product-infor__list-details ul {
      padding: 5px;
      margin-left: 13px; }
      .details-function-benefit__wrapper .details-function-benefit .details-function .content-function .product-infor .product-infor__list-details ul li {
        list-style-type: initial;
        margin-bottom: 10px; }
        .details-function-benefit__wrapper .details-function-benefit .details-function .content-function .product-infor .product-infor__list-details ul li p {
          color: #707070; }
    .details-function-benefit__wrapper .details-function-benefit .details-function .content-function .product-infor .product-infor__content {
      -webkit-line-clamp: initial;
      -webkit-box-orient: initial;
      display: block;
      max-height: 220px;
      overflow-y: scroll;
      overflow-x: hidden;
      margin-bottom: 0; }
  .details-function-benefit__wrapper .details-function-benefit .details-benefit {
    width: 50%;
    height: 620px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .details-function-benefit__wrapper .details-function-benefit .details-benefit img,
    .details-function-benefit__wrapper .details-function-benefit .details-benefit video,
    .details-function-benefit__wrapper .details-function-benefit .details-benefit iframe {
      width: 100%;
      height: 100%;
      object-fit: cover; }

@media (max-width: 768px) {
  .details-function-benefit__wrapper .details-nav {
    margin-bottom: 36px; }
    .details-function-benefit__wrapper .details-nav .nav-function {
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%;
      overflow-x: scroll;
      overflow-y: hidden;
      padding: 12px; }
  .details-function-benefit__wrapper .details-function-benefit .content--value {
    flex-direction: column; }
  .details-function-benefit__wrapper .details-function-benefit .details-function {
    width: 100%; }
  .details-function-benefit__wrapper .details-function-benefit .details-benefit {
    width: 100%; }
  .details-function-benefit__wrapper .details-function-benefit .details-benefit img {
    width: 100%; } }

.details-review__wrapper {
  background: #041E42;
  padding: 64px 0; }
  @media (max-width: 1280px) {
    .details-review__wrapper {
      padding: 40px 0; } }
  @media (max-width: 640px) {
    .details-review__wrapper {
      padding: 32px 0; } }
  .details-review__wrapper .details-review {
    margin-bottom: 40px; }
    @media (max-width: 1280px) {
      .details-review__wrapper .details-review {
        margin-bottom: 32px; } }
    @media (max-width: 640px) {
      .details-review__wrapper .details-review {
        margin-bottom: 12px; } }
    .details-review__wrapper .details-review .details-review__title .product-infor p {
      color: #C0C0C0; }
    .details-review__wrapper .details-review .details-review__form .form-left {
      margin-top: 32px;
      display: flex;
      flex-direction: column; }
      @media (max-width: 1280px) {
        .details-review__wrapper .details-review .details-review__form .form-left {
          margin-top: 24px; } }
      @media (max-width: 640px) {
        .details-review__wrapper .details-review .details-review__form .form-left {
          margin-top: 16px; } }
      .details-review__wrapper .details-review .details-review__form .form-left .rate-comment {
        flex: 1; }
        .details-review__wrapper .details-review .details-review__form .form-left .rate-comment textarea {
          border-radius: 10px;
          width: 100%;
          height: 100%;
          border: none;
          background: #ffffff;
          padding-left: 20px;
          resize: none;
          padding-top: 12px; }
      .details-review__wrapper .details-review .details-review__form .form-left .rate-section {
        align-items: center;
        margin-bottom: 24px; }
        .details-review__wrapper .details-review .details-review__form .form-left .rate-section .rate-title__items {
          color: #C0C0C0; }
        .details-review__wrapper .details-review .details-review__form .form-left .rate-section .rate-star__items .items-star {
          display: inline; }
          .details-review__wrapper .details-review .details-review__form .form-left .rate-section .rate-star__items .items-star * {
            margin-right: 5px;
            position: relative;
            width: 2rem;
            height: 2rem;
            text-indent: -9999rem;
            display: inline-block; }
            .details-review__wrapper .details-review .details-review__form .form-left .rate-section .rate-star__items .items-star *::after {
              position: absolute;
              top: 0;
              left: 0;
              content: "";
              width: 2rem;
              height: 2rem;
              background-image: url("../assets/icons/white-star-whiteborder.png");
              background-repeat: no-repeat;
              background-position: center;
              text-indent: 0;
              display: inline; }
          .details-review__wrapper .details-review .details-review__form .form-left .rate-section .rate-star__items .items-star *:hover::after {
            background-image: url("../assets/icons/white-star.png"); }
          .details-review__wrapper .details-review .details-review__form .form-left .rate-section .rate-star__items .items-star *.active::after {
            background-image: url("../assets/icons/white-star.png"); }
    .details-review__wrapper .details-review .details-review__form .form-right {
      margin-top: 32px; }
      @media (max-width: 1280px) {
        .details-review__wrapper .details-review .details-review__form .form-right {
          margin-top: 24px; } }
      @media (max-width: 640px) {
        .details-review__wrapper .details-review .details-review__form .form-right {
          margin-top: 16px; } }
      .details-review__wrapper .details-review .details-review__form .form-right .row-input {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px; }
        .details-review__wrapper .details-review .details-review__form .form-right .row-input input {
          width: 49%; }
      .details-review__wrapper .details-review .details-review__form .form-right .collumn-input {
        height: 140px;
        flex-direction: column;
        display: flex;
        justify-content: space-between; }
        .details-review__wrapper .details-review .details-review__form .form-right .collumn-input button {
          border-radius: 10px;
          width: 100%;
          height: 61px;
          border: solid 1px #ffffff;
          background: #041E42;
          color: #C0C0C0;
          text-align: center;
          transition: .3s all linear; }
          .details-review__wrapper .details-review .details-review__form .form-right .collumn-input button:hover {
            border: solid 1px #041E42;
            color: #041E42;
            background: #C0C0C0; }
      .details-review__wrapper .details-review .details-review__form .form-right input {
        border-radius: 10px;
        width: 100%;
        height: 61px;
        border: none;
        background: #ffffff;
        padding-left: 20px; }
        .details-review__wrapper .details-review .details-review__form .form-right input ::placeholder {
          color: #707070; }
  .details-review__wrapper .details-user__comment {
    margin-top: 24px; }
    .details-review__wrapper .details-user__comment .comment__more {
      margin-top: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      display: none; }
      .details-review__wrapper .details-user__comment .comment__more.active {
        display: flex; }
      .details-review__wrapper .details-user__comment .comment__more--btn {
        position: relative;
        width: 36px;
        height: 36px;
        color: #ECECEC;
        border-radius: 2px; }
        .details-review__wrapper .details-user__comment .comment__more--btn::after {
          position: absolute;
          content: "\279C";
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(90deg);
          width: 100%;
          height: 100%;
          border-radius: 2px;
          background-color: #707070;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer; }
        .details-review__wrapper .details-user__comment .comment__more--btn:hover::after {
          box-shadow: 0 0 3px rgba(112, 112, 112, 0.3);
          background-color: rgba(112, 112, 112, 0.3); }
    .details-review__wrapper .details-user__comment .comment__reviews .amount-comment {
      color: #C0C0C0;
      align-items: center;
      padding-bottom: 12px; }
    .details-review__wrapper .details-user__comment .comment__section {
      display: flex;
      flex-direction: column;
      color: #C0C0C0; }
      .details-review__wrapper .details-user__comment .comment__section .comment-items {
        border-top: solid 1px #ffffff; }
        .details-review__wrapper .details-user__comment .comment__section .comment-items:last-child {
          border-bottom: none; }
        .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .active {
          height: 100%; }
        .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 12px 8px 12px 8px;
          height: 100%;
          margin-left: 0; }
          .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section--left {
            flex: 1;
            display: flex;
            justify-content: flex-start;
            align-items: center; }
            .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section--left > *:not(:first-child) {
              margin-left: 20px; }
          .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section--right .comment__date {
            font-size: 18px;
            font-style: italic;
            color: rgba(192, 192, 192, 0.7);
            font-weight: bold; }
          .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section:hover {
            background: rgba(112, 112, 112, 0.5); }
          .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section .user-avatar {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 36px; }
            .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section .user-avatar .user-name {
              border-radius: 50%;
              width: 35px;
              height: 35px; }
          .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section .rate-title__items {
            color: #C0C0C0;
            width: 320px; }
          .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section .rate-star__items .items-star {
            display: inline; }
            .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section .rate-star__items .items-star * {
              margin-right: 5px;
              position: relative;
              width: 2rem;
              height: 2rem;
              text-indent: -9999rem;
              display: inline-block; }
              .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section .rate-star__items .items-star *::after {
                position: absolute;
                top: 0;
                left: 0;
                content: "";
                width: 2rem;
                height: 2rem;
                background-image: url("../assets/icons/white-star-whiteborder.png");
                background-repeat: no-repeat;
                background-position: center;
                text-indent: 0;
                display: inline; }
              .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section .rate-star__items .items-star *.active::after {
                background-image: url("../assets/icons/white-star.png"); }
        .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment {
          height: 0;
          overflow: hidden; }
          .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment .comment {
            padding: 12px 8px 12px 8px;
            background-color: rgba(112, 112, 112, 0.1); }

@media (max-width: 768px) {
  .details-review__wrapper .details-review .details-review__form .form-left {
    flex: 0 0 100%;
    max-width: 100%; }
  .details-review__wrapper .details-review .details-review__form .form-right {
    flex: 0 0 100%;
    max-width: 100%; }
  .details-review__wrapper .details-review .details-review__form .form-right .row-input {
    flex-direction: column; }
    .details-review__wrapper .details-review .details-review__form .form-right .row-input input {
      width: 100%; }
      .details-review__wrapper .details-review .details-review__form .form-right .row-input input:not(:first-child) {
        margin-top: 20px; }
  .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section .rate-title__items {
    width: 200px; }
  .details-review__wrapper .details-review .details-review__form .form-left .rate-section .rate-title__items {
    flex: 0 0 40%;
    max-width: 40%; }
  .details-review__wrapper .details-review .details-review__form .form-left .rate-section .rate-star__items {
    flex: 0 0 60%;
    max-width: 60%; }
  .details-review__wrapper .details-review .details-review__form .form-left .rate-comment textarea {
    height: 120px; }
  .details-review__wrapper .details-review .details-review__form .form-right input, .details-review__wrapper .details-review .details-review__form .form-right button {
    height: 56px; }
  .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section--right {
    flex: 0 0 25%;
    width: 25%;
    text-align: right; }
  .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section--left {
    flex-direction: column;
    text-align: center; }
    .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section--left > *:not(:first-child) {
      margin-top: 4px; }
  .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section--left > :not(:first-child) {
    margin-left: 0; } }

.details-comprarison__wrapper {
  margin-top: 64px;
  margin-bottom: 128px; }
  @media (max-width: 1280px) {
    .details-comprarison__wrapper {
      margin-top: 40px; } }
  @media (max-width: 640px) {
    .details-comprarison__wrapper {
      margin-top: 32px; } }
  @media (max-width: 1280px) {
    .details-comprarison__wrapper {
      margin-bottom: 64px; } }
  .details-comprarison__wrapper .details-comprarison .comparision-title {
    margin-bottom: 64px;
    text-align: center;
    color: #041E42;
    font-family: "HELVETICA-NEUE", "Segoe UI", sans-serif;
    font-size: 50px;
    font-weight: 500; }
    @media (max-width: 1280px) {
      .details-comprarison__wrapper .details-comprarison .comparision-title {
        margin-bottom: 40px; } }
    @media (max-width: 640px) {
      .details-comprarison__wrapper .details-comprarison .comparision-title {
        margin-bottom: 32px; } }
    @media (max-width: 1024px) {
      .details-comprarison__wrapper .details-comprarison .comparision-title {
        font-size: 40px; } }
    @media (max-width: 640px) {
      .details-comprarison__wrapper .details-comprarison .comparision-title {
        font-size: 32px; } }
  .details-comprarison__wrapper .details-comprarison .comparision-section {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    flex-wrap: nowrap; }
    .details-comprarison__wrapper .details-comprarison .comparision-section .col-infor {
      min-width: 220px;
      width: 220px;
      flex: auto; }
    .details-comprarison__wrapper .details-comprarison .comparision-section .product-name {
      text-align: center;
      padding: 70px 0; }
    .details-comprarison__wrapper .details-comprarison .comparision-section .infor-name {
      padding: 30px 0; }
    .details-comprarison__wrapper .details-comprarison .comparision-section .col-product {
      min-width: 320px;
      width: 320px;
      flex: auto; }
      .details-comprarison__wrapper .details-comprarison .comparision-section .col-product .product-name {
        color: #ECECEC;
        text-transform: uppercase;
        font-size: 20px;
        border-radius: 15px 15px 0 0;
        background: #041E42;
        border-bottom: solid 2px #C0C0C0; }
      .details-comprarison__wrapper .details-comprarison .comparision-section .col-product .active-sale {
        position: relative;
        text-decoration: line-through; }
        .details-comprarison__wrapper .details-comprarison .comparision-section .col-product .active-sale .sale {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 40px; }
        .details-comprarison__wrapper .details-comprarison .comparision-section .col-product .active-sale .discount {
          font-weight: 600;
          text-align: center;
          position: absolute;
          bottom: 0;
          right: 0;
          padding: 7px 20px;
          background: #C0C0C0;
          color: #041E42;
          border-radius: 20px 0 0 0; }
          .details-comprarison__wrapper .details-comprarison .comparision-section .col-product .active-sale .discount .cheap {
            font-size: 15px;
            font-weight: 400; }
        .details-comprarison__wrapper .details-comprarison .comparision-section .col-product .active-sale .trial {
          position: absolute;
          bottom: 15%;
          left: 50%;
          transform: translateX(-50%);
          font-size: 15px; }
      .details-comprarison__wrapper .details-comprarison .comparision-section .col-product .infor-name {
        text-align: center; }
        .details-comprarison__wrapper .details-comprarison .comparision-section .col-product .infor-name:last-child {
          border-radius: 0 0 15px 15px; }
        .details-comprarison__wrapper .details-comprarison .comparision-section .col-product .infor-name:nth-child(odd) {
          background: #ECECEC;
          color: #041E42; }
        .details-comprarison__wrapper .details-comprarison .comparision-section .col-product .infor-name:nth-child(even) {
          background: #041E42;
          color: #ECECEC; }

@media (max-width: 1024px) {
  .details-comprarison__wrapper .details-comprarison .comparision-section .col-infor {
    text-align: right; } }

.details-collections__wrapper {
  margin: 64px 0; }
  @media (max-width: 1280px) {
    .details-collections__wrapper {
      margin: 40px 0; } }
  @media (max-width: 640px) {
    .details-collections__wrapper {
      margin: 32px 0; } }
  .details-collections__wrapper .details-collections .details-collections__title {
    margin-bottom: 40px;
    text-align: center;
    display: block; }
    @media (max-width: 1280px) {
      .details-collections__wrapper .details-collections .details-collections__title {
        margin-bottom: 32px; } }
    @media (max-width: 640px) {
      .details-collections__wrapper .details-collections .details-collections__title {
        margin-bottom: 12px; } }
  .details-collections__wrapper .details-collections__section .collections__section .collection__items {
    margin-top: 24px; }
    .details-collections__wrapper .details-collections__section .collections__section .collection__items .items .items__img {
      height: 20%; }
      .details-collections__wrapper .details-collections__section .collections__section .collection__items .items .items__img * {
        width: 100%;
        height: auto; }
    .details-collections__wrapper .details-collections__section .collections__section .collection__items .items .items__title {
      padding: 13px 0; }
      .details-collections__wrapper .details-collections__section .collections__section .collection__items .items .items__title--uppercase {
        color: #041E42;
        text-transform: uppercase;
        padding: 13px 0;
        display: block;
        font-weight: 700; }
    .details-collections__wrapper .details-collections__section .collections__section .collection__items .items .items__content,
    .details-collections__wrapper .details-collections__section .collections__section .collection__items .items .items__price {
      font-size: 18px; }
    .details-collections__wrapper .details-collections__section .collections__section .collection__items .items .items__description {
      position: relative;
      margin-top: 20px;
      border-top: solid 1px #707070;
      border-bottom: solid 1px #707070;
      height: 36px; }
      .details-collections__wrapper .details-collections__section .collections__section .collection__items .items .items__description p {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 36px; }

@media (max-width: 1280px) {
  .details-collections__wrapper .collections__section {
    flex-wrap: wrap; }
    .details-collections__wrapper .collections__section .collection__items {
      flex: 0 0 33.3333%;
      max-width: 33.3333%; } }

@media (max-width: 900px) {
  .details-collections__wrapper .collections__section .collection__items {
    flex: 0 0 50%;
    max-width: 50%; } }

@media (max-width: 640px) {
  .details-collections__wrapper .collections__section .collection__items {
    flex: 0 0 100%;
    max-width: 100%; } }

.blog-detail__wrapper .blog-detail {
  margin: 64px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  @media (max-width: 1280px) {
    .blog-detail__wrapper .blog-detail {
      margin: 40px 0; } }
  @media (max-width: 640px) {
    .blog-detail__wrapper .blog-detail {
      margin: 32px 0; } }
  .blog-detail__wrapper .blog-detail--left {
    flex: 0 0 70%;
    width: 70%; }
    .blog-detail__wrapper .blog-detail--left > *:not(:first-child) {
      margin-top: 24px; }
      @media (max-width: 1280px) {
        .blog-detail__wrapper .blog-detail--left > *:not(:first-child) {
          margin-top: 16px; } }
      @media (max-width: 640px) {
        .blog-detail__wrapper .blog-detail--left > *:not(:first-child) {
          margin-top: 12px; } }
    .blog-detail__wrapper .blog-detail--left__title {
      margin-bottom: 40px; }
      @media (max-width: 1280px) {
        .blog-detail__wrapper .blog-detail--left__title {
          margin-bottom: 32px; } }
      @media (max-width: 640px) {
        .blog-detail__wrapper .blog-detail--left__title {
          margin-bottom: 12px; } }
    .blog-detail__wrapper .blog-detail--left__img {
      width: 100%;
      height: 629px;
      object-fit: cover;
      object-position: center; }
    .blog-detail__wrapper .blog-detail--left p {
      white-space: pre-line;
      line-height: 1.2;
      word-spacing: 1px; }
    .blog-detail__wrapper .blog-detail--left__desc {
      white-space: pre-line;
      line-height: 1.2;
      word-spacing: 1px; }
  .blog-detail__wrapper .blog-detail--right {
    flex: 0 0 25%;
    width: 25%; }
    .blog-detail__wrapper .blog-detail--right .blog-detail__title--right {
      font-weight: 700;
      margin-bottom: 40px; }
      @media (max-width: 1280px) {
        .blog-detail__wrapper .blog-detail--right .blog-detail__title--right {
          margin-bottom: 32px; } }
      @media (max-width: 640px) {
        .blog-detail__wrapper .blog-detail--right .blog-detail__title--right {
          margin-bottom: 12px; } }
    .blog-detail__wrapper .blog-detail--right .blog {
      display: flex;
      flex-direction: column; }
      .blog-detail__wrapper .blog-detail--right .blog-item {
        padding-top: 8px;
        border-bottom: 1px solid rgba(4, 30, 66, 0.25);
        transition: .3s all linear; }
        .blog-detail__wrapper .blog-detail--right .blog-item__img {
          height: 238px;
          object-position: center;
          object-fit: cover; }
        .blog-detail__wrapper .blog-detail--right .blog-item__content-title {
          margin: 8px 0;
          font-weight: 700; }
        .blog-detail__wrapper .blog-detail--right .blog-item:hover {
          opacity: .7;
          background-color: rgba(192, 192, 192, 0.7); }
    .blog-detail__wrapper .blog-detail--right .share-detail {
      margin-bottom: 32px; }
      @media (max-width: 1280px) {
        .blog-detail__wrapper .blog-detail--right .share-detail {
          margin-bottom: 24px; } }
      @media (max-width: 640px) {
        .blog-detail__wrapper .blog-detail--right .share-detail {
          margin-bottom: 16px; } }
      .blog-detail__wrapper .blog-detail--right .share-detail__title {
        padding-bottom: 5px;
        border-bottom: 1px solid #041E42; }
      .blog-detail__wrapper .blog-detail--right .share-detail-social {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap; }
        .blog-detail__wrapper .blog-detail--right .share-detail-social li + li {
          margin-left: 12px; }
        .blog-detail__wrapper .blog-detail--right .share-detail-social__item {
          margin: 32px 0; }
      .blog-detail__wrapper .blog-detail--right .share-detail-form {
        display: flex;
        flex-direction: column; }
        .blog-detail__wrapper .blog-detail--right .share-detail-form--info {
          padding: 8px 12px;
          font-size: 14px; }
          .blog-detail__wrapper .blog-detail--right .share-detail-form--info.error {
            color: rgba(255, 0, 0, 0.658); }
          .blog-detail__wrapper .blog-detail--right .share-detail-form--info.success {
            color: #4bb543; }
        .blog-detail__wrapper .blog-detail--right .share-detail-form .group-input {
          display: flex;
          flex-direction: row; }
        .blog-detail__wrapper .blog-detail--right .share-detail-form input {
          height: 60px;
          outline: none;
          border: none; }
          .blog-detail__wrapper .blog-detail--right .share-detail-form input:hover, .blog-detail__wrapper .blog-detail--right .share-detail-form input:focus {
            outline: none;
            border: none; }
        .blog-detail__wrapper .blog-detail--right .share-detail-form input[type="email"] {
          width: 70%;
          padding-left: 16px;
          background-color: #ECECEC;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px; }
        .blog-detail__wrapper .blog-detail--right .share-detail-form input[type="submit"] {
          font-weight: bold;
          text-transform: uppercase;
          font-size: 16px;
          padding: 0 8px;
          color: white;
          background-color: #041E42;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          transition: .3s all linear; }
          .blog-detail__wrapper .blog-detail--right .share-detail-form input[type="submit"]:hover, .blog-detail__wrapper .blog-detail--right .share-detail-form input[type="submit"]:active {
            opacity: .9; }

@media (max-width: 1280px) {
  .blog-detail__wrapper .blog-detail--right .share-detail-form input[type="email"] {
    width: 100%;
    border-radius: 4px; }
  .blog-detail__wrapper .blog-detail--right .share-detail-form input[type="submit"] {
    width: 100%;
    border-radius: 4px; }
  .blog-detail__wrapper .blog-detail--right .share-detail-form .group-input {
    flex-direction: column; }
    .blog-detail__wrapper .blog-detail--right .share-detail-form .group-input > *:not(:first-child) {
      margin-top: 12px; } }

@media (max-width: 1024px) {
  .blog-detail__wrapper .blog-detail {
    flex-direction: column; }
  .blog-detail__wrapper .blog-detail--right {
    margin-top: 64px; } }
  @media (max-width: 1024px) and (max-width: 1280px) {
    .blog-detail__wrapper .blog-detail--right {
      margin-top: 40px; } }
  @media (max-width: 1024px) and (max-width: 640px) {
    .blog-detail__wrapper .blog-detail--right {
      margin-top: 32px; } }

@media (max-width: 1024px) {
  .blog-detail__wrapper .blog-detail--left,
  .blog-detail__wrapper .blog-detail--right {
    flex: 0 0 100%;
    width: 100%; }
  .blog-detail__wrapper .blog-detail--right .share-detail-form .group-input {
    width: 60%; }
    .blog-detail__wrapper .blog-detail--right .share-detail-form .group-input input {
      height: 46px; }
      .blog-detail__wrapper .blog-detail--right .share-detail-form .group-input input:focus {
        border: 1px solid #041E42; }
  .blog-detail__wrapper .blog-detail--right .blog-detail__title--right {
    margin-left: 1%; }
  .blog-detail__wrapper .blog-detail--right .blog {
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 1%; }
    .blog-detail__wrapper .blog-detail--right .blog-item {
      width: 50%;
      padding-right: 2%;
      padding-top: 12px;
      border-bottom: 0; } }

@media (max-width: 425px) {
  .blog-detail__wrapper .blog-detail--right .blog-item {
    width: 100%; } }

.dealer-product__wrapper .dealer-product {
  margin: 0 -3%;
  padding-bottom: 64px; }
  @media (max-width: 1280px) {
    .dealer-product__wrapper .dealer-product {
      padding-bottom: 40px; } }
  @media (max-width: 640px) {
    .dealer-product__wrapper .dealer-product {
      padding-bottom: 32px; } }
  .dealer-product__wrapper .dealer-product__title {
    text-align: center;
    margin: 64px 0; }
    @media (max-width: 1280px) {
      .dealer-product__wrapper .dealer-product__title {
        margin: 40px 0; } }
    @media (max-width: 640px) {
      .dealer-product__wrapper .dealer-product__title {
        margin: 32px 0; } }
  .dealer-product__wrapper .dealer-product-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -1%;
    margin-right: -1%;
    margin-bottom: 40px; }
    @media (max-width: 1280px) {
      .dealer-product__wrapper .dealer-product-list {
        margin-bottom: 32px; } }
    @media (max-width: 640px) {
      .dealer-product__wrapper .dealer-product-list {
        margin-bottom: 12px; } }
  .dealer-product__wrapper .dealer-product-item {
    flex: 0 0 25%;
    padding: 1%; }
    .dealer-product__wrapper .dealer-product-item__link {
      display: inline-block;
      transition: 0.3s; }
      .dealer-product__wrapper .dealer-product-item__link:hover {
        background-color: #ECECEC; }
    .dealer-product__wrapper .dealer-product-item__img {
      width: 322px;
      height: 226px;
      object-fit: cover;
      object-position: center; }
    .dealer-product__wrapper .dealer-product-item__content {
      padding: 5px 0; }
    .dealer-product__wrapper .dealer-product-item__title {
      letter-spacing: 2px;
      font-weight: 700;
      margin: 12px 0;
      text-transform: uppercase; }
    .dealer-product__wrapper .dealer-product-item__desc {
      padding: 12px 0;
      border-bottom: 1px solid #707070;
      color: #707070; }
  .dealer-product__wrapper .dealer-product .releases-pagination {
    width: 315px; }

@media (max-width: 1280px) {
  .dealer-product__wrapper .dealer-product-item {
    flex: 0 0 33.3333%; } }

@media (max-width: 1024px) {
  .dealer-product__wrapper .dealer-product {
    margin: 0; }
  .dealer-product__wrapper .dealer-product-item {
    flex: 0 0 50%; }
  .dealer-product__wrapper .dealer-product-item__link {
    width: 100%; }
  .dealer-product__wrapper .dealer-product-item__img {
    width: 100%;
    object-fit: cover;
    object-position: center; } }

@media (max-width: 640px) {
  .dealer-product__wrapper .dealer-product-item {
    flex: 0 0 100%; }
  .dealer-product__wrapper .dealer-product-item__content {
    text-align: center; } }

.dealer-help__wrapper {
  background: #ECECEC;
  padding-bottom: 64px; }
  @media (max-width: 1280px) {
    .dealer-help__wrapper {
      padding-bottom: 40px; } }
  @media (max-width: 640px) {
    .dealer-help__wrapper {
      padding-bottom: 32px; } }
  .dealer-help__wrapper .container {
    padding-left: 0;
    padding-right: 0; }
    .dealer-help__wrapper .container .dealer-help .help-title {
      text-align: center;
      padding: 64px 0; }
      @media (max-width: 1280px) {
        .dealer-help__wrapper .container .dealer-help .help-title {
          padding: 40px 0; } }
      @media (max-width: 640px) {
        .dealer-help__wrapper .container .dealer-help .help-title {
          padding: 32px 0; } }
    .dealer-help__wrapper .container .dealer-help .help-section {
      align-self: center;
      justify-content: space-between;
      margin: auto; }
      .dealer-help__wrapper .container .dealer-help .help-section .col, .dealer-help__wrapper .container .dealer-help .help-section [class*=col-] {
        padding-left: 15px;
        padding-right: 15px; }
      .dealer-help__wrapper .container .dealer-help .help-section .dealer-items .img-section {
        position: relative; }
        .dealer-help__wrapper .container .dealer-help .help-section .dealer-items .img-section img {
          width: 100%; }
        .dealer-help__wrapper .container .dealer-help .help-section .dealer-items .img-section .overlay-section {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          position: absolute;
          bottom: 0;
          left: 0;
          padding: 10px 0;
          width: 100%;
          background: #041E42; }
          .dealer-help__wrapper .container .dealer-help .help-section .dealer-items .img-section .overlay-section .title {
            font-size: 27px;
            font-family: HELVETICANEUELTSTD-ROMAN;
            color: #C0C0C0;
            transform: translateY(4px); }
          .dealer-help__wrapper .container .dealer-help .help-section .dealer-items .img-section .overlay-section .more {
            margin: 0;
            border-color: #C0C0C0; }
            .dealer-help__wrapper .container .dealer-help .help-section .dealer-items .img-section .overlay-section .more p {
              color: #C0C0C0; }

@media (max-width: 1440px) {
  .dealer-help__wrapper .container .dealer-help .help-section .dealer-items .img-section .overlay-section .title, .dealer-help__wrapper .container .dealer-help .help-section .dealer-items .img-section .overlay-section .more {
    font-size: 20px; } }

@media (max-width: 1280px) {
  .dealer-help__wrapper .container .dealer-help .help-section .dealer-items .img-section .overlay-section .more {
    font-size: 20px; }
  .dealer-help__wrapper .container .dealer-help .help-section .dealer-items .img-section .overlay-section .title {
    font-size: 22px; }
  .dealer-help__wrapper .container .dealer-help .help-section {
    flex-direction: column; }
    .dealer-help__wrapper .container .dealer-help .help-section > * {
      flex: 0 0 80%;
      max-width: 80%;
      margin-left: auto;
      margin-right: auto; }
    .dealer-help__wrapper .container .dealer-help .help-section .dealer-items + .dealer-items {
      margin-top: 40px; } }
    @media (max-width: 1280px) and (max-width: 1280px) {
      .dealer-help__wrapper .container .dealer-help .help-section .dealer-items + .dealer-items {
        margin-top: 32px; } }
    @media (max-width: 1280px) and (max-width: 640px) {
      .dealer-help__wrapper .container .dealer-help .help-section .dealer-items + .dealer-items {
        margin-top: 12px; } }

@media (max-width: 640px) {
  .dealer-help__wrapper .container .dealer-help .help-section .dealer-items .img-section .overlay-section .more {
    font-size: 16px; }
  .dealer-help__wrapper .container .dealer-help .help-section .dealer-items .img-section .overlay-section .title {
    font-size: 20px; }
  .dealer-help__wrapper .container .dealer-help .help-section > * {
    flex: 0 0 100%;
    max-width: 100%; } }

@media (max-width: 425px) {
  .dealer-help__wrapper .container .dealer-help .help-section .dealer-items .img-section .overlay-section .more {
    font-size: 0px;
    padding: 5px 5px; }
  .dealer-help__wrapper .container .dealer-help .help-section .dealer-items .img-section .overlay-section .title {
    font-size: 16px; } }

.dealer-new__wrapper {
  padding-bottom: 64px; }
  @media (max-width: 1280px) {
    .dealer-new__wrapper {
      padding-bottom: 40px; } }
  @media (max-width: 640px) {
    .dealer-new__wrapper {
      padding-bottom: 32px; } }
  .dealer-new__wrapper .dealer-new .new-title {
    text-align: center;
    padding: 80px 0; }
  .dealer-new__wrapper .dealer-new .new-section .col, .dealer-new__wrapper .dealer-new .new-section [class*=col-] {
    padding-left: 15px;
    padding-right: 15px; }
  .dealer-new__wrapper .dealer-new .new-section .dealer-items .img-section {
    position: relative; }
    .dealer-new__wrapper .dealer-new .new-section .dealer-items .img-section img {
      width: 100%; }
    .dealer-new__wrapper .dealer-new .new-section .dealer-items .img-section .overlay-section {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px 0;
      width: 100%;
      background: #041E42; }
      .dealer-new__wrapper .dealer-new .new-section .dealer-items .img-section .overlay-section .title {
        font-size: 27px;
        font-family: HELVETICANEUELTSTD-ROMAN;
        color: #C0C0C0;
        transform: translateY(4px); }
      .dealer-new__wrapper .dealer-new .new-section .dealer-items .img-section .overlay-section .more {
        margin: 0;
        border-color: #C0C0C0; }
        .dealer-new__wrapper .dealer-new .new-section .dealer-items .img-section .overlay-section .more p {
          color: #C0C0C0; }

@media (max-width: 1280px) {
  .dealer-new__wrapper .dealer-new .new-section {
    flex-direction: column; }
    .dealer-new__wrapper .dealer-new .new-section > * {
      flex: 0 0 80%;
      max-width: 80%;
      margin-left: auto;
      margin-right: auto; }
    .dealer-new__wrapper .dealer-new .new-section > *:not(:first-child) {
      margin-top: 50px; }
  .dealer-new__wrapper .dealer-new .new-title {
    padding: 50px; } }

@media (max-width: 1024px) {
  .dealer-new__wrapper .dealer-new .new-title {
    padding: 36px 0; } }

@media (max-width: 640px) {
  .dealer-new__wrapper .dealer-new .new-section > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .dealer-new__wrapper .dealer-new .new-section > *:not(:first-child) {
    margin-top: 36px; } }

.dealer-navigation__wrapper {
  padding-bottom: 64px; }
  @media (max-width: 1280px) {
    .dealer-navigation__wrapper {
      padding-bottom: 40px; } }
  @media (max-width: 640px) {
    .dealer-navigation__wrapper {
      padding-bottom: 32px; } }
  .dealer-navigation__wrapper .container {
    padding-right: 0;
    padding-left: 0; }
    .dealer-navigation__wrapper .container .dealer-navigation {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .dealer-navigation__wrapper .container .dealer-navigation .more {
        margin: 0;
        padding: 16px 10px; }
        .dealer-navigation__wrapper .container .dealer-navigation .more p {
          font-size: 27px;
          margin-right: 20px;
          text-transform: initial; }
      .dealer-navigation__wrapper .container .dealer-navigation .navigate-text__items {
        font-size: 27px;
        color: #041E42; }
        .dealer-navigation__wrapper .container .dealer-navigation .navigate-text__items:last-child {
          margin-right: 0; }
      .dealer-navigation__wrapper .container .dealer-navigation .navigate-text__items:not(:last-child) {
        position: relative; }
        .dealer-navigation__wrapper .container .dealer-navigation .navigate-text__items:not(:last-child)::after {
          position: absolute;
          top: 0;
          right: -50%;
          width: 1px;
          height: 100%;
          content: "";
          background: #041E42; }

@media (max-width: 1440px) {
  .dealer-navigation__wrapper .container .dealer-navigation .navigate-text__items:not(:last-child)::after {
    right: -20%; } }

@media (max-width: 1280px) {
  .dealer-navigation__wrapper .container .dealer-navigation .navigate-text__items:not(:last-child)::after {
    right: -20%; }
  .dealer-navigation__wrapper .container .dealer-navigation .more p {
    font-size: 20px; }
  .dealer-navigation__wrapper .container .dealer-navigation .navigate-text__items {
    font-size: 20px; } }

@media (max-width: 1024px) {
  .dealer-navigation__wrapper .container .dealer-navigation .navigate-text__items:not(:last-child)::after {
    display: none; }
  .dealer-navigation__wrapper .container .dealer-navigation {
    flex-direction: column; }
  .dealer-navigation__wrapper .container .dealer-navigation .navigate-text__items {
    padding: 12px;
    margin-top: 12px; }
  .dealer-navigation__wrapper .container .dealer-navigation .more {
    padding: 8px 12px; }
  .dealer-navigation__wrapper {
    padding: 0 0 36px 0; } }

@media (max-width: 640px) {
  .dealer-navigation__wrapper .container .dealer-navigation .more p {
    font-size: 18px; }
  .dealer-navigation__wrapper .container .dealer-navigation .navigate-text__items {
    font-size: 18px; }
  .dealer-navigation__wrapper .container .dealer-navigation .more p {
    margin-right: 8px; } }

@media (max-width: 320px) {
  .dealer-navigation__wrapper .container .dealer-navigation .more p {
    font-size: 16px; }
  .dealer-navigation__wrapper .container .dealer-navigation .navigate-text__items {
    font-size: 16px; }
  .dealer-navigation__wrapper .container .dealer-navigation .more p {
    margin-right: 8px; } }

.dealer-navigation-digital__wrapper .dealer-navigation-digital {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 64px 0; }
  @media (max-width: 1280px) {
    .dealer-navigation-digital__wrapper .dealer-navigation-digital {
      margin: 40px 0; } }
  @media (max-width: 640px) {
    .dealer-navigation-digital__wrapper .dealer-navigation-digital {
      margin: 32px 0; } }
  .dealer-navigation-digital__wrapper .dealer-navigation-digital .nav-items {
    cursor: pointer;
    padding: 20px 35px 20px 6px;
    position: relative;
    font-size: 27px;
    color: #041E42;
    white-space: nowrap;
    min-width: 20%; }
    .dealer-navigation-digital__wrapper .dealer-navigation-digital .nav-items:not(:first-child) {
      margin-left: 120px; }
      .dealer-navigation-digital__wrapper .dealer-navigation-digital .nav-items:not(:first-child)::before {
        position: absolute;
        content: "";
        top: 50%;
        left: -60px;
        transform: translate(-50%, -50%);
        height: 50%;
        width: 2px;
        background: #041E42; }
    .dealer-navigation-digital__wrapper .dealer-navigation-digital .nav-items img {
      display: none; }
  .dealer-navigation-digital__wrapper .dealer-navigation-digital .active {
    border: solid 2px #041E42;
    border-radius: 6px;
    position: relative; }
    .dealer-navigation-digital__wrapper .dealer-navigation-digital .active::after {
      margin-left: 3px;
      width: 2rem;
      height: 2rem;
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
      content: "";
      background-image: url("../assets/images/dealer-digital/arrow.png");
      background-repeat: no-repeat;
      background-position: center; }

@media (max-width: 1280px) {
  .dealer-navigation-digital__wrapper .dealer-navigation-digital {
    flex-direction: column; }
  .dealer-navigation-digital__wrapper .dealer-navigation-digital .nav-items:not(:first-child)::before {
    display: none; }
  .dealer-navigation-digital__wrapper .dealer-navigation-digital .nav-items:not(:first-child) {
    margin-left: 0; } }

@media (max-width: 1024px) {
  .dealer-navigation-digital__wrapper .dealer-navigation-digital .nav-items {
    padding: 10px 40px 10px 10px;
    margin-right: 0;
    margin-top: 12px; } }

.dealer-digital-list__wrapper {
  margin-bottom: 64px; }
  @media (max-width: 1280px) {
    .dealer-digital-list__wrapper {
      margin-bottom: 40px; } }
  @media (max-width: 640px) {
    .dealer-digital-list__wrapper {
      margin-bottom: 32px; } }
  .dealer-digital-list__wrapper .dealer-digital-list .list-items {
    margin-top: 128px;
    position: relative; }
    @media (max-width: 1280px) {
      .dealer-digital-list__wrapper .dealer-digital-list .list-items {
        margin-top: 64px; } }
    .dealer-digital-list__wrapper .dealer-digital-list .list-items .row {
      align-items: center; }
      .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__img {
        width: 828px;
        height: 582px;
        display: flex;
        align-items: center;
        position: relative; }
        .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__img img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center; }
      .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__infor .product-infor {
        padding-right: 64px;
        padding-left: 64px; }
        @media (max-width: 1280px) {
          .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__infor .product-infor {
            padding-right: 40px; } }
        @media (max-width: 640px) {
          .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__infor .product-infor {
            padding-right: 32px; } }
        @media (max-width: 1280px) {
          .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__infor .product-infor {
            padding-left: 40px; } }
        @media (max-width: 640px) {
          .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__infor .product-infor {
            padding-left: 32px; } }
        .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__infor .product-infor .product-infor__title {
          text-transform: uppercase;
          color: #041E42;
          font-family: "HELVETICA-NEUE", "Segoe UI", sans-serif;
          font-size: 30px;
          font-weight: 500;
          font-weight: bold; }
          @media (max-width: 1024px) {
            .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__infor .product-infor .product-infor__title {
              font-size: 26px; } }
          @media (max-width: 640px) {
            .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__infor .product-infor .product-infor__title {
              font-size: 22px; } }
        .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__infor .product-infor .product-infor__content {
          justify-content: flex-end;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          overflow: hidden; }
        .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__infor .product-infor .product-infor__more {
          color: #707070;
          margin-bottom: 12px;
          display: block;
          width: 100%; }
        .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__infor .product-infor .btn {
          margin-bottom: 0; }
    .dealer-digital-list__wrapper .dealer-digital-list .list-items:first-child {
      margin-top: 0; }
    .dealer-digital-list__wrapper .dealer-digital-list .list-items:nth-child(even) .items__img {
      text-align: end; }
    .dealer-digital-list__wrapper .dealer-digital-list .list-items:nth-child(even) .row {
      flex-direction: row; }
      .dealer-digital-list__wrapper .dealer-digital-list .list-items:nth-child(even) .row .items__infor .product-infor {
        display: flex;
        flex-direction: column;
        align-items: flex-start; }
        .dealer-digital-list__wrapper .dealer-digital-list .list-items:nth-child(even) .row .items__infor .product-infor .product-infor__content {
          text-align: left; }
        .dealer-digital-list__wrapper .dealer-digital-list .list-items:nth-child(even) .row .items__infor .product-infor .product-infor__more {
          text-align: left; }
    .dealer-digital-list__wrapper .dealer-digital-list .list-items:nth-child(odd) .row {
      flex-direction: row-reverse; }
      .dealer-digital-list__wrapper .dealer-digital-list .list-items:nth-child(odd) .row .items__infor .product-infor {
        display: flex;
        flex-direction: column;
        align-items: flex-end; }
        .dealer-digital-list__wrapper .dealer-digital-list .list-items:nth-child(odd) .row .items__infor .product-infor .product-infor__content {
          text-align: end; }
        .dealer-digital-list__wrapper .dealer-digital-list .list-items:nth-child(odd) .row .items__infor .product-infor .product-infor__more {
          text-align: right; }
    .dealer-digital-list__wrapper .dealer-digital-list .list-items .underlay-background {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 100%;
      height: 450px;
      background: #ECECEC;
      z-index: -1; }

@media (max-width: 1280px) {
  .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__infor .product-infor {
    padding: 0 24px; }
  .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__img {
    width: 711px;
    height: 500px; } }

@media (max-width: 1024px) {
  .dealer-digital-list__wrapper .dealer-digital-list .list-items:first-child {
    margin-top: 80px; } }

@media (max-width: 768px) {
  .dealer-digital-list__wrapper .dealer-digital-list .list-items:nth-child(odd) .row {
    flex-direction: column; }
  .dealer-digital-list__wrapper .dealer-digital-list .list-items:first-child {
    margin-top: 42px; }
  .dealer-digital-list__wrapper .dealer-digital-list .list-items .underlay-background {
    height: calc(100% + 36px); }
  .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__img {
    width: 100%;
    max-width: 100%; }
  .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__infor {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 36px; }
    .dealer-digital-list__wrapper .dealer-digital-list .list-items .row .items__infor .product-infor {
      padding: 0; }
  .dealer-digital-list__wrapper .dealer-digital-list .list-items:nth-child(odd) .row .items__infor .product-infor {
    align-items: flex-start; }
  .dealer-digital-list__wrapper .dealer-digital-list .list-items:nth-child(odd) .row .items__infor .product-infor .product-infor__content {
    text-align: start; } }

@media (max-width: 768px) {
  .dealer-navigation-digital__wrapper .dealer-navigation-digital .nav-items {
    font-size: 18px; } }

.wholesale--dealer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 64px;
  margin-bottom: 128px; }
  @media (max-width: 1280px) {
    .wholesale--dealer {
      margin-top: 40px; } }
  @media (max-width: 640px) {
    .wholesale--dealer {
      margin-top: 32px; } }
  @media (max-width: 1280px) {
    .wholesale--dealer {
      margin-bottom: 64px; } }
  .wholesale--dealer__content1 {
    display: flex;
    flex-direction: row;
    justify-content: center; }
    .wholesale--dealer__content1 .left1 {
      color: #041E42;
      width: 50%;
      padding-right: 10%; }
    .wholesale--dealer__content1 .right1 {
      color: #707070;
      width: 50%;
      padding-right: 10%; }
  .wholesale--dealer__content2 {
    display: flex;
    flex-direction: row;
    margin-top: 64px;
    margin-bottom: 128px; }
    @media (max-width: 1280px) {
      .wholesale--dealer__content2 {
        margin-top: 40px; } }
    @media (max-width: 640px) {
      .wholesale--dealer__content2 {
        margin-top: 32px; } }
    @media (max-width: 1280px) {
      .wholesale--dealer__content2 {
        margin-bottom: 64px; } }
    .wholesale--dealer__content2 .left2 {
      justify-content: flex-end;
      width: 50%;
      padding: 0 10% 0 0%; }
      .wholesale--dealer__content2 .left2 .content {
        width: 100%;
        display: grid;
        gap: 12px;
        grid-auto-columns: auto;
        grid-auto-rows: auto;
        min-height: 550px; }
        .wholesale--dealer__content2 .left2 .content img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
        .wholesale--dealer__content2 .left2 .content img:nth-child(1) {
          grid-column: 1 / span 3;
          grid-row: 1 / span 4; }
        .wholesale--dealer__content2 .left2 .content img:nth-child(2) {
          grid-column: 4 / span 2;
          grid-row: 2 / span 4; }
        .wholesale--dealer__content2 .left2 .content img:nth-child(3) {
          grid-column: 2 / span 2;
          grid-row: 5 / span 3; }
        .wholesale--dealer__content2 .left2 .content img:nth-child(4) {
          grid-column: 4 / span 3;
          grid-row: 6 / span 8; }
    .wholesale--dealer__content2 .right2 {
      padding-right: 10%;
      width: 50%; }
      .wholesale--dealer__content2 .right2 .content2 {
        margin-top: 24px;
        list-style-type: initial; }
        .wholesale--dealer__content2 .right2 .content2 li {
          margin: 12px 0; }
  .wholesale--dealer__content3 .title3 {
    text-align: center;
    margin-bottom: 64px; }
    @media (max-width: 1280px) {
      .wholesale--dealer__content3 .title3 {
        margin-bottom: 40px; } }
    @media (max-width: 640px) {
      .wholesale--dealer__content3 .title3 {
        margin-bottom: 32px; } }
  .wholesale--dealer__content3 .content {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px 40px; }
    .wholesale--dealer__content3 .content .box .images img {
      width: 100%;
      height: 260px;
      object-fit: cover;
      object-position: center; }
    .wholesale--dealer__content3 .content .title {
      color: #041E42;
      margin: 20px 0;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden; }
    .wholesale--dealer__content3 .content .btn a {
      border: none;
      font-style: italic;
      padding-left: 0;
      background-color: transparent; }
      .wholesale--dealer__content3 .content .btn a:hover {
        cursor: pointer;
        background-color: transparent;
        color: #041E42;
        opacity: .7; }
        .wholesale--dealer__content3 .content .btn a:hover svg path {
          stroke: #041E42; }
    .wholesale--dealer__content3 .content .text {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      font-size: 20px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      white-space: wrap;
      color: #707070;
      margin: 15px 0; }

@media (max-width: 1024px) {
  .wholesale--dealer__content3 .content {
    grid-template-columns: auto auto;
    gap: 20px; } }

@media (max-width: 1280px) {
  .wholesale--dealer__content1 {
    flex-direction: column; }
    .wholesale--dealer__content1 .left1, .wholesale--dealer__content1 .right1 {
      width: 100%; }
    .wholesale--dealer__content1 .right1 {
      padding-top: 24px; }
  .wholesale--dealer__content2 {
    flex-direction: column; }
    .wholesale--dealer__content2 .left2 {
      width: 100%;
      padding: 0; }
    .wholesale--dealer__content2 .right2 {
      width: 100%;
      padding-top: 24px;
      padding-right: 0; }
      .wholesale--dealer__content2 .right2 .content2 {
        padding-left: 24px; }
  .wholesale--dealer__content3 .content {
    grid-template-columns: auto;
    gap: 20px; }
  .wholesale--dealer__content3 .content .box .images img {
    height: 360px; } }

@media (max-width: 425px) {
  .wholesale--dealer__content3 .content .box .images img {
    height: 240px; } }

@media (max-width: 768px) {
  .wholesale--dealer__content2 .left2 .content {
    display: flex;
    flex-direction: column; }
    .wholesale--dealer__content2 .left2 .content > * {
      min-height: 320px;
      max-height: 480px; }
    .wholesale--dealer__content2 .left2 .content > *:not(:first-child) {
      margin-top: 12px; } }

.wholesale--commitmet {
  background-color: #041e42;
  padding: 64px 0; }
  @media (max-width: 1280px) {
    .wholesale--commitmet {
      padding: 40px 0; } }
  @media (max-width: 640px) {
    .wholesale--commitmet {
      padding: 32px 0; } }
  .wholesale--commitmet__title {
    text-align: center;
    margin-bottom: 64px; }
    @media (max-width: 1280px) {
      .wholesale--commitmet__title {
        margin-bottom: 40px; } }
    @media (max-width: 640px) {
      .wholesale--commitmet__title {
        margin-bottom: 32px; } }
    .wholesale--commitmet__title .title1 {
      color: #c0c0c0; }
    .wholesale--commitmet__title .title2 {
      margin-top: 32px;
      color: #041E42;
      font-family: "HELVETICA-NEUE", "Segoe UI", sans-serif;
      font-size: 20px;
      font-weight: 500;
      font-family: inherit;
      font-weight: 400;
      color: #c0c0c0;
      text-transform: uppercase; }
      @media (max-width: 1280px) {
        .wholesale--commitmet__title .title2 {
          margin-top: 24px; } }
      @media (max-width: 640px) {
        .wholesale--commitmet__title .title2 {
          margin-top: 16px; } }
      @media (max-width: 1024px) {
        .wholesale--commitmet__title .title2 {
          font-size: 19px; } }
      @media (max-width: 640px) {
        .wholesale--commitmet__title .title2 {
          font-size: 18px; } }
  .wholesale--commitmet__content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 100%;
    margin: auto;
    flex-wrap: wrap; }
    .wholesale--commitmet__content .commitmet__link {
      color: #c0c0c0;
      width: 240px;
      flex: 0 0 33.333%;
      margin-top: 40px; }
      @media (max-width: 1280px) {
        .wholesale--commitmet__content .commitmet__link {
          margin-top: 32px; } }
      @media (max-width: 640px) {
        .wholesale--commitmet__content .commitmet__link {
          margin-top: 12px; } }
      .wholesale--commitmet__content .commitmet__link p {
        margin-top: 12px;
        text-align: center; }
      .wholesale--commitmet__content .commitmet__link img {
        width: 112px;
        height: 112px;
        object-position: center;
        object-fit: cover;
        border-radius: 50%;
        transition: 0.3s; }
      .wholesale--commitmet__content .commitmet__link:hover img {
        transform: scale(1.1); }

@media (max-width: 1024px) {
  .wholesale--commitmet__content .commitmet__link {
    margin: 24px 0;
    flex: 0 0 50%; } }

@media (max-width: 640px) {
  .wholesale--commitmet__content .commitmet__link {
    margin: 24px 0;
    flex: 0 0 100%; } }

.wholesale--family {
  background-color: #c1bfbf;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 64px 0; }
  @media (max-width: 1280px) {
    .wholesale--family {
      padding: 40px 0; } }
  @media (max-width: 640px) {
    .wholesale--family {
      padding: 32px 0; } }
  .wholesale--family__content {
    width: 686px; }
    .wholesale--family__content .title {
      margin-bottom: 64px; }
      @media (max-width: 1280px) {
        .wholesale--family__content .title {
          margin-bottom: 40px; } }
      @media (max-width: 640px) {
        .wholesale--family__content .title {
          margin-bottom: 32px; } }
    .wholesale--family__content .content {
      text-transform: uppercase;
      margin-bottom: 32px;
      font-weight: 700;
      font-family: inherit; }
      @media (max-width: 1280px) {
        .wholesale--family__content .content {
          margin-bottom: 24px; } }
      @media (max-width: 640px) {
        .wholesale--family__content .content {
          margin-bottom: 16px; } }
    .wholesale--family__content .form-input {
      display: flex;
      flex-direction: column; }
      .wholesale--family__content .form-input .row-input {
        display: flex;
        justify-content: space-between; }
        .wholesale--family__content .form-input .row-input input {
          padding-left: 10px;
          width: 49%;
          border-radius: 8px;
          border: 1px;
          height: 50px;
          margin: 10px 0;
          letter-spacing: 1px;
          transition: 0.3s; }
        .wholesale--family__content .form-input .row-input input:focus,
        .wholesale--family__content .form-input .row-input textarea:focus {
          border: 1px solid #041E42; }
      .wholesale--family__content .form-input .colum-input input {
        padding-left: 10px;
        border-radius: 8px;
        border: 1px;
        height: 50px;
        margin: 10px 0;
        width: 100%;
        letter-spacing: 1px;
        transition: 0.3s; }
      .wholesale--family__content .form-input .colum-input textarea {
        width: 100%;
        height: 150px;
        border-radius: 8px;
        outline: 0;
        padding-left: 10px;
        line-height: 40px;
        resize: none;
        letter-spacing: 1px;
        outline: none;
        transition: 0.3s;
        border: 0;
        margin: 10px 0; }
      .wholesale--family__content .form-input .colum-input input:focus,
      .wholesale--family__content .form-input .colum-input textarea:focus {
        border: 1px solid #041E42; }
      .wholesale--family__content .form-input .button-send input[type="submit"] {
        padding: 15px;
        margin-top: 18px;
        color: #041E42;
        border: 3px solid #041E42;
        border-radius: 8px;
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase; }
        .wholesale--family__content .form-input .button-send input[type="submit"]:hover {
          background-color: #041E42;
          color: white; }

@media (max-width: 900px) {
  .wholesale--family__content {
    width: 90%; } }

@media (max-width: 640px) {
  .wholesale--family__content {
    width: 96%; }
  input {
    width: 100% !important; }
  .wholesale--family__content .form-input .row-input {
    flex-direction: column; } }

.wholesale-service {
  margin: 64px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around; }
  @media (max-width: 1280px) {
    .wholesale-service {
      margin: 40px 0; } }
  @media (max-width: 640px) {
    .wholesale-service {
      margin: 32px 0; } }
  .wholesale-service-left {
    flex-basis: 50%;
    width: 50%;
    position: relative;
    padding-right: 42px;
    padding-left: 42px; }
    .wholesale-service-left .swiper-button-next,
    .wholesale-service-left .swiper-button-prev {
      background-color: #C0C0C0;
      background-repeat: no-repeat;
      background-size: auto;
      background-position: center;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); }
      .wholesale-service-left .swiper-button-next::after,
      .wholesale-service-left .swiper-button-prev::after {
        content: ""; }
      .wholesale-service-left .swiper-button-next:hover,
      .wholesale-service-left .swiper-button-prev:hover {
        background-color: white; }
    .wholesale-service-left .swiper-button-next {
      background-image: url("../assets/icons/arrowRight.png"); }
    .wholesale-service-left .swiper-button-prev {
      background-image: url("../assets/icons/arrowLeft.png"); }
    .wholesale-service-left .title {
      color: #041E42;
      font-family: "HELVETICA-NEUE", "Segoe UI", sans-serif;
      font-size: 50px;
      font-weight: 500;
      color: #041e42;
      position: relative; }
      @media (max-width: 1024px) {
        .wholesale-service-left .title {
          font-size: 40px; } }
      @media (max-width: 640px) {
        .wholesale-service-left .title {
          font-size: 32px; } }
      .wholesale-service-left .title span {
        color: #041E42;
        font-family: "HELVETICA-NEUE", "Segoe UI", sans-serif;
        font-size: 65px;
        font-weight: 500; }
        @media (max-width: 1024px) {
          .wholesale-service-left .title span {
            font-size: 50px; } }
        @media (max-width: 640px) {
          .wholesale-service-left .title span {
            font-size: 45px; } }
      .wholesale-service-left .title span::after {
        position: absolute;
        bottom: 25px;
        left: 0;
        width: 80px;
        transform: rotate(120deg);
        height: 1px;
        background: #041e42;
        content: ""; }
    .wholesale-service-left .text {
      margin: 40px 0; }
      @media (max-width: 1280px) {
        .wholesale-service-left .text {
          margin: 32px 0; } }
      @media (max-width: 640px) {
        .wholesale-service-left .text {
          margin: 12px 0; } }
      .wholesale-service-left .text p {
        margin: 10px 0;
        padding-left: 60px; }
    .wholesale-service-left .images {
      max-width: 100%;
      margin-left: 0;
      position: relative; }
      .wholesale-service-left .images .swiper-pagination {
        left: 50%;
        transform: translateX(-50%); }
        .wholesale-service-left .images .swiper-pagination-bullet {
          margin: 0 6px;
          position: relative; }
          .wholesale-service-left .images .swiper-pagination-bullet-active {
            background-color: #977; }
          .wholesale-service-left .images .swiper-pagination-bullet-active::before {
            content: "";
            position: absolute;
            width: 5px;
            height: 5px;
            background-color: #041E42;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%; }
      .wholesale-service-left .images .swiper-container {
        height: 100%; }
      .wholesale-service-left .images img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center; }
  .wholesale-service-right {
    flex-basis: 50%;
    width: 50%;
    padding-left: 3%; }
    .wholesale-service-right .images {
      width: 100%;
      height: 100%; }
      .wholesale-service-right .images img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center; }

@media (max-width: 1440px) {
  .wholesale-service {
    padding: 0 0 64px 0; } }

@media (max-width: 1024px) {
  .wholesale-service-right .images {
    height: auto; } }

@media (max-width: 768px) {
  .wholesale-service {
    flex-direction: column;
    align-items: center; }
  .wholesale-service-left,
  .wholesale-service-right {
    width: 100%;
    flex: 0 0 100%; }
  .wholesale-service-left {
    padding-right: 0; }
  .wholesale-service-right {
    padding-left: 0;
    margin-top: 32px; }
  .wholesale-service-left .images {
    margin: 0 auto; }
  .wholesale-service-left .text {
    text-align: center; }
  .wholesale-service-left .title {
    text-align: center; }
    .wholesale-service-left .title h3 {
      display: inline-block;
      position: relative; }
  .wholesale-service-left .text p {
    padding-left: 0; } }

@media (max-width: 640px) {
  .wholesale-service {
    padding: 0; }
  .wholesale-service-right .images img {
    max-height: 450px; } }

.wholesale-factory__background {
  position: relative;
  height: 450px;
  width: 100%; }
  .wholesale-factory__background img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }
  .wholesale-factory__background .opacity {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0)); }

.wholesale-factory__content1 {
  padding: 64px 0;
  width: 30%;
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.4); }
  @media (max-width: 1280px) {
    .wholesale-factory__content1 {
      padding: 40px 0; } }
  @media (max-width: 640px) {
    .wholesale-factory__content1 {
      padding: 32px 0; } }
  .wholesale-factory__content1 .text {
    color: white; }
  .wholesale-factory__content1 p {
    margin-top: 40px;
    color: white; }
    @media (max-width: 1280px) {
      .wholesale-factory__content1 p {
        margin-top: 32px; } }
    @media (max-width: 640px) {
      .wholesale-factory__content1 p {
        margin-top: 12px; } }

.wholesale-factory__menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 64px 0; }
  @media (max-width: 1280px) {
    .wholesale-factory__menu {
      margin: 40px 0; } }
  @media (max-width: 640px) {
    .wholesale-factory__menu {
      margin: 32px 0; } }
  .wholesale-factory__menu .menu-1,
  .wholesale-factory__menu .menu-2,
  .wholesale-factory__menu .menu-3 {
    width: 100%;
    max-width: 470px; }
    .wholesale-factory__menu .menu-1 .title,
    .wholesale-factory__menu .menu-2 .title,
    .wholesale-factory__menu .menu-3 .title {
      color: #041E42;
      font-family: "HELVETICA-NEUE", "Segoe UI", sans-serif;
      font-size: 30px;
      font-weight: 500;
      color: #041e42;
      margin: 40px 0; }
      @media (max-width: 1024px) {
        .wholesale-factory__menu .menu-1 .title,
        .wholesale-factory__menu .menu-2 .title,
        .wholesale-factory__menu .menu-3 .title {
          font-size: 26px; } }
      @media (max-width: 640px) {
        .wholesale-factory__menu .menu-1 .title,
        .wholesale-factory__menu .menu-2 .title,
        .wholesale-factory__menu .menu-3 .title {
          font-size: 22px; } }
      @media (max-width: 1280px) {
        .wholesale-factory__menu .menu-1 .title,
        .wholesale-factory__menu .menu-2 .title,
        .wholesale-factory__menu .menu-3 .title {
          margin: 32px 0; } }
      @media (max-width: 640px) {
        .wholesale-factory__menu .menu-1 .title,
        .wholesale-factory__menu .menu-2 .title,
        .wholesale-factory__menu .menu-3 .title {
          margin: 12px 0; } }
  .wholesale-factory__menu .menu-2 {
    border-left: 1px dashed #041e42;
    border-right: 1px dashed #041e42; }

.wholesale-factory__content2 {
  height: 700px;
  position: relative;
  display: flex;
  justify-content: space-between; }
  .wholesale-factory__content2 .left {
    width: 43.22916%;
    height: 100%;
    background-color: #041e42;
    position: relative; }
    .wholesale-factory__content2 .left::after {
      content: "";
      position: absolute;
      top: 5%;
      left: 50%;
      transform: translateX(-50%);
      background-image: url("../assets/wholesale/icons/ws-s-2.png");
      background-repeat: no-repeat;
      background-size: contain;
      width: 86px;
      height: 86px; }
  .wholesale-factory__content2 .center {
    position: absolute;
    bottom: 0;
    left: 43.22916%;
    transform: translateX(-50%);
    width: 30%;
    display: flex;
    justify-content: center; }
  .wholesale-factory__content2 .right {
    position: relative;
    height: 100%;
    width: 56.77083%; }
    .wholesale-factory__content2 .right .content {
      position: absolute;
      top: 35%;
      left: 30%;
      right: 25%;
      bottom: 10%; }
      .wholesale-factory__content2 .right .content .title {
        color: #041E42;
        font-family: "HELVETICA-NEUE", "Segoe UI", sans-serif;
        font-size: 50px;
        font-weight: 500; }
        @media (max-width: 1024px) {
          .wholesale-factory__content2 .right .content .title {
            font-size: 40px; } }
        @media (max-width: 640px) {
          .wholesale-factory__content2 .right .content .title {
            font-size: 32px; } }
      .wholesale-factory__content2 .right .content .subtitle {
        display: block;
        overflow: hidden;
        color: #041E42;
        margin: 10px 0 15px 0;
        position: relative; }
        .wholesale-factory__content2 .right .content .subtitle::after {
          position: absolute;
          top: 50%;
          width: 100%;
          margin: 0 4px;
          transform: translateY(-50%);
          border-top: 1px solid #041E42;
          content: ""; }
      .wholesale-factory__content2 .right .content .text {
        font-style: italic; }

.wholesale-factory__content3 {
  width: 96%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }
  @media (min-width: 576px) {
    .wholesale-factory__content3 {
      max-width: 95%; } }
  @media (min-width: 1025px) {
    .wholesale-factory__content3 {
      max-width: 900px; } }
  @media (min-width: 1280px) {
    .wholesale-factory__content3 {
      max-width: 1200px;
      padding-right: 3%;
      padding-left: 3%; } }
  @media (min-width: 1440px) {
    .wholesale-factory__content3 {
      max-width: 1280px;
      padding-right: 3%;
      padding-left: 3%; } }
  @media (min-width: 1600px) {
    .wholesale-factory__content3 {
      max-width: 1366px;
      padding-right: 3%;
      padding-left: 3%; } }
  @media (min-width: 1800px) {
    .wholesale-factory__content3 {
      max-width: 1640px;
      padding-right: 5%;
      padding-left: 5%; } }
  .wholesale-factory__content3 .left {
    margin: 64px 0;
    width: 550px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; }
    @media (max-width: 1280px) {
      .wholesale-factory__content3 .left {
        margin: 40px 0; } }
    @media (max-width: 640px) {
      .wholesale-factory__content3 .left {
        margin: 32px 0; } }
    .wholesale-factory__content3 .left .content {
      width: 550px;
      padding-left: 2%;
      padding-right: 2%; }
      .wholesale-factory__content3 .left .content .title {
        color: #041e42; }
      .wholesale-factory__content3 .left .content .text {
        color: #707070;
        margin: 40px 0; }
        @media (max-width: 1280px) {
          .wholesale-factory__content3 .left .content .text {
            margin: 32px 0; } }
        @media (max-width: 640px) {
          .wholesale-factory__content3 .left .content .text {
            margin: 12px 0; } }
      .wholesale-factory__content3 .left .content .menu {
        padding: 0; }
        .wholesale-factory__content3 .left .content .menu li {
          margin: 25px 0;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center; }
          .wholesale-factory__content3 .left .content .menu li img {
            flex: 0 0 10%;
            width: 10%;
            padding-right: 12px; }
          .wholesale-factory__content3 .left .content .menu li p {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            -webkit-box-orient: vertical; }
  .wholesale-factory__content3 .right {
    padding: 0;
    margin: 64px 0; }
    @media (max-width: 1280px) {
      .wholesale-factory__content3 .right {
        margin: 40px 0; } }
    @media (max-width: 640px) {
      .wholesale-factory__content3 .right {
        margin: 32px 0; } }
    .wholesale-factory__content3 .right img {
      height: 100%;
      object-position: center;
      object-fit: cover; }
    .wholesale-factory__content3 .right .text {
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden; }

.wholesale-factory__content4 {
  height: 480px;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  .wholesale-factory__content4 .content4__item {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 49%;
    background-color: #041e42; }
    .wholesale-factory__content4 .content4__item::after {
      content: "";
      position: absolute;
      top: 5%;
      left: 50%;
      transform: translateX(-50%);
      background-image: url("../assets/wholesale/icons/ws-s-2.png");
      background-size: contain;
      background-repeat: no-repeat;
      width: 56px;
      height: 56px; }
    .wholesale-factory__content4 .content4__item:nth-child(2n+1) .images {
      position: absolute;
      bottom: 4px;
      right: 10px; }
    .wholesale-factory__content4 .content4__item:nth-child(2n+1) .text {
      position: absolute;
      top: 20%;
      right: 40%;
      bottom: 5%; }
    .wholesale-factory__content4 .content4__item:nth-child(2n) .images {
      position: absolute;
      left: 10px;
      bottom: 4px;
      transform: scaleX(-1); }
    .wholesale-factory__content4 .content4__item:nth-child(2n) .text {
      position: absolute;
      top: 20%;
      left: 40%; }
    .wholesale-factory__content4 .content4__item .images {
      height: 85%;
      width: 35%; }
      .wholesale-factory__content4 .content4__item .images img {
        height: 100%;
        object-fit: contain;
        object-position: bottom; }
    .wholesale-factory__content4 .content4__item .text {
      color: #c0c0c0;
      width: 300px;
      height: 320px; }
      .wholesale-factory__content4 .content4__item .text .title {
        color: #041E42;
        font-family: "HELVETICA-NEUE", "Segoe UI", sans-serif;
        font-size: 50px;
        font-weight: 500;
        color: #c0c0c0; }
        @media (max-width: 1024px) {
          .wholesale-factory__content4 .content4__item .text .title {
            font-size: 40px; } }
        @media (max-width: 640px) {
          .wholesale-factory__content4 .content4__item .text .title {
            font-size: 32px; } }
      .wholesale-factory__content4 .content4__item .text .subtitle {
        font-size: 16px;
        margin: 10px 0 50px 0; }
      .wholesale-factory__content4 .content4__item .text .description {
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 4;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        font-style: italic; }

.wholesale-factory__content5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #041e42;
  margin: 64px 0; }
  @media (max-width: 1280px) {
    .wholesale-factory__content5 {
      margin: 40px 0; } }
  @media (max-width: 640px) {
    .wholesale-factory__content5 {
      margin: 32px 0; } }
  .wholesale-factory__content5 .title {
    margin-bottom: 32px; }
    @media (max-width: 1280px) {
      .wholesale-factory__content5 .title {
        margin-bottom: 24px; } }
    @media (max-width: 640px) {
      .wholesale-factory__content5 .title {
        margin-bottom: 16px; } }
  .wholesale-factory__content5 .subtitle {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 12px; }
  .wholesale-factory__content5 .text {
    display: flex;
    justify-content: space-between;
    flex-direction: row; }
    .wholesale-factory__content5 .text .phone {
      border-right: 1px #707070 solid;
      padding-right: 15px; }
    .wholesale-factory__content5 .text .email {
      padding-left: 15px; }

@media (max-width: 1280px) {
  .wholesale-factory__content1 {
    width: 40%; } }

@media (max-width: 1024px) {
  .wholesale-factory__content1 {
    left: 10%; } }

@media (max-width: 640px) {
  .wholesale-factory__content1 {
    width: 90%;
    padding: 12px;
    background-color: rgba(112, 112, 112, 0.4);
    max-height: 330px;
    overflow: hidden;
    left: 5%; }
  .wholesale-factory__background {
    max-height: 360px; } }

@media (max-width: 768px) {
  .wholesale-factory__menu {
    flex-direction: column; }
  .wholesale-factory__menu .menu-1 {
    padding-bottom: 24px; }
  .wholesale-factory__menu .menu-2 {
    border-left: 0;
    border-right: 0;
    border-top: 1px dashed #041e42;
    border-bottom: 1px dashed #041e42;
    padding: 24px 0; }
  .wholesale-factory__menu .menu-3 {
    padding-top: 24px; }
  .wholesale-factory__menu .menu-1 .title,
  .wholesale-factory__menu .menu-2 .title,
  .wholesale-factory__menu .menu-3 .title {
    margin: 20px 0; } }

@media (max-width: 1440px) {
  .wholesale-factory__content2 {
    max-height: 560px; } }

@media (max-width: 1280px) {
  .wholesale-factory__content2 .left {
    width: 40%; }
    .wholesale-factory__content2 .left::after {
      width: 64px;
      height: 64px; }
  .wholesale-factory__content2 .right {
    width: 60%; }
  .wholesale-factory__content2 .center {
    left: 40%; }
  .wholesale-factory__content2 .right .content {
    top: 30%;
    left: 30%; } }

@media (max-width: 1024px) {
  .wholesale-factory__content2 {
    position: relative;
    max-height: 460px; }
    .wholesale-factory__content2 .left {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0; }
      .wholesale-factory__content2 .left::after {
        width: 56px;
        height: 56px; }
    .wholesale-factory__content2 .right {
      position: absolute;
      width: 50%;
      top: 0;
      left: 50%; }
      .wholesale-factory__content2 .right .content {
        left: 0;
        top: 50%;
        right: 5%;
        bottom: 5%;
        transform: translateY(-50%); }
        .wholesale-factory__content2 .right .content .title {
          color: white; }
        .wholesale-factory__content2 .right .content .subtitle {
          color: rgba(255, 255, 255, 0.7); }
          .wholesale-factory__content2 .right .content .subtitle::after {
            border-top: 1px solid #fff; }
        .wholesale-factory__content2 .right .content .text {
          color: #C0C0C0;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          overflow: hidden; }
    .wholesale-factory__content2 .center {
      position: absolute;
      top: 0;
      left: 0;
      right: 50%;
      bottom: 0;
      width: 50%;
      transform: none;
      display: flex;
      justify-content: center;
      align-items: flex-end; }
      .wholesale-factory__content2 .center img {
        width: 80%;
        max-height: 80%;
        object-fit: contain;
        object-position: center; } }

@media (max-width: 640px) {
  .wholesale-factory__content2 {
    max-height: 520px; }
    .wholesale-factory__content2 .left::after {
      width: 56px;
      height: 56px;
      display: none; }
    .wholesale-factory__content2 .right {
      top: 50%;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: auto; }
      .wholesale-factory__content2 .right .content {
        top: 24px;
        left: 10%;
        bottom: 24px;
        right: 10%;
        transform: none;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center; }
        .wholesale-factory__content2 .right .content .subtitle {
          padding-bottom: 12px; }
          .wholesale-factory__content2 .right .content .subtitle::after {
            top: 100%;
            left: 0;
            border-top: 1px solid gray; }
    .wholesale-factory__content2 .center {
      top: 32px;
      left: 0;
      right: auto;
      bottom: 50%;
      height: auto;
      width: 100%;
      transform: none; }
      .wholesale-factory__content2 .center img {
        height: 100%;
        max-height: 100%;
        object-fit: contain; } }

@media (max-width: 1280px) {
  .wholesale-factory__content3 .left .content {
    width: auto; } }

@media (max-width: 640px) {
  .wholesale-factory__content3 .left .content .text {
    margin: 24px 0; } }

@media (max-width: 1440px) {
  .wholesale-factory__content4 .content4__item::after {
    display: none; }
  .wholesale-factory__content4 .content4__item:nth-child(2n+1) .text {
    right: 50%; }
  .wholesale-factory__content4 .content4__item:nth-child(2n) .text {
    left: 50%; }
  .wholesale-factory__content4 .content4__item .images {
    width: 45%; }
  .wholesale-factory__content4 .content4__item .text {
    left: 50% !important;
    top: calc(50% + 24px) !important;
    transform: translateX(-50%);
    width: 90%;
    height: calc(50% - 48px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center; }
  .wholesale-factory__content4 .content4__item .text .subtitle {
    margin: 12px 0; }
  .wholesale-factory__content4 .content4__item .images {
    top: 24px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    height: calc(50% - 24px); } }

@media (max-width: 425px) {
  .wholesale-factory__content4 {
    flex-direction: column;
    height: auto; }
  .wholesale-factory__content4 .content4__item {
    height: 380px;
    width: 100%; }
    .wholesale-factory__content4 .content4__item:not(:first-child) {
      margin-top: 12px; } }
