.wholesale--commitmet {
    background-color: #041e42;
    // padding: map-get($size, margin-block-large) 0;
    @include spaceL(padding, 0);
    &__title {
        text-align: center;
        // margin: 0 0 50px 0;
        @include spaceL(margin-bottom);
        .title1 {
            color: #c0c0c0;
        }

        .title2 {
            @include spaceS(margin-top);
            @include font-heading(5);
            font-family: inherit;
            font-weight: 400;
            color: #c0c0c0;
            // margin-top: 20px;
            text-transform: uppercase;
        }
    }
    &__content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        align-items: center;
        width: 100%;
        margin: auto;
        flex-wrap: wrap;
        .commitmet__link {
            color: #c0c0c0;
            width: 240px;
            flex: 0 0 33.333%;
            // margin: map-get($size, margin-block-medium) 35px;
            @include spaceM(margin-top);
            p {
                margin-top: 12px;
                text-align: center;
            }
            img {
                width: 112px;
                height: 112px;
                object-position: center;
                object-fit: cover;
                border-radius: 50%;
                transition: 0.3s;
            }
            &:hover {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
}

@media (max-width: map-get($media, tl)){
    .wholesale--commitmet__content .commitmet__link{
        margin: 24px 0;
        flex: 0 0 50%;
    }
}
@media (max-width: map-get($media, ml)){
    .wholesale--commitmet__content .commitmet__link{
        margin: 24px 0;
        flex: 0 0 100%;
    }
}