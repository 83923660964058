.details-infor__wrapper {
    .container {
        .details-infor {
            @include spaceL(margin-top);
            // padding: 60px 0;
            .details-infor__liveview {
                padding: 0 30px;
                .mySwiper2 {
                    margin: 10px 0;
                    .swiper-wrapper {
                        .swiper-slide {
                            * {
                                width: 100%;
                                height: 100%;
                            }
                            cursor: zoom-in;
                            &.swiper-slide-zoomed{
                                cursor: zoom-out;
                            }
                        }
                    }
                }
                .mySwiper {
                    width: 70%;
                    .swiper-wrapper {
                        .swiper-slide {
                            cursor: pointer;
                            * {
                                border-radius: 5px;
                            }
                        }
                    }
                }
                .swiper-btn__nav {
                    .swiper-button-prev {
                        left: 80px;
                        padding: 7px;
                    }
                    .swiper-button-next {
                        right: 80px;
                        padding: 7px;
                    }
                }
            }
            .detail-infor__content {
                .product-infor {
                    .product-infor__title {
                        margin: 0 0 30px 0;
                        font-size: 40px;
                    }
                    .product-infor__price {
                        font-family: HELVETICANEUELTSTD-ROMAN;
                        font-size: 40px;
                        color: map-get($mau, color-primary);
                        margin: 10px 0;
                    }
                }
                .product-oftions {
                    padding: 7px 0;
                    .dropdown-value {
                        display: flex;
                        align-items: center;
                        .size {
                            width: 60%;
                        }
                        .amount {
                            width: 40%;
                        }
                    }
                }
                .product-rate {
                    padding: 30px 0;
                    .rate-section {
                        padding: 7px 0;
                        align-items: center;
                        .rate-title__items {
                            * {
                                color: map-get($mau, dark-gray);
                                white-space: nowrap;
                            }
                            
                            a:hover {
                                color: map-get($mau, color-primary);
                            }
                        }
                        &:not(:last-child){
                            .rate-title__items {
                                * {
                                    border-right: 1px solid map-get($mau, dark-gray);
                                }
                            }
                        }
                        .rate-star__items {
                            .items-star {
                                display: flex;
                                align-items: center;
                                * {
                                    margin-right: 5px;
                                    position: relative;
                                    width: 2rem;
                                    height: 2rem;
                                    text-indent: -9999rem;
                                    display: inline-block;
                                    align-items: center;
                                    &::after {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        content: "";
                                        width: 2rem;
                                        height: 2rem;
                                        background-image: url("../assets/icons/white-star-blueborder.png");
                                        background-repeat: no-repeat;
                                        background-position: center;
                                        text-indent: 0;
                                        display: inline;
                                    }
                                }
                                // *:hover{
                                //     &::after{
                                //         background-image: url("../assets/icons/blue-star.png");
                                //     }
                                // }
                                .active {
                                    &::after {
                                        background-image: url("../assets/icons/blue-star.png");
                                    }
                                }
                            }
                        }
                    }
                }
                .product-feature-warranty {
                    .nav-feature {
                        display: flex;
                        align-items: center;
                        font-size: 17px;
                        color: map-get($mau, color-primary);
                        font-family: SEGOEUIB;
                        text-transform: uppercase;
                        position: relative;
                        overflow-x: scroll;
                        overflow-y: hidden;
                        padding-bottom: 12px;

                        .product-feature {
                            cursor: pointer;
                            &:not(:first-child) {
                                margin-left: 24px;
                            }
                        }
                        &::after {
                            position: absolute;
                            content: "";
                            bottom: -4px;
                            right: 0;
                            width: 100%;
                            height: 1px;
                            background: map-get($mau, color-primary);
                            opacity: 0.4;
                        }
                        // .product-warrenty{
                        //     margin-left: auto;

                        // }
                        .active {
                            position: relative;
                            &::after {
                                position: absolute;
                                content: "";
                                bottom: -5px;
                                left: 0;
                                width: 80%;
                                border: solid 2px map-get($mau, underline-red);
                                background: map-get($mau, underline-red);
                                border-radius: 3px;
                                z-index: 1;
                            }
                        }
                    }
                    .product-selected {
                        .product-selected--value {
                            display: none;
                            &.active {
                                display: block;
                            }
                        }
                    }

                    .feature-details {
                        margin-top: 30px;
                        .dropdown-feature {
                            border-bottom: solid 1px map-get($mau, gray-1);
                            .dropdown-item:hover {
                                color: map-get($mau, color-primary);
                            }
                            &:last-child {
                                border-bottom: none;
                            }
                            .dropdown-item {
                                border: none;
                                border-radius: 0;
                                width: 100%;
                                padding: 10px 0 25px 0;
                                span {
                                    img {
                                        transition: 0.4s ease-in-out;
                                    }
                                }
                                .active-caret {
                                    img {
                                        transform: rotate(180deg);
                                    }
                                }
                            }
                            .feature-content {
                                transition: max-height 0.4s ease-in-out;
                                max-height: 0;
                                overflow: hidden;
                                color: map-get($mau, dark-gray);
                                // transition: height 0.6s ease-in-out;
                            }
                            .active {
                                &::-webkit-scrollbar {
                                    width: 0;
                                }
                                height: auto;
                                max-height: 300px;
                                overflow-y: scroll;
                                margin-bottom: 20px;
                            }
                        }
                    }
                    .feature-icons {
                        border-top: solid 1px map-get($mau, gray-2);
                        padding: 20px 0;
                        .swiper-slide {
                            cursor: pointer;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            .icon-avatar {
                                width: 60px;
                                height: 60px;
                                border-radius: 50%;
                                background: map-get($mau, gray-2);
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
                .product-order {
                    margin-top: 20px;
                    a {
                        border: solid 3px map-get($mau, color-primary);
                        background-color: transparent;

                        border-radius: 5px;
                        text-align: center;
                        padding: 16px 0;
                        display: block;
                        
                        color: map-get($mau, color-primary);
                        font-weight: 700;
                        
                        &:hover{
                            background-color: map-get($mau, color-primary);
                            color: white;
                        }
                    }
                }
                .product-ask{
                    display: flex;
                    flex-direction: column;
                    @include spaceM(margin-top);

                    &-item{
                        &--top{
                            display: flex;
                            background-color: map-get($mau, color-primary);
                            border-top-left-radius: 8px;
                            border-top-right-radius: 8px;
                        }
                        &--bottom{
                            border-bottom-left-radius: 8px;
                            border-bottom-right-radius: 8px;
                            padding: 12px 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: map-get($mau, gray-2);
                        }
                        &__link{
                            color: map-get($mau, color-primary);
                            font-weight: 700;
                        }
                        &--left{
                            width: 32%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        &--right{
                            width: 68%;
                        }

                        &__img{
                            width: 80px;
                            height: 80px;
                            
                            border-radius: 50%;

                            background-color: white;
                            display: flex;
                            justify-content: center;
                            align-items: flex-end;
                            img{
                                height: 64px;
                            }
                            
                        }
                        &__title{
                            padding: 24px 0;
                            @include font-p;
                            color: white;
                            font-weight: 700;
                        }
                        &__desc{
                            width: 100%;
                            @include font-p;
                            padding-right: 24px;
                            font-weight: 300;
                            color: white;
                            padding-bottom: 24px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1640px) and (min-width: 601px) {
    .details-infor__wrapper {
        .container {
            .details-infor {
                .details-infor__liveview {
                    .swiper-btn__nav {
                        .swiper-button-prev {
                            left: 60px;
                        }
                        .swiper-button-next {
                            right: 60px;
                        }
                    }
                }
                .detail-infor__content {
                    .product-infor {
                        .product-infor__title {
                            margin: 0 0 20px 0;
                            font-size: 30px;
                        }
                        .product-infor__price {
                            font-size: 30px;
                            margin: 7px 0;
                        }
                    }
                    .product-oftions {
                        .dropdown-value {
                            .size,
                            .amount {
                                font-size: 13px;
                            }
                        }
                    }
                    .product-rate {
                        padding: 25px 0;
                        .rate-section {
                            padding: 7px 0;
                            .rate-title__items {
                                color: map-get($mau, dark-gray);
                                font-size: 13px;
                                white-space: nowrap;
                            }
                            .rate-star__items {
                                .items-star {
                                    * {
                                        width: 1.6rem;
                                        height: 1.6rem;
                                        &::after {
                                            width: 1.6rem;
                                            height: 1.6rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .product-feature-warranty {
                        .nav-feature {
                            font-size: 13px;
                            .active {
                                position: relative;
                                &::after {
                                    border: solid 1.7px map-get($mau, underline-red);
                                }
                            }
                        }
                        .feature-details {
                            .dropdown-feature {
                                border-bottom: solid 1px map-get($mau, gray-1);
                                &:last-child {
                                    border-bottom: none;
                                }
                                .dropdown-item {
                                    font-size: 13px;
                                    padding: 10px 0 20px 0;
                                }
                                .feature-content {
                                    font-size: 13px;
                                }
                            }
                        }
                        .feature-icons {
                            padding: 14px 0;
                            .swiper-slide {
                                .icon-avatar {
                                    width: 45px;
                                    height: 45px;
                                }
                                .title-icon {
                                    font-size: 13px;
                                }
                            }
                        }
                    }
                    .product-order {
                        padding: 15px 0;
                        a {
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: map-get($media, tl)) {
    .details-infor__wrapper {
        .container {
            .details-infor {
                flex-direction: column;
                .details-infor__liveview {
                    flex: 0 0 100%;
                    width: 100%;
                    max-width: 100%;
                }
                .detail-infor__content{
                    flex: 0 0 100%;
                    width: 100%;
                    max-width: 100%;

                    @include spaceL(margin-top);
                    // margin-top: 24px;
                }
            }
        }
    }
}


// @media (max-width: map-get($media, ml)) {
//     .details-infor__wrapper {
//         .container {
//             .details-infor {
//                 padding: 8px 0 36px 0;
//             }
//         }
//     }
// }