@font-face {
    font-family: HELVETICANEUELTSTD-BDOU ;
    src: url('../fonts/HELVETICANEUELTSTD-BDOU.otf');
}
@font-face {
    font-family: HELVETICANEUELTSTD-ROMAN ;
    src: url('../fonts/HELVETICANEUELTSTD-ROMAN.otf');
}
@font-face {
    font-family: SEGOEUI ;
    src: url('../fonts/SEGOEUI.otf');
}
@font-face {
    font-family: SEGOEUIB ;
    src: url('../fonts/SEGOEUIB.otf');
}
@font-face {
    font-family: SEGOEUII ;
    src: url('../fonts/SEGOEUII.otf');
}
@font-face {
    font-family:SEGOEUIL ;
    src: url('../fonts/SEGOEUIL.otf');
}
@font-face {
    font-family: SEGOEUISL ;
    src: url('../fonts/SEGOEUISL.otf');
}
@font-face {
    font-family: SEGOEUIZ ;
    src: url('../fonts/SEGOEUIZ.otf');
}
@font-face {
    font-family: SEGUIBL ;
    src: url('../fonts/SEGUIBL.otf');
}
@font-face {
    font-family:SEGUIBLI;
    src: url('../fonts/SEGUIBLI.otf');
}
@font-face {
    font-family:SEGUIEMJ;
    src: url('../fonts/SEGUIEMJ.otf');
}
@font-face {
    font-family:SEGUIHIS;
    src: url('../fonts/SEGUIHIS.otf');
}
@font-face {
    font-family:SEGUILI;
    src: url('../fonts/SEGUILI.otf');
}
@font-face {
    font-family:SEGUISB;
    src: url('../fonts/SEGUISB.otf');
}
@font-face {
    font-family:SEGUISBI;
    src: url('../fonts/SEGUISBI.otf');
}
@font-face {
    font-family:SEGUISLI;
    src: url('../fonts/SEGUISLI.otf');

}
@font-face {
    font-family:SEGUISYM;
    src: url('../fonts/SEGUISYM.otf');
}
@font-face {
    font-family:HELVETICA-NEUE;
    src: url('../fonts/HELVETICANEUELTSTD-ROMAN.otf');
}