$time-duration: .1s;
@keyframes show-hamberger {
    0%{
        display: none;
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    }
    100%{
        display: block;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
}

@keyframes show-popup-block {
    0%{
        display: none;
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    }                    
    100%{
        display: block;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }                    
}

@keyframes showFlexRL {
    0%{
        display: none;
        clip-path: inset(0 0 0 100%);
    }                    
    100%{
        display: flex;
        clip-path: inset(0 0 0 0);
    }                    
}

@keyframes widthRight{
    0%{
        width: 0%
    }
    100%{
        width: 80%;
    }
  }
  