.breadcrumb-wrapper{
    display: flex;
    align-items: center;
    padding-top: 36px;
    margin: 0 -3%;
    .breadcrumb-items{
        font-size: 16px;
        color: map-get($mau, dark-gray );
        text-transform: uppercase;
        padding-right: 20px;
        position: relative;

        white-space: nowrap;
        line-height: 20px;
        &::after{
            content: ">";
            position: absolute;
            top: 50%;
            right: 0px;
            transform: translate(-50%, -50%);
            line-height: 20px;
            font-size: inherit;
        }
    }
    .active{
        font-weight: 700;
        &::after{
            content: "";
        }
    }

    max-width: 100%;
    overflow-x: scroll;

    &::-webkit-scrollbar{
        width: 0px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 0px map-get($mau, gray-1); 
        border-radius: 10px;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: map-get($mau, white); 
        border-radius: 10px;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: rgba(map-get($mau, dark-gray), .7); 	
    }
}

@media (max-width: map-get($media, tl)) {
    .breadcrumb-wrapper{
        margin: 0;
    }
}
