// Area Global
.about-story-mg__wrapper {
  background-color: map-get($mau, gray-2);

  // .about-story-milestone{
  //   display: flex;
  //   align-items: center;
  //   flex-direction: column;
  // }

  .about-story-global {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto;

    &__title {
      width: 56%;
    }
    &__paragraph {
      @include p-line-clamp(3);
      width: 56%;
    }

    &__img {
      // width: 60%;
      // margin: map-get($size, margin-block-large) 0;
      @include spaceXL(margin, 0);

      &--bg {
        min-width: 1200px;
        width: 1200px;
        max-width: 1200px;
      }
      position: relative;
      &-list {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      &--icon {
        position: absolute;
        width: 160px;
      }
      &--icon:nth-of-type(1) {
        top: 7%;
        left: 8%;
      }
      &--icon:nth-of-type(2) {
        top: 45%;
        left: 30%;
      }
      &--icon:nth-of-type(3) {
        top: 18%;
        left: 47%;
      }
      &--icon:nth-of-type(4) {
        top: -4%;
        left: 75%;
      }
      &--icon:nth-of-type(5) {
        top: 59%;
        left: 88%;
      }
    }
  }

  .about-story-mg__title {
    // padding: map-get($size, margin-block-large) 0;
    @include spaceXL(padding-top);
    @include spaceL(padding-bottom);
    text-align: center;
  }
}

@media (max-width: map-get($media, pm)) {
  .about-story-mg__wrapper .about-story-mg__title,
  .about-story-mg__wrapper .about-story-global__paragraph {
    width: 72%;
  }
  .about-story-mg__wrapper .about-story-global__paragraph {
    -webkit-line-clamp: 4;
  }

  .about-story-mg__wrapper .about-story-global__img {
    &--bg {
      min-width: 900px;
      width: 900px;
      max-width: 900px;
    }
    &--icon {
      width: 156px;
    }
    &--icon:nth-of-type(1) {
      top: 0%;
      left: 7%;
    }
    &--icon:nth-of-type(2) {
      top: 38%;
      left: 29.5%;
    }
    &--icon:nth-of-type(3) {
      top: 15%;
      left: 47%;
    }
    &--icon:nth-of-type(4) {
      top: -10%;
      left: 75%;
    }
    &--icon:nth-of-type(5) {
      top: 52%;
      left: 87%;
    }
  }
}

@media (max-width: map-get($media, tl)) {
  .about-story-mg__wrapper .about-story-global__img {
    &--bg {
      min-width: 720px;
      width: 720px;
      max-width: 720px;
    }
    &--icon {
      width: 120px;
    }
    &--icon:nth-of-type(1) {
      top: 1.5%;
      left: 7%;
    }
    &--icon:nth-of-type(2) {
      top: 38%;
      left: 29.5%;
    }
    &--icon:nth-of-type(3) {
      top: 15%;
      left: 47%;
    }
    &--icon:nth-of-type(4) {
      top: -10%;
      left: 75%;
    }
    &--icon:nth-of-type(5) {
      top: 52%;
      left: 87%;
    }
  }
}
@media (max-width: map-get($media, ts)) {
  .about-story-mg__wrapper .about-story-global__img {
    &--bg {
      min-width: 540px;
      width: 540px;
      max-width: 540px;
    }
    &--icon {
      width: 96px;
    }
    &--icon:nth-of-type(1) {
      top: 0%;
      left: 7%;
    }
    &--icon:nth-of-type(2) {
      top: 38%;
      left: 29.5%;
    }
    &--icon:nth-of-type(3) {
      top: 15%;
      left: 47%;
    }
    &--icon:nth-of-type(4) {
      top: -10%;
      left: 75%;
    }
    &--icon:nth-of-type(5) {
      top: 52%;
      left: 87%;
    }
  }
}
@media (max-width: map-get($media, ml)) {
  .about-story-mg__wrapper .about-story-global__img {
    &--bg {
      min-width: 360px;
      width: 360px;
      max-width: 360px;
    }
    &--icon{
      width: 64px;
    }
    &--icon:nth-of-type(1) {
      top: 0%;
      left: 7%;
    }
    &--icon:nth-of-type(2) {
      top: 38%;
      left: 29.5%;
    }
    &--icon:nth-of-type(3) {
      top: 15%;
      left: 47%;
    }
    &--icon:nth-of-type(4) {
      top: -10%;
      left: 75%;
    }
    &--icon:nth-of-type(5) {
      top: 52%;
      left: 87%;
    }
  }
}
@media (max-width: map-get($media, mm)) {
  .about-story-mg__wrapper .about-story-global__img {
    &--bg {
      min-width: 300px;
      width: 300px;
      max-width: 300px;
    }
    &--icon{
      width: 52px;
    }
    &--icon:nth-of-type(1) {
      top: 7%;
      left: 8%;
    }
    &--icon:nth-of-type(2) {
      top: 38%;
      left: 29.5%;
    }
    &--icon:nth-of-type(3) {
      top: 15%;
      left: 47%;
    }
    &--icon:nth-of-type(4) {
      top: -10%;
      left: 75%;
    }
    &--icon:nth-of-type(5) {
      top: 52%;
      left: 87%;
    }
  }
}

@media (max-width: map-get($media, ps)) {
  .about-story-mg__wrapper .about-story-mg__title,
  .about-story-mg__wrapper .about-story-global__paragraph {
    width: 88%;
    -webkit-line-clamp: 5;
  }
}

// media tablet large 1024px
// @media (max-width: map-get($media, tl)){
//   .about-story-mg__wrapper {
//     .about-story-global{
//       width: 80%;

//       // &__img{
//       //   margin: map-get($size, margin-block-medium);
//       // }
//     }

//     // // Title as-5
//     // .about-story-mg__title{
//     //   // padding-top: map-get($size, margin-block-medium);
//     //   // padding-bottom: map-get($size, margin-block-small);
//     // }
//   }
// }

// media mobile medium 425px
// @media (max-width: map-get($media, mm)){
//   .about-story-mg__wrapper {
//     .about-story-global{

//       &__img{
//         // margin: map-get($size, margin-block-small);
//       }
//     }

//     // // Title as-5
//     // .about-story-mg__title{
//     //   padding-top: map-get($size, margin-block-small);
//     //   padding-bottom: map-get($size, margin-block-small);
//     // }
//   }
// }
//////////////////////////////////////////////////////////////

// Area Mislenstone
.process-year {
  display: flex;
  flex-direction: row;
  height: 590px;
  align-items: center;
  &-item {
    flex: 1;
    position: relative;
    &.active {
      .process-year-item__number {
        // &:hover &--year,
        // &:active &--year{
        //   color:white;
        // }

        // &:hover::before,
        // &:active::before{
        //   background-color: map-get($mau, color-primary);
        // }
        &::before {
          background-color: map-get($mau, color-primary);
        }
        &--year {
          color: white;
        }
      }
    }

    &:not(:first-child) {
      .process-year-item--dash {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 0;
        transform: translate(-100%, -50%);
        border-top: 2px dashed map-get($mau, color-primary);
        z-index: 1;
      }
    }

    &--center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      position: relative;
    }

    &__content {
      position: absolute;
      top: -320%;
      left: 50%;
      width: 150%;
      transform: translate(-50%, -100%);

      color: map-get($mau, color-primary);
      text-align: center;
      &--title {
        width: 100%;
        margin-bottom: 24px;

        @include font-heading(5);
        font-family: inherit;
        -webkit-line-clamp: 1;
        font-weight: 700;
        text-transform: uppercase;
      }

      &--desc {
        width: 100%;
        font-family: inherit;
        -webkit-line-clamp: 2;
      }
    }

    &:nth-of-type(2n) {
      .process-year-item {
        &__content {
          position: absolute;
          top: 420%;
          left: 50%;
          transform: translate(-50%, 0%);
          width: 150%;
        }
        &__icon {
          top: -60px;
        }
        &__number {
          &--solid {
            top: initial;
            bottom: 0;
            left: 50%;
            transform: rotate(90deg);
          }
        }
      }
    }

    &__number {
      position: relative;
      z-index: 2;
      padding: 8px 12px;

      $border-process: 2px solid map-get($mau, color-primary);
      $border-process--skew: 20deg;

      cursor: pointer;
      transition: color 0.3s linear;

      &--year {
        // font-size: 21px;
        @include font-heading(5);
        font-family: inherit;
        font-weight: bold;
        text-align: center;
        color: map-get($mau, color-primary);
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        transform: skew($border-process--skew);
        border-top: $border-process;
        border-bottom: $border-process;
        border-left: $border-process;
        border-right: $border-process;
        background-color: map-get($mau, gray-2);
        transition: background 0.3s linear;
      }

      &--solid {
        position: absolute;
        background-color: map-get($mau, color-primary);
        transform-origin: left;
        width: 150%;
        height: 1px;

        top: 0;
        left: 50%;
        transform: rotate(-90deg);

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 100%;
          width: 8px;
          height: 8px;
          transform: translate(0%, -50%);
          border-radius: 50%;
          background-color: #041e42;
        }
      }
    }

    &__icon {
      position: absolute;
      top: 60px;
      left: 50%;
      transform: translateX(-50%);
      width: 35px;
      height: 35px;
      object-fit: cover;
      border-radius: 50%;
      border: 1px solid map-get($mau, color-primary);
    }
  }
}

@media (min-width: 1800px) {
  .process-year-item__content{
    width: 135%;
  }

  .process-year-item:nth-of-type(2n) .process-year-item__content {
    width: 135%;
  }
}

@media (max-width: map-get($media, tl)) {
  .process-year-item__number {
    padding: 5px 8px;
  }
  .process-year {
    margin-top: map-get($size, margin-block-medium);
    flex-direction: column;
    min-height: 100vh;

    &-item {
      width: 100%;

      &__content {
        position: absolute;
        top: 50%;
        left: calc(50% - 100px);
        width: 30%;
        transform: translate(-100%, -50%);

        &--title {
          -webkit-line-clamp: 5;
          text-align: right;
          font-size: 16px;
        }

        &--desc {
          display: none;
        }
      }

      &__icon {
        top: 50%;
        left: calc(50% + 100px);
        transform: translateY(-50%);
      }

      &__number {
        &--solid {
          position: absolute;
          top: 50%;
          left: 0;
          width: 50px;
          height: 2px;
          transform: rotate(180deg);
          transform-origin: left;
          background-color: #041e42;
        }
      }

      &:not(:first-child) {
        .process-year-item--dash {
          width: 0;
          height: 100%;
          transform: translate(-50%, -150%);
          border-right: 3px dashed map-get($mau, color-primary);
          border-top: 0;
        }
      }
      &:nth-of-type(2n) {
        .process-year-item {
          &__content {
            top: 50%;
            left: calc(50% + 100px);
            width: 30%;
            transform: translate(0%, -50%);

            &--title {
              -webkit-line-clamp: 5;
              text-align: left;
            }
          }
          &__icon {
            top: 50%;
            left: calc(50% - 130px);
            transform: translateY(-50%);
          }
          &__number {
            &--solid {
              top: 50%;
              bottom: initial;
              left: 100%;
              right: initial;
              transform: rotate(0deg) translate(0%, -50%);
            }
          }
        }
      }
    }
  }

  .process-year-item__content--title {
    margin-bottom: 0;
  }
}

@media (max-width: map-get($media, mm)) {
  .process-year-item__number--solid {
    width: 20px;
  }
  .process-year-item__content {
    left: calc(50% - 70px);
  }
  .process-year-item:nth-of-type(2n) .process-year-item__content {
    left: calc(50% + 70px);
  }
  .process-year-item__icon {
    left: calc(50% + 70px);
  }
  .process-year-item:nth-of-type(2n) .process-year-item__icon {
    left: calc(50% - 105px);
  }
}
