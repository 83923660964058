.mattress-recommend-video__wrapper {
    @include spaceXL(padding, 0);

    background: map-get($mau, color-primary);
    // min-height: 636px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .mattress-recommend-video {
        display: flex;
        // justify-content: center;
        align-items: center;
        .left-recommend-video {
            width: 50%;
            .product-infor {
                // padding: 0 170px 0 0;
                @include spaceL(padding-right);
                & > * {
                    color: map-get($mau, gray-1);
                }
                span {
                    text-transform: uppercase;
                    font-weight: 400;
                    // font-size: 20px;
                }
                .product-infor__title {
                    // margin: 10px 0;
                    @include spaceM(margin-top);
                }
            }
        }
    }
    .right-recommend-video {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        width: 50%;
        height: auto;
        .video-section{
            img{
                object-fit: contain;
                object-position: center;
                width: 100%;
                padding: 0 24px;
            }
        }
    }
}

@media (max-width: map-get($media, tl)) {
    // .mattress-recommend-video__wrapper{
    //     min-height: 520px;
    // }
    .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video {
        width: 100%;
    }
    .mattress-recommend-video__wrapper {
        flex-direction: column;
        // padding: 36px 0;
    }
    .mattress-recommend-video__wrapper .right-recommend-video {
        transform: none;
        position: static;
        top: initial;
        right: initial;
        width: 96%;
    }
    .mattress-recommend-video__wrapper .mattress-recommend-video .left-recommend-video .product-infor {
        padding: 0;
    }
}
