.mattress-type {
    // margin-top: 80px;
    // margin-bottom: 100px;
    @include spaceL(margin, 0);
}
.title-mattress-type {
    // margin-bottom: 95px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
        h3 {
            text-align: center;
        }
    }
    .menu {
        // margin-top: 5%;
        @include spaceL(margin-top);
        display: flex;
        text-align: center;
        justify-content: space-evenly;
        align-self: center;
        width: 90%;
        flex-wrap: wrap;

        &__item {
            width: 160px;
            height: 100px;
            margin-top: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        p {
            color: #041e42;
            margin-top: 12px;
            white-space: nowrap;
            font-weight: 700;
            text-transform: uppercase;
        }
        img {
            width: 54px;
            height: 54px;
        }
    }
}

@media (max-width: map-get($media, ps)){
    .title-mattress-type .menu {
        width: 100%;
        justify-content: space-evenly;
    }
}
@media (max-width: map-get($media, tl)){
    .title-mattress-type .menu {
        width: 90%;
        justify-content: space-between;
    }
}
@media (max-width: map-get($media, tm)){
    .title-mattress-type .menu {
        width: 100%;
        justify-content: space-between;
    }
}
@media (max-width: map-get($media, ts)){
    .title-mattress-type .menu {
        &__item{
            width: 50%;
        }
    }
}

.featured-content-master .block-featured {
    // padding-top: 103px;
    @include spaceXL(margin-top);

    justify-content: center;
    display: flex;
    flex-direction: row;
    text-align: right;

    &:nth-child(even) {
        flex-direction: row-reverse;
        text-align: left;
        justify-content: flex-start;
        align-items: flex-start;
        .featured-left {
            margin-left: 5%;
            margin-right: 10%;
        }
    }
    .featured-left {
        max-width: 35%;
        margin-left: 10%;
        margin-right: 5%;
        .block-main-title {
            .title {
                p {
                    @include font-heading(3);
                    color: #041e42;
                    font-weight: 500;
                }
            }
            .content {
                margin: 30px 0;
                p {
                    @include font-p;
                    // text-align: justify;
                    // color: #707070;
                    // overflow: hidden;
                    // text-overflow: ellipsis;
                    // -webkit-line-clamp: 5;
                    // display: -webkit-box;
                    // -webkit-box-orient: vertical;
                    @include p-line-clamp(5);
                    white-space: pre-line;
                }
            }
        }
        .featured-right {
            .block-pic {
                .pic {
                    width: 660px;
                    height: 400px;
                    margin: 0 auto;
                    transition: 0.5s all ease-in-out;
                    box-shadow: 0px 3px 6px rgba($color: #000000, $alpha: 0.16);
                }
            }
        }
    }
}
// @media (max-width: map-get($media, ps)){
//     .block-featured{
//         padding-top: 86px;
//     }
// }
// @media (max-width: map-get($media, ts)){
//     .block-featured{
//         padding-top: 78px;
//     }
// }

@media (max-width: map-get($media, tl)){
    .featured-content-master .block-featured .featured-left{
        max-width: 40%;
        margin-left: 5%;
    }
    .featured-content-master .block-featured:nth-child(even) .featured-left{
        margin-right: 5%;
    }
}

@media (max-width: map-get($media, ts)){
    .featured-content-master .block-featured{
        flex-direction: column-reverse;
    }
    .featured-content-master .block-featured:nth-child(even){
        flex-direction: column-reverse;
    }

    .featured-content-master .block-featured .featured-left{
        max-width: 100%;
        text-align: start;
        margin-left: 0;
        margin-right: 0;
        padding: 0px 10% 0 10%;
    }
    .featured-content-master .featured-right .block-pic{
        padding: 16px 10% 0 10%;
    }
    .featured-content-master .mattress-type{
        margin-top: 56px;
        margin-bottom: 56px;

    }
}

@media (max-width: map-get($media, mm)){
    .featured-content-master .block-featured .featured-left{
        padding: 0px;
    }
    
    .featured-content-master .featured-right .block-pic{
        padding: 12px 0 0 0;
    }
    .featured-content-master .mattress-type{
        margin-top: 56px;
        margin-bottom: 56px;
    }
}