.about-story-partner__wrapper{
  // margin: map-get($size, margin-block-large);
  @include spaceL(margin, 0);
  .swiper-container{
    padding: 0 80px;
    @media (max-width: 1439px){
      padding: 0 60px;
    }
    @media (max-width: 1023px){
        padding: 0 45px;
    }
  }
  .about-story-partner{
    &__title{
      // padding-bottom: map-get($size, margin-block-large);
      @include spaceL(padding-bottom);
      text-align: center;
    }
    position: relative;
    #about-story-partner{
      &--next,
      &--prev{
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        &::after{
          content:"";
        }
      }

      &--next{
        background-image: url('../assets/images/about/next.png');
      }
      &--prev{
        background-image: url('../assets/images/about/prev.png');
      }
    }
  }
}


@media (max-width: map-get($media, tl)){
  .about-story-partner__wrapper{
    margin: 36px 0;
  }

  .about-story-partner__wrapper .about-story-partner__title{
    padding-bottom: 36px;
  }
  .about-story-partner__wrapper .about-story-partner{
    margin-bottom: 12px;
  }

  .about-story-mg__wrapper .about-story-mg__title{
    padding: 36px 0;
  }
}