.dealer-navigation__wrapper {
    @include spaceL(padding-bottom);
    .container {
        padding-right: 0;
        padding-left: 0;
        .dealer-navigation {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .more {
                margin: 0;
                padding: 16px 10px;
                p {
                    font-size: 27px;
                    margin-right: 20px;
                    text-transform: initial;
                }
            }
            .navigate-text__items {
                    font-size: 27px;
                    color: map-get($mau, color-primary);
                &:last-child {
                        margin-right: 0;
                }
            }
            .navigate-text__items:not(:last-child) {
                position: relative;
                &::after {
                    position: absolute;
                    top: 0;
                    right: -50%;
                    width: 1px;
                    height: 100%;
                    content: "";
                    background: map-get($mau, color-primary);
                }
            }
        }
    }
}

@media (max-width: map-get($media, pm)){
    .dealer-navigation__wrapper .container .dealer-navigation .navigate-text__items:not(:last-child)::after{
        right: -20%;
    }
}
@media (max-width: map-get($media, ps)){
    .dealer-navigation__wrapper .container .dealer-navigation .navigate-text__items:not(:last-child)::after{
        right: -20%;
    }
    .dealer-navigation__wrapper .container .dealer-navigation .more p{
        font-size: 20px;
    }
    .dealer-navigation__wrapper .container .dealer-navigation .navigate-text__items{
        font-size: 20px;
    }
}
@media (max-width: map-get($media, tl)){
    .dealer-navigation__wrapper .container .dealer-navigation .navigate-text__items:not(:last-child)::after{
        display: none;
    }
    .dealer-navigation__wrapper .container .dealer-navigation{
        flex-direction: column;
    }

    .dealer-navigation__wrapper .container .dealer-navigation .navigate-text__items{
        padding: 12px;
        
        margin-top: 12px;
    }
    .dealer-navigation__wrapper .container .dealer-navigation .more{
        padding: 8px 12px;
    }
    .dealer-navigation__wrapper{
        padding: 0 0 36px 0;
    }
}

@media (max-width: map-get($media, ml)){
    .dealer-navigation__wrapper .container .dealer-navigation .more p{
        font-size: 18px;
    }
    .dealer-navigation__wrapper .container .dealer-navigation .navigate-text__items{
        font-size: 18px;
    }
    .dealer-navigation__wrapper .container .dealer-navigation .more p{
        margin-right: 8px;
    }
}
@media (max-width: map-get($media, ms)){
    .dealer-navigation__wrapper .container .dealer-navigation .more p{
        font-size: 16px;
    }
    .dealer-navigation__wrapper .container .dealer-navigation .navigate-text__items{
        font-size: 16px;
    }
    .dealer-navigation__wrapper .container .dealer-navigation .more p{
        margin-right: 8px;
    }
}