
.web4g {
	position: fixed;
	width: 100%;
	z-index: 10;
}

// .swiper-button-next,
// .swiper-button-prev {
// 	top: 4%;
// }
.animated,
.fadein {
	animation-duration: 2s;
}
body{
	position: relative;
}

@import 'i-1.scss';
@import 'i-2.scss';
@import 'i-3.scss';
@import 'i-4.scss';
@import 'i-5.scss';
@import 'i-6.scss';


