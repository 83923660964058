.about-story-business__wrapper{
  background-color: map-get($mau, gray-2);

  .our-story-business{
    // padding: map-get($size, margin-block-large) 0;
    @include spaceL(padding, 0);

    // &__title{
    //   // margin-bottom: map-get($size, margin-block-large);
    //   // @include spaceL(margin-bottom);
    
    // }

    &__list{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
    }

    &__item{
      width: 18%;

      display: flex;
      flex-direction: column;
      @include spaceL(margin-top);

      &-img{
        width: 65px;
        height: 65px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
        // margin-bottom:map-get($size, margin-block-medium);
        @include spaceM(margin-bottom);
      }

      &-desc{
        margin-top: 8px;
        color:map-get($mau, dark-gray);
      }
    }
  }
}

@media (max-width: map-get($media, tl)){
  .about-story-business__wrapper{
    .our-story-business{
      &__item{
        flex-basis: 45%;
        // margin-top: map-get($size, margin-block-medium);
        &-img{
          margin-bottom: map-get($size, margin-block-small);
        }

        &-desc{
          width: 80%;
        }
      }
    }
  }
  .about-story-business__wrapper .our-story-business{
    padding: 36px 0;
  }

  // .about-story-business__wrapper .our-story-business__title{
  //   margin-bottom: 36px;
  // }
}
@media (max-width: map-get($media, mm)){
  .about-story-business__wrapper{
    .our-story-business{
      &__title{
        &--heading{
          text-align: center;
        }
      }
      &__item{
        flex-basis: 100%;
        
        justify-content: center;
        align-items: center;
      
        &-desc{
          text-align: center;
        }
      }


    }
  }
}