.details-comprarison__wrapper{
    // margin-bottom: 100px;
    @include spaceL(margin-top);
    @include spaceXL(margin-bottom);
    .details-comprarison{
        .comparision-title{
            // padding: 80px 0;
            @include spaceL(margin-bottom);
            text-align: center;
            @include font-heading(3);
        }
        .comparision-section{
            width: 100%;
            overflow-x: scroll;
            overflow-y: hidden;
            flex-wrap: nowrap;
            
            .col-infor{
                min-width: 220px;
                width: 220px;
                flex: auto;
            }

            .product-name{
                // display: flex;
                // align-items: center;
                // justify-content: center;
                text-align: center;
                padding: 70px 0;
            }
            .infor-name{
                padding: 30px 0;
            }

            .col-product{
                min-width: 320px;
                width: 320px;
                flex: auto;
                .product-name{
                    color: map-get($mau, gray-2);
                    text-transform: uppercase;
                    font-size: 20px;
                    border-radius: 15px 15px 0 0;
                    background: map-get($mau, color-primary );
                    border-bottom: solid 2px map-get($mau, gray-1 );

                }
                .active-sale{
                    position: relative;
                    text-decoration: line-through;
                    // &:nth-child(1){
                    //     text-decoration: line-through;
                    // }
                    
                    .sale{
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 40px;
                    }
                    .discount{
                        font-weight: 600;
                        text-align: center;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        padding: 7px 20px;
                        background: map-get($mau, gray-1 );
                        color: map-get($mau, color-primary );
                        border-radius: 20px 0 0 0 ;
                        .cheap{
                            font-size: 15px;
                            font-weight: 400;
                        }
                    }
                    .trial{
                        position: absolute;
                        bottom: 15%;
                        left: 50%;
                        transform: translateX(-50%);
                        font-size: 15px;
                    }
                }
                
                .infor-name{
                    text-align: center;
                    
                    &:last-child{
                        border-radius: 0 0 15px 15px ;
                    }
                    &:nth-child(odd){
                        background: map-get($mau, gray-2);
                        color: map-get($mau, color-primary);
                    }
                    &:nth-child(even){
                        background: map-get($mau, color-primary );
                        color: map-get($mau, gray-2);
                    }
                }
            }
        }
    }
}

@media(max-width: map-get($media, tl)){
    .details-comprarison__wrapper .details-comprarison .comparision-section .col-infor{
        text-align: right;
    }
}