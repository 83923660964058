.about-media-press__wrapper{
  transition: .3s;
  .about-media-press{
    // margin-top: map-get($size, margin-block-large);
    @include spaceL(margin-top);

    height: 427px;
    background-image: url('../assets/images/about/press.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// @media (max-width: map-get($media, tl)){
//   .about-media-press__wrapper{
//     .about-media-press{
//       margin-top: map-get($size, margin-block-medium);
//     }
//   }
// }

@media (max-width: map-get($media, tm)){
  .about-media-press__wrapper{
    .about-media-press{
      height: 360px;
    }
  }
}

@media (max-width: map-get($media, mm)){
  .about-media-press__wrapper{
    .about-media-press{
      // margin-top: map-get($size, margin-block-small);
      height: 300px;
    }
  }
}