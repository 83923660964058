
@mixin media--minmax ($minwidth, $maxwidth){
  @media (min-width: $minwidth) and (max-width: $maxwidth){
    @content;
  }
}

@mixin media--min ($minwidth){
  @media (min-width: $minwidth){
    @content;
  }
}

@mixin media--max ($maxwidth){
  @media (max-width: $maxwidth){
    @content;
  }
}

@mixin animationRight($time){
  animation: widthRight $time ease forwards;
}