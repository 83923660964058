.dealer-navigation-digital__wrapper{
    .dealer-navigation-digital{
        display: flex;
        align-items: center;
        justify-content: center;
        // margin: 80px 0;
        @include spaceL(margin, 0);

        .nav-items{
            &:not(:first-child){
                margin-left: 120px;
                &::before{
                    position: absolute;
                    content: "";
                    top: 50%;
                    left: -60px;
                    transform: translate(-50%, -50%);
                    height: 50%;
                    width: 2px;
                    background: map-get($mau, color-primary ) ;
                }
            }
            cursor: pointer;
            padding: 20px 35px 20px 6px;
            position: relative;
            font-size: 27px;
            color: map-get($mau, color-primary );
            white-space: nowrap;
            // margin-right: 10%;
            min-width: 20%;
  
            img{
                display: none;
            }
        }

        
        .active{
            border: solid 2px map-get($mau,color-primary );
            border-radius: 6px;
            position: relative;
            &::after{
                margin-left: 3px;
                width: 2rem;
                height: 2rem;
                position: absolute;
                top: 50%;
                right: 8px;
                transform: translateY(-50%);
                content: "";
                background-image: url("../assets/images/dealer-digital/arrow.png");
                background-repeat: no-repeat;
                background-position: center;
            }
        }
    }
}

@media (max-width: map-get($media, ps)){
    .dealer-navigation-digital__wrapper .dealer-navigation-digital{
        // margin: 0px;
        flex-direction: column;
    }
    .dealer-navigation-digital__wrapper .dealer-navigation-digital .nav-items:not(:first-child)::before{
        display: none;
    }
    .dealer-navigation-digital__wrapper .dealer-navigation-digital .nav-items:not(:first-child){
        margin-left: 0;
    }
}
@media (max-width: map-get($media, tl)){

    .dealer-navigation-digital__wrapper .dealer-navigation-digital .nav-items{
        padding: 10px 40px 10px 10px;
        margin-right: 0;

        margin-top: 12px;
    }
}
@media (max-width: map-get($media, ml)){

}