// .AS-media {
//     margin-top: 100px;
// }
.AS-container {
    background-color: #041e42;
    color: map-get($mau, gray-1);
}
.AS-header {
    @include font-heading(3);
    font-family: inherit;
    font-weight: 400;
    @include p-line-clamp(1);
    @include spaceL(padding-top);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: inherit;
}

.AS-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    letter-spacing: 1px;

    &-1 {
        @include font-heading(3);
        font-family: inherit;
        @include spaceM(margin-top);
        display: flex;
        flex-direction: row;
        text-align: center;
        justify-content: center;
        width: 70%;
        margin-inline: 5px;
        padding: 0 12px;
        color: inherit;

        .before,.after {
            height: fit-content;
            width: 48px;
            height: 48px;
            object-fit: contain;
            padding-right: 12px;
            margin-top: -16px;
        }
        .before{
            align-items: flex-start;
        }
        .after {
            transform: rotate(180deg) translateY(-16px);
            align-self: flex-end;
        }

        p{
            @include p-line-clamp(2);
        }
    }
    &-2 {
        @include font-p;
        // margin-top: 50px;
        font-weight: 300;
        @include spaceM(margin-top);
        color: inherit;
        
        font-style: italic;
        p{
            @include p-line-clamp(1);
        }
    }
    &-3 {
        @include spaceM(margin-top);
        font-weight: bold;
        text-transform: uppercase;
        padding: 10px;
        border-radius: 5px;
        background-color: transparent;
        border: 3px solid map-get($mau, gray-1);
        display: inline-block;
        text-transform: inherit;
        a {
            color: map-get($mau, gray-1);
        }
        &:hover {
            a {
                color: map-get($mau, color-primary);
            }
            border: 3px solid map-get($mau, color-primary);
            background-color: map-get($mau, gray-1);
        }
    }
}
.AS-footer {
    @include spaceL(padding, 0);
    .AS-logo {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;

        .AS-logo-slogan {
            object-fit: contain;
            object-position: center;
            flex: 1;

            width: 170px;
            height: 61px;
            // &::after{
            //     position: absolute;
            //     content: "";
            //     top: 0;
            //     left: 350%;
            //     width: 1px;
            //     height: 100%;
            //     background: gray;
            // }

            &:not(:first-child) {
                border-left: 3px solid rgba(map-get($mau, gray-2), 0.3);
            }
        }
    }
}


@media (max-width: map-get($media, tl)){
    // .AS-media{
    //     margin-top: 56px;
    // }
    .AS-content-1{
        width: 95%;
    }
    .AS-content-1 .before,
    .AS-content-1 .after{
        width: 36px;
        height: 36px;
    }
}
@media (max-width: map-get($media, ml)){
    // .AS-media{
    //     margin-top: 56px;
    // }
    .AS-content-1{
        width: 95%;
    }
    .AS-content-1 .before,
    .AS-content-1 .after{
        width: 26px;
        height: 26px;
    }
}

@media (max-width: map-get($media, ts)){
    // .AS-media{
    //     margin-top: 36px;
    // }
    .AS-footer .AS-logo .AS-logo-slogan{
        &:not(:first-child) {
            border-left: 0px solid rgba(map-get($mau, gray-2), 0.3);
        }
        margin: 24px;
        flex: initial;
    }
}