.dealer-help__wrapper{
    background: map-get($mau, gray-2 );
    // padding-bottom: 100px;
    @include spaceL(padding-bottom);
    .container{
        padding-left: 0;
        padding-right: 0;
        .dealer-help{
            .help-title{
                text-align: center;
                // padding: 50px 0;
                @include spaceL(padding, 0);
            }
            .help-section{
                align-self: center;
                justify-content: space-between;
                margin: auto;
                .col, [class*=col-]{
                    padding-left: 15px;
                    padding-right: 15px;
                }
                .dealer-items{
                    .img-section {
                        img{
                            width: 100%;
                            // height: auto;
                        }
                        position: relative;
                        .overlay-section{
                            display: flex;
                            align-items: center;
                            justify-content: space-evenly;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            padding: 10px 0;
                            width: 100%;
                            background: map-get($mau, color-primary );
                            .title{
                                font-size: 27px;
                                font-family: HELVETICANEUELTSTD-ROMAN;
                                color: map-get($mau, gray-1 );
                                transform: translateY(4px)
                            }
                            .more{
                                margin: 0;
                                border-color: map-get($mau, gray-1 );
                                p{
                                    color: map-get($mau, gray-1 );
                                }
                            }
                        }
                    }
                    
                }
            }
        }
    }    
}


@media (max-width: map-get($media, pm)){
    .dealer-help__wrapper .container .dealer-help .help-section .dealer-items .img-section .overlay-section{
        .title, .more{
            font-size: 20px;
        }
    } 
}

@media (max-width: map-get($media, ps)){
    .dealer-help__wrapper .container .dealer-help .help-section .dealer-items .img-section .overlay-section{
        .more{
            font-size: 20px;
        }
        .title{
            font-size: 22px;
        }
    } 
    .dealer-help__wrapper .container .dealer-help .help-section{
        flex-direction: column;
        &>*{
            flex: 0 0 80%;
            max-width: 80%;
            margin-left: auto;
            margin-right: auto;
        }
        .dealer-items{
            & + .dealer-items{
                @include spaceM(margin-top);
            }
        }
    }
}

@media (max-width: map-get($media, ml)){
    .dealer-help__wrapper .container .dealer-help .help-section .dealer-items .img-section .overlay-section{
        .more{
            font-size: 16px;
        }
        .title{
            font-size: 20px;
        }
    }
    .dealer-help__wrapper .container .dealer-help .help-section{
        &>*{
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}
@media (max-width: map-get($media, mm)){
    .dealer-help__wrapper .container .dealer-help .help-section .dealer-items .img-section .overlay-section{
        .more{
            font-size: 0px;
            padding: 5px 5px;
        }
        .title{
            font-size: 16px;
        }
    }
}