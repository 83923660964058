.clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

.fullsize {
	width: 100%;
	height: 100%;
}

.object-cover {
	object-fit: contain;
	object-position: center;
	background-size: cover;
}

.object-contain {
	object-fit: contain;
	object-position: center;
	background-size: contain;
}

.blur-up {
	filter: blur(10px);
	transition: filter 1s;

	&.lazyloaded {
		filter: blur(0);
	}
}

.img-cover {
	width: 100%;
	height: 100%;

	object-fit: cover;
	object-position: center;
}

.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

header {
	overflow: hidden;
}
main {
	overflow: hidden;
}
footer {
	overflow: hidden;
}

// product
.product-infor {
	.product-infor__title {
		@include font-heading(3);
	}
	.product-infor__content {
		// margin: 40px 0;
		@include spaceM(margin,0);

		color: map-get($mau, dark-gray);
		display: -webkit-box;
		-webkit-line-clamp: 9;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	.product-infor__more {
		color: map-get($mau, color-primary);
		font-weight: bold;
		padding-top: 24px;
		border-top: 1px solid map-get($mau, dark-gray);
		& + .product-infor__more{
			margin-top: 12px;
			padding-top: 0;
			border: none;
		}
		span {
			color: map-get($mau, dark-gray);
		}

	}
}

// btn more
.more {
	display: flex;
	width: fit-content;
	align-items: center;
	padding: 5px 10px;
	border: solid 2px map-get($mau, color-primary);
	border-radius: 5px;
	margin: 40px 0;
	cursor: pointer;
	p {
		text-transform: uppercase;
		margin-right: 5px;
		white-space: nowrap;

		color: map-get($mau, color-primary);
		font-weight: bold;
	}
}
.btn--download {
	p {
		font-weight: 600;
	}
}

.btn {
	a {
		background-color: white;
		border: 1px solid map-get($mau, color-primary);
		color: map-get($mau, color-primary);

		padding: 0px 12px;
		border-radius: 3px;
		font-weight: bold;
		position: relative;
		line-height: 36px;

		display: inline-flex;
		justify-content: space-between;
		align-items: center;

		transition: .3s all ease;

		&:hover {
			background-color: #041e42;
			color: white;
		}
		svg {
			margin-left: 4px;
			
			path {
				stroke: map-get($mau, color-primary);
			}
		}
	}

	.btn-arrow {
		&.btn-arrow--white {
			background-color: white;
			border: 1px solid map-get($mau, color-primary);
			color: map-get($mau, color-primary);
			&:hover {
				background-color: #041e42;
				color: white;

				svg {
					path {
						stroke: white;
					}
				}
			}
			svg {
				path {
					stroke: map-get($mau, color-primary);
				}
			}
		}
		&.btn-arrow--transparent {
			background-color: transparent;
			border: 1px solid map-get($mau, color-primary);
			color: map-get($mau, color-primary);
			&:hover {
				background-color: #041e42;
				color: white;

				svg {
					path {
						stroke: white;
					}
				}
			}
			svg {
				path {
					stroke: map-get($mau, color-primary);
				}
			}
		}
		&.btn-arrow--primary {
			background-color: map-get($mau, color-primary);
			border: 1px solid white;
			color: white;
			&:hover {
				background-color: white;
				color: map-get($mau, color-primary);

				svg {
					path {
						stroke: map-get($mau, color-primary);
					}
				}
			}
			svg {
				path {
					stroke: white;
				}
			}
		}
	}
}

.swiper-btn__nav {
	position: relative;
	.swiper-button-prev,
	.swiper-button-next {
		&::after {
			display: none;
		}
		position: absolute;
		top: 50%;
		user-select: none;
	}
}

// font family for heading
@for $i from 1 through 6 {
	.font-heading-#{$i} {
		color: map-get($mau, color-primary);
		font-family: "HELVETICA-NEUE", "Segoe UI", sans-serif;
		font-size: map-get($size, "font-h#{$i}");
		font-weight: 500;
	}
	@media (max-width: map-get($media, tl)) {
		.font-heading-#{$i} {
			font-size: map-get($size, "font-h#{$i}-tl");
		}
	}
	@media (max-width: map-get($media, ml)) {
		.font-heading-#{$i} {
			font-size: map-get($size, "font-h#{$i}-ml");
		}
	}
}

.font-p {
	color: map-get($mau, dark-gray);
	font-family: "Segoe UI", sans-serif;
	font-size: map-get($size, "font-p");
	font-weight: 400;
}
@media (max-width: map-get($media, tl)) {
	.font-p {
		font-size: map-get($size, "font-p-tl");
	}
}
@media (max-width: map-get($media, ml)) {
	.font-p {
		font-size: map-get($size, "font-p-ml");
	}
}

.font-p-small {
	color: map-get($mau, dark-gray);
	font-family: "HELVETICA-NEUE", "Segoe UI", sans-serif;
	font-size: map-get($size, "font-p-small");
	font-weight: 500;
}
@media (max-width: map-get($media, tl)) {
	.font-p-small {
		font-size: map-get($size, "font-p-small-tl");
	}
}
@media (max-width: map-get($media, ml)) {
	.font-p-small {
		font-size: map-get($size, "font-small-p-ml");
	}
}

@for $i from 1 through 5 {
	.p-line-clamp--#{$i} {
		display: -webkit-box;
		-webkit-line-clamp: #{$i};
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
}

.overlay-black {
	position: relative;
	&::after {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		content: "";
		background: map-get($mau, black-op);
		z-index: 1;
	}
}

.span--date {
	@include font-p-small;
	display: block;
	font-style: italic;
	margin-top: map-get($size, margin-block-small);
	color: map-get($mau, color-primary);
	text-transform: uppercase;
}


::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}
/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px map-get($mau, gray-1);
    border-radius: 5px;
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: map-get($mau, color-primary);
    border-radius: 5px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(map-get($mau, dark-gray), 0.7);
}