.details-function-benefit__wrapper{
    // margin: 120px 0;
    @include spaceXL(margin-bottom);
    .details-nav{
        // margin-bottom: 60px;
        @include spaceL(margin-bottom);
        .nav-function {
            display: flex;
            align-items: center;
            color: map-get($mau, color-primary );
            font-family: SEGOEUI;
            text-transform: uppercase;
            position: relative;
            *{
                cursor: pointer;
                margin-right: 20px;
            }
            &::after{
                position: absolute;
                content: "";
                bottom: -4px;
                right: 0;
                width: 98%;
                height: 1px;
                background: map-get($mau, color-primary );
                opacity: 0.4;
            }
            .active{
                position: relative;
                &::after{
                    position: absolute;
                    content: "";
                    bottom: -5px;
                    left: 0;
                    width: 70%;
                    border: solid 2px map-get($mau, underline-red );
                    background: map-get($mau, underline-red ) ;
                    border-radius: 3px;
                    z-index: 1;
                }
            }
        }
    }
    
    .details-function-benefit{

        .content--value{
            display: none;
            flex-wrap: wrap;
            
            &.active{
                display: flex;
            }

        }
        .details-function{
            width: 50%;
            // padding-right: 24px;
            @include spaceL(padding-right);
            .content-function {
                // height: 697px;
                // overflow: hidden;
                .product-infor{
                    .product-infor__title{
                        // margin-bottom: 60px;
                        @include spaceM(margin-bottom);
                    }
                    .product-infor__more{
                        margin-bottom: 12px;
                        padding-top: 0;
                        border-top: 0;
                    }
                    .product-infor__list-details{
                        ul{
                            padding: 5px;
                            margin-left: 13px;
                            li{
                                list-style-type: initial;
                                margin-bottom: 10px ;
                                p{
                                    color: map-get($mau, dark-gray );
                                }
                            }
                        }
                    }
                    .product-infor__content{
                        -webkit-line-clamp: initial;
                        -webkit-box-orient: initial;
                        display: block;
                        max-height: 220px;
                        overflow-y: scroll;
                        overflow-x: hidden;

                        margin-bottom: 0;
                    }
                }
            }
        }
        .details-benefit{
            width: 50%;
            // align-self: stretch;
            height: 620px;  
            display: flex;
            align-items: center;
            justify-content: center;
            // padding: 0 40px;
            img,
            video,
            iframe{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

// @media (max-width: 1440px) and (min-width:601px){
//     .details-function-benefit__wrapper{
//         // margin: 80px 0;
//         .details-nav{
//             .nav-function {
//                 font-size: 15px;
//             }
//         }
        
//         // .details-function-benefit{
//         //     .details-function{
//         //         .content-function {
//         //             // height: 697px;
//         //             // overflow: hidden;
//         //             .product-infor{
//         //                 .product-infor__title{
//         //                     margin-bottom: 30px;
//         //                     font-size: 40px;
//         //                 }
//         //                 // .product-infor__more{
//         //                 //     font-size: 16px;
//         //                 // }
//         //                 // .product-infor__list-details{
//         //                 //     ul{
//         //                 //         font-size: 16px;
//         //                 //     }
//         //                 // }
//         //                 // .product-infor__content{
//         //                 //    font-size: 16px;
//         //                 // }
//         //             }
//         //         }
//         //     }
//         // }
//     }
    
// }

@media (max-width: map-get($media, ts)){
    .details-function-benefit__wrapper{
        // margin: 56px 0;
        .details-nav{
            margin-bottom: 36px;
            .nav-function{
                width: 100%;
                flex: 0 0 100%;
                max-width: 100%;

                overflow-x: scroll;
                overflow-y: hidden;

                padding: 12px;
            }
        }
        .details-function-benefit{
            .content--value{
                flex-direction: column;
            }
        }
    }
    .details-function-benefit__wrapper .details-function-benefit .details-function{
        width: 100%;
    }
    .details-function-benefit__wrapper .details-function-benefit .details-benefit{
        width: 100%;
    }
    .details-function-benefit__wrapper .details-function-benefit .details-benefit img{
        width: 100%;
    }
}