.mattress-collections__wrapper {
    // height: 1138px;
    background: map-get($mau, gray-2);
    .container {
        text-align: center;
        .mattress-collections {
            // padding: 50px 0;
            @include spaceL(padding, 0);
            // .collections-title {
            //     @extend .font-heading-3;
            // }
            .collections-section {
                // margin: 50px 0;
                @include spaceL(margin, 0);
                .options {
                    @include font-heading(4);
                    color: map-get($mau, color-primary);                  
                    padding: 10px 20px;
                    cursor: pointer;

                    display: inline-block;

                    & + .options{
                        margin-left: 120px;
                        position: relative;
                        &::after{
                            position: absolute;
                            content: "";
                            top: 50%;
                            left: -60px;
                            height: 60%;
                            transform: translateY(-50%);
                            width: 2px;
                            background: map-get($mau, color-primary);
                        }
                    }
                }
                .active {
                    @include font-heading(7);
                    border: solid 2px map-get($mau, color-primary);
                    border-radius: 6px;
                }
                .collections__products {
                    img {
                        width: 57%;
                    }
                    // padding: 70px 0;
                    @include spaceL(padding,0);
                    display: flex;
                    // justify-content: center;
                    align-items: center;
                    .product-infor {
                        width: 43%;
                        text-align: start;
                        padding: 0 20px;
                        font-size: inherit;
                    }
                }
            }
            .collections-scroll {
                display: flex;
                align-items: center;
                justify-content: center;
                transform: rotate(270deg);
                text-transform: uppercase;
                font-size: 16px;
                font-family: SEGUISB;
                color: map-get($mau, dark-gray);
                opacity: 0.7;
                user-select: none;
                -moz-user-select: none;
                margin-bottom: 10px;
                img {
                    transform: rotate(90deg);
                    margin-right: 20px;
                    margin-top: 3px;
                }
                .text-scroll {
                    background-size: 200%;
                    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.1), map-get($mau, color-primary));
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    animation: gradient 1.3s;
                    animation-iteration-count: infinite;
                    @keyframes gradient {
                        100% {
                            background-position: 200%;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: map-get($media, ts)) {
    .collections__products {
        flex-direction: column;
    }
    .mattress-collections__wrapper .container .mattress-collections .collections-section .collections__products img{
        width: 100%;
    }
    .mattress-collections__wrapper
        .container
        .mattress-collections
        .collections-section
        .collections__products
        .product-infor {
        width: 100%;
        margin-top: 12px;
    }
    // .mattress-collections__wrapper .container .mattress-collections {
    //     padding: 0;
    // }
    // .mattress-collections__wrapper .container .mattress-collections .collections-section {
    //     margin: 36px 0;
    // }
    .mattress-collections__wrapper .container .mattress-collections .collections-scroll {
        // margin-bottom: 48px;
        display: inline-flex;
    }
}
// @media (max-width: map-get($media, ml)) {
//     .mattress-collections__wrapper .container .mattress-collections .collections-section .options {
//         margin: 8px;
//     }
//     .mattress-collections__wrapper .container .mattress-collections .collections-section .collections__products {
//         padding: 36px 0;
//     }
// }
