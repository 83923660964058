$mau: (
	main: rgb(7, 65, 76),
	color-primary: #041E42,
	gray-2: #ECECEC,
	gray-1: #C0C0C0,
	dark-gray: #707070,
	white-1: #ffffff,
	black-op: rgba(0,0,0,0.4),
	underline-red: #BF0D3E, 

	error: rgba(255, 0, 0, 0.658),
	success: #4bb543,
);

$media: (
	none: 0px,

	// mobile small
	ms: 320px,

	// mobile medium
	mm: 425px,

	// mobile large
	ml: 640px,

	// tablet small
	ts: 768px,

	// tablet medium
	tm: 900px,

	// tablet large
	tl: 1024px,

	// personal computer small
	ps: 1280px,

	// personal computer medium
	pm: 1440px,

	// personal computer large
	pl: 1920px,
);

$size: (
	// header device size large
	header-large: 103px,
	// header device size small
	header-small: 78px,

	margin-block-x-large:128px,
	margin-block-large:64px,
	margin-block-medium:40px,
	margin-block-small:32px,

	font-h1:80px,
	font-h1-tl:56px,
	font-h1-ml:30px,

	font-h2:65px,
	font-h2-tl:50px,
	font-h2-ml:45px,

	font-h3:50px,
	font-h3-tl:40px,
	font-h3-ml:32px,

	font-h4:30px,
	font-h4-tl:26px,
	font-h4-ml:22px,
	// hover h4
	font-h7:32px,
	font-h7-tl:28px,
	font-h7-ml:24px,
	
	font-h5:20px,
	font-h5-tl:19px,
	font-h5-ml:18px,
	
	font-h6:17px,
	font-h6-tl:16px,
	font-h6-ml:15px,


	font-p:20px,
	font-p-tl:18px,
	font-p-ml:16px,

	font-p-small:16px,
	font-p-small-tl:15px,
	font-p-small-ml:14px,
);

@mixin spaceXL($name, $value: -1px){
	@if ($value != -1px){
		#{$name}: map-get($size, margin-block-x-large) $value;
		@media (max-width: map-get($media, ps)){
			#{$name}: map-get($size, margin-block-large) $value;
		}
		@media (max-width: map-get($media, ml)){
			#{$name}: map-get($size, margin-block-media) $value;
		}
	}
	@else{
		#{$name}: map-get($size, margin-block-x-large);
		@media (max-width: map-get($media, ps)){
			#{$name}: map-get($size, margin-block-large);
		}
		@media (max-width: map-get($media, ml)){
			#{$name}: map-get($size, margin-block-media);
		}
	}
}
@mixin spaceL($name, $value: -1px){
	@if ($value != -1px){
		#{$name}: map-get($size, margin-block-large) $value;
		@media (max-width: map-get($media, ps)){
			#{$name}: map-get($size, margin-block-medium) $value;
		}
		@media (max-width: map-get($media, ml)){
			#{$name}: map-get($size, margin-block-small) $value;
		}
	}
	@else{
		#{$name}: map-get($size, margin-block-large);
		@media (max-width: map-get($media, ps)){
			#{$name}: map-get($size, margin-block-medium);
		}
		@media (max-width: map-get($media, ml)){
			#{$name}: map-get($size, margin-block-small);
		}
	}
}
@mixin spaceM($name, $value: -1px){
	@if ($value != -1px){
		#{$name}: map-get($size, margin-block-medium) $value;
		@media (max-width: map-get($media, ps)){
			#{$name}: map-get($size, margin-block-small) $value;
		}
		@media (max-width: map-get($media, ml)){
			#{$name}: 12px $value;
		}
	}
	@else{
		#{$name}: map-get($size, margin-block-medium);
		@media (max-width: map-get($media, ps)){
			#{$name}: map-get($size, margin-block-small);
		}
		@media (max-width: map-get($media, ml)){
			#{$name}: 12px;
		}
	}
}	
@mixin spaceS($name, $value: -1px){
	@if ($value != -1px){
		#{$name}: map-get($size, margin-block-small) $value;
		@media (max-width: map-get($media, ps)){
			#{$name}: 24px $value;
		}
		@media (max-width: map-get($media, ml)){
			#{$name}: 16px $value;
		}
	}
	@else{
		#{$name}: map-get($size, margin-block-small);
		@media (max-width: map-get($media, ps)){
			#{$name}: 24px;
		}
		@media (max-width: map-get($media, ml)){
			#{$name}: 16px;
		}
	}
}

@mixin spaceButton($name, $value: -1px){
	@if ($value != -1px){
		#{$name}: 24px $value;
		@media (max-width: map-get($media, ps)){
			#{$name}: 16px $value;
		}
		@media (max-width: map-get($media, ml)){
			#{$name}: 12px $value;
		}
	}
	@else{
		#{$name}: 24px;
		@media (max-width: map-get($media, ps)){
			#{$name}: 16px;
		}
		@media (max-width: map-get($media, ml)){
			#{$name}: 12px;
		}
	}
}
@mixin spaceButtonS($name, $value: -1px){
	@if ($value != -1px){
		#{$name}: 20px $value;
		@media (max-width: map-get($media, ps)){
			#{$name}: 12px $value;
		}
		@media (max-width: map-get($media, ml)){
			#{$name}: 8px $value;
		}
	}
	@else{
		#{$name}: 20px;
		@media (max-width: map-get($media, ps)){
			#{$name}: 12px;
		}
		@media (max-width: map-get($media, ml)){
			#{$name}: 8px;
		}
	}
}

@mixin spaceContainer(){
	width: 96%;
	padding-right: 10px;
	padding-left: 10px;
	margin-right: auto;
	margin-left: auto;
	position: relative;

	@media (min-width: 576px) {
		max-width: 95%;
	}
	
	@media (min-width: 1025px) {
		max-width: 900px;
	}

	@media (min-width: 1280px) {
		max-width: 1200px;
		padding-right: 3%;
		padding-left: 3%;
	}

	@media (min-width: 1440px) {
		max-width: 1280px;
		padding-right: 3%;
		padding-left: 3%;
	}

	@media (min-width: 1600px) {
		max-width: 1366px;
		padding-right: 3%;
		padding-left: 3%;
	}
	@media (min-width: 1800px) {
		max-width: 1640px;
		padding-right: 5%;
		padding-left: 5%;
	}
}

// font family for heading
@mixin font-heading($val){
	color: map-get($mau, color-primary);
	font-family: "HELVETICA-NEUE", "Segoe UI", sans-serif;
	font-size: map-get($size, "font-h#{$val}");
	font-weight: 500;
	@media (max-width: map-get($media, tl)) {
		font-size: map-get($size, "font-h#{$val}-tl");
	}
	@media (max-width: map-get($media, ml)) {
		font-size: map-get($size, "font-h#{$val}-ml");
	}
}

@mixin font-p{
	color: map-get($mau, dark-gray);
	font-family: "Segoe UI", sans-serif;
	font-size: map-get($size, "font-p");
	font-weight: 400;
	@media (max-width: map-get($media, tl)) {
		font-size: map-get($size, "font-p-tl");
	}
	@media (max-width: map-get($media, ml)) {
		font-size: map-get($size, "font-p-ml");
	}
}
@mixin font-p-medium{
	color: map-get($mau, dark-gray);
	font-family: "Segoe UI", sans-serif;
	font-size: 18px;
	font-weight: 400;
	@media (max-width: map-get($media, tl)) {
		font-size: 17px;
	}
	@media (max-width: map-get($media, ml)) {
		font-size: 16px;
	}
}

@mixin font-p-small {
	color: map-get($mau, dark-gray);
	font-family: "Segoe UI", sans-serif;
	font-size: map-get($size, "font-p-small");
	font-weight: 400;
	@media (max-width: map-get($media, tl)) {
		font-size: map-get($size, "font-p-small-tl");
	}
	@media (max-width: map-get($media, ml)) {
		font-size: map-get($size, "font-small-p-ml");
	}
}

@mixin p-line-clamp($val) {
	display: -webkit-box;
	-webkit-line-clamp: $val;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
