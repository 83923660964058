.details-review__wrapper{
    background: map-get($mau, color-primary );
    // padding: 80px 0;
    @include spaceL(padding,0);
    // height: 800px;
    .details-review {
        @include spaceM(margin-bottom);
        .details-review__title{
            .product-infor{
                p{
                    color: map-get($mau, gray-1 );
                }
            }
        }
        .details-review__form{
            .form-left{
                // margin: 10px 0;
                @include spaceS(margin-top);
                display: flex;
                flex-direction: column;
                .rate-comment{
                    // position: relative;
                    flex: 1;
                    textarea{
                        border-radius: 10px;
                        width: 100%;
                        height: 100%;
                        border: none;
                        background: map-get($mau, white-1 );
                        padding-left: 20px;
                        resize: none;
                        padding-top: 12px;
                    }
                }
                
                .rate-section{
                    align-items: center;
                    margin-bottom: 24px;
                    .rate-title__items{
                        color: map-get($mau, gray-1 );
                        
                    }
                    .rate-star__items{
                        .items-star{
                            display: inline;
                            *{
                                margin-right: 5px;
                                position: relative;
                                width: 2rem;
                                height: 2rem;
                                text-indent: -9999rem;
                                display: inline-block;
                                &::after{
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    content: "";
                                    width: 2rem;
                                    height:2rem;
                                    background-image: url("../assets/icons/white-star-whiteborder.png");
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    text-indent: 0;
                                    display: inline;
                                }
                            }
                            *:hover{
                                &::after{
                                    background-image: url("../assets/icons/white-star.png");
                                   
                                }
                            }
                            *.active{
                                &::after{
                                    background-image: url("../assets/icons/white-star.png");
                                   
                                }
                            }
                        }
                    }
                }
            }
            .form-right{
                // margin: 10px 0;
                @include spaceS(margin-top);
                
                .row-input{
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;
                    input{
                        width: 49%;
                    }
                }
                
                .collumn-input{
                    height: 140px;
                    flex-direction: column;
                    display: flex;
                    // align-content: space-between;
                    justify-content: space-between;
                    button{
                        border-radius: 10px;
                        width: 100%;
                        height: 61px;
                        border: solid 1px  map-get($mau, white-1 );
                        background: map-get($mau, color-primary );
                        color:  map-get($mau, gray-1 );
                        text-align: center;
                        transition: .3s all linear;
                        &:hover{
                            border: solid 1px  map-get($mau,  color-primary);
                            color: map-get($mau, color-primary );                        
                            background:  map-get($mau, gray-1 );
                        }
                    }
                }
                input{
                    border-radius: 10px;
                    width: 100%;
                    height: 61px;
                    border: none;
                    background: map-get($mau, white-1 );
                    padding-left: 20px;
                    ::placeholder{
                        color: map-get($mau, dark-gray );
                    }
                }
                
            }
        }
    }
    .details-user__comment{
        margin-top: 24px;
        
        .comment__more{
            margin-top: 12px;
            display: flex;
            justify-content:center;
            align-items:center;
            display: none;
            &.active{
                display: flex;
            }
            &--btn{
                position: relative;
                width: 36px;
                height: 36px;
                color: map-get($mau, gray-2);
                border-radius:2px;

                &::after{
                    position: absolute;
                    content: "\279C";
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) rotate(90deg);
    
                    width: 100%;
                    height: 100%;
                    border-radius:2px;
    
                    background-color: map-get($mau, dark-gray);
    
                    display: flex;
                    justify-content: center;
                    align-items: center;
    
                    cursor: pointer;
                }
                &:hover::after{
                    box-shadow: 0 0 3px rgba(map-get($mau, dark-gray), .3);
                    background-color: rgba(map-get($mau, dark-gray), .3);
                }
            }
        }
        .comment__reviews{
            .amount-comment{
                color: map-get($mau, gray-1);
                align-items: center;
                padding-bottom: 12px;
                // span {
                //     display: inline-block;
                //     width: 0;
                //     height: 0;
                //     vertical-align: middle;
                //     border-top: 7px dashed;
                //     border-right: 7px solid transparent;
                //     border-left: 7px solid transparent;
                //     transform: rotate(270deg);
                //     margin-right: 10px;
                // }
            }
        }
        .comment__section{
            display: flex;
            flex-direction: column;
            color: map-get($mau, gray-1 );

            .comment-items{  
                // padding: 20px 0;
                
                border-top: solid 1px map-get($mau, white-1);
                &:last-child{
                    border-bottom: none;
                }
                .user-comment-section{
                    
                    .active{
                        height: 100%;
                    }
                    .rate-section{
                        cursor: pointer;
                        display: flex;
                        justify-content:space-between;
                        align-items: center;

                        padding: 12px 8px 12px 8px;

                        &--left{
                            flex: 1;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            &>*:not(:first-child){
                                margin-left: 20px;
                            }
                        }
                        &--right{
                            .comment__date{
                                font-size: 18px;
                                font-style: italic;
                                color:rgba(map-get($mau, gray-1),.7);
                                font-weight: bold;
                            }
                        }

                        &:hover{
                            background: rgba(map-get($mau, dark-gray),.5);
                        }
                        height: 100%;
                        margin-left: 0;
                        
                        .user-avatar{
                            display: flex;
                            justify-content:flex-start;
                            align-items:center;
                            .user-name{
                                border-radius: 50%;
                                width: 35px;
                                height: 35px;
                            }
                            width: 36px;
                        }
                        .rate-title__items{
                            color: map-get($mau, gray-1 );

                            width: 320px;

                        }
                        .rate-star__items{
                            // margin-left: 30px;
                            .items-star{
                                display: inline;
                                *{
                                    margin-right: 5px;
                                    position: relative;
                                    width: 2rem;
                                    height: 2rem;
                                    text-indent: -9999rem;
                                    display: inline-block;
                                    &::after{
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        content: "";
                                        width: 2rem;
                                        height:2rem;
                                        background-image: url("../assets/icons/white-star-whiteborder.png");
                                        background-repeat: no-repeat;
                                        background-position: center;
                                        text-indent: 0;
                                        display: inline;
                                    }
                                    &.active{
                                        &::after{
                                            background-image: url("../assets/icons/white-star.png");
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .user-comment{
                    height: 0;
                    overflow: hidden;
                    .comment{
                        padding: 12px 8px 12px 8px;
                        background-color: rgba(map-get($mau, dark-gray), .1);
                    }
                }
            }
        }
    }
}

@media (max-width: map-get($media, ts)){
    .details-review__wrapper .details-review .details-review__form .form-left{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .details-review__wrapper .details-review .details-review__form .form-right{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .details-review__wrapper .details-review .details-review__form .form-right .row-input{
        flex-direction: column;
        input{
            width: 100%;
            &:not(:first-child){
                margin-top: 20px;
            }
        }
    }
    .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section .rate-title__items{
        width: 200px;
    }
    .details-review__wrapper .details-review .details-review__form .form-left .rate-section .rate-title__items{
        flex: 0 0 40%;
        max-width: 40%;
    }
    .details-review__wrapper .details-review .details-review__form .form-left .rate-section .rate-star__items{
        flex: 0 0 60%;
        max-width: 60%;
    }
    .details-review__wrapper .details-review .details-review__form .form-left .rate-comment textarea{
        height: 120px;
    }
    .details-review__wrapper .details-review .details-review__form .form-right {
        input, button{
            height: 56px;
        }
    }
    .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section--right{
        flex: 0 0 25%;
        width: 25%;
        text-align: right;
    }
    .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section--left{
        flex-direction: column;
        text-align: center;
        & > *:not(:first-child){
            margin-top: 4px;
        }
    }
    .details-review__wrapper .details-user__comment .comment__section .comment-items .user-comment-section .rate-section--left>:not(:first-child){
        margin-left: 0;
    }
}