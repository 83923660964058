.dealer-new__wrapper{
    // padding-bottom: 100px;
    @include spaceL(padding-bottom);
    .dealer-new{
        .new-title{
            text-align: center;
            padding: 80px 0;
        }
        .new-section{
            .col, [class*=col-]{
                padding-left: 15px;
                padding-right: 15px;
            }
            .dealer-items{
                .img-section {
                    position: relative;
                    img{
                        width: 100%;
                        // height: auto;
                    }
                    .overlay-section{
                        display: flex;
                        align-items: center;
                        justify-content: space-evenly;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        padding: 10px 0;
                        width: 100%;
                        background: map-get($mau, color-primary );
                        
                        .title{
                            font-size: 27px;
                            font-family: HELVETICANEUELTSTD-ROMAN;
                            color: map-get($mau, gray-1 );
                            transform: translateY(4px)
                        }
                        .more{
                            margin: 0;
                            border-color: map-get($mau, gray-1 );
                            p{
                                color: map-get($mau, gray-1 );
                            }
                        }
                    }
                }
                
            }
        }
    }
}

@media (max-width: map-get($media, ps)){
    .dealer-new__wrapper .dealer-new .new-section{
        flex-direction: column;
        &>*{
            flex: 0 0 80%;
            max-width: 80%;
            margin-left: auto;
            margin-right: auto;
        }
        &>*:not(:first-child){
            margin-top: 50px;
        }
    }
    .dealer-new__wrapper .dealer-new .new-title{
        padding: 50px;
    }
}

@media (max-width: map-get($media, tl)){
    .dealer-new__wrapper .dealer-new .new-title{
        padding: 36px 0;
    }
}
@media (max-width: map-get($media, ml)){
    .dealer-new__wrapper .dealer-new .new-section{
        &>*{
            flex: 0 0 100%;
            max-width: 100%;
        }
        &>*:not(:first-child){
            margin-top: 36px;
        }
    }
}