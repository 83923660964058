.tab-container {
	.list-tab {
		.item {
			cursor: pointer;

			&.active {
				// color: map-get($mau, main);
			}
		}
	}

	.tab-content {
		.content {
			display: none;
			opacity: 0;
			transition: all .3s cubic-bezier(.2, .5, .5, .8);

			&.show {
				opacity: 1;
			}
		}
	}
}