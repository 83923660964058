.intro{
    &__button{
        @include spaceM(margin-top);
        &--link{
            padding: 12px 24px !important;

            @media (max-width: map-get($media, tl)) {
                padding: 8px 12px !important;                
            }
            @media (max-width: map-get($media, ml)) {
                font-size: 14px;
            }
        }
    }
}