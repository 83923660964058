.blog-detail__wrapper{
  // margin-bottom: map-get($size, margin-block-medium);

  .blog-detail{
    // margin-top: map-get($size, margin-block-large);
    @include spaceL(margin, 0);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &--left{
      flex: 0 0 70%;
      width: 70%;

      & > *:not(:first-child){
        @include spaceButton(margin-top);
      }

      &__title{
        // margin-bottom: map-get($size, margin-block-medium);
        @include spaceM(margin-bottom);
      }

      &__img{
        width: 100%;
        height: 629px;

        object-fit: cover;
        object-position: center;
      }
      p{
        white-space: pre-line;
        // letter-spacing: 1px;
        line-height: 1.2;
        word-spacing: 1px;
      }

      &__desc{
        white-space: pre-line;
        // letter-spacing: 1px;
        line-height: 1.2;
        word-spacing: 1px;
      }
    }
    &--right{
      flex: 0 0 25%;
      width: 25%;
      .blog-detail__title--right{
        font-weight: 700;
        @include spaceM(margin-bottom);
      }
      .blog{
        display: flex;
        flex-direction: column;
        &-item{
          &__img{
            height: 238px;
            object-position: center;
            object-fit: cover;
          }
          &__content{
            &-date{
              @extend .span--date;
            }
            &-title{
              @extend .title-blog;
              margin: 8px 0;
              font-weight: 700;
            }
          }
          padding-top: 8px;
          border-bottom: 1px solid rgba(map-get($mau, color-primary), 0.25);
          transition: .3s all linear;
          &:hover{
            opacity: .7;
            background-color: rgba(map-get($mau, gray-1), .7);
          }
        }
      }

      .share-detail{
        // margin-bottom: map-get($size, margin-block-small);
        @include spaceS(margin-bottom);
        &__title{
          padding-bottom: 5px;
          border-bottom: 1px solid map-get($mau, color-primary);
        }
        &-social{
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          li + li{
            margin-left: 12px;
          }
          &__item{
            margin: map-get($size, margin-block-small) 0;
          }
        }
        &-form{
          display: flex;
          flex-direction: column;
          &--info{
            padding: 8px 12px;
            font-size: 14px;
            &.error{
              color: map-get($mau, error);
            }
            &.success{
              color: map-get($mau, success);
            }
          }
          .group-input{
            display: flex;
            flex-direction: row;
          }
          input{
            height: 60px;
            outline: none;
            border: none;
            &:hover, &:focus{
              outline: none;
              border: none;
            }
          }
          input[type="email"]{
            width: 70%;
            padding-left: 16px;
            background-color: map-get($mau, gray-2);

            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }
          input[type="submit"]{
            font-weight: bold;
            text-transform: uppercase;
            font-size: 16px;
            padding: 0 8px;
            
            color: white;
            background-color: map-get($mau, color-primary);
            
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            transition: .3s all linear;
            &:hover,&:active{
              opacity: .9;
            }
          }
          
        }
      }
    }
  }
}

@media (max-width: map-get($media, ps)){
  .blog-detail__wrapper .blog-detail--right .share-detail-form input[type=email]{
    width: 100%;
    border-radius: 4px;
  }
  .blog-detail__wrapper .blog-detail--right .share-detail-form input[type=submit]{
    width: 100%;
    border-radius: 4px;
  }
  .blog-detail__wrapper .blog-detail--right .share-detail-form .group-input{
    flex-direction: column;
    &>*:not(:first-child){
      margin-top: 12px;
    }
  }
}
@media (max-width: map-get($media, tl)){
  .blog-detail__wrapper .blog-detail{
    flex-direction: column;
  }
  .blog-detail__wrapper .blog-detail--right{
    @include spaceL(margin-top);
  }
  .blog-detail__wrapper .blog-detail--left,
  .blog-detail__wrapper .blog-detail--right{
    flex: 0 0 100%;
    width: 100%;
  }
  .blog-detail__wrapper .blog-detail--right .share-detail-form .group-input{
    width: 60%;
    input{
      height: 46px;
      &:focus{
        border: 1px solid map-get($mau, color-primary);
      }
    }
  }

  .blog-detail__wrapper .blog-detail--right .blog-detail__title--right{
    margin-left: 1%;
  }

  .blog-detail__wrapper .blog-detail--right .blog{
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 1%;
    &-item{
      width: 50%;
      padding-right: 2%;
      padding-top: 12px;
      border-bottom: 0;
    }
  }
}

@media (max-width: map-get($media, mm)){
  .blog-detail__wrapper .blog-detail--right .blog-item{
    width: 100%;
  }
}