.featured{
    background-color: #ECECEC;
    width: 100%; 
    &-content{
        @include spaceContainer;
        height: auto;
        .block-featured {
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 0;
            text-align: unset;

            .featured-left{
                overflow: visible;
                // margin-left: 10%;
                // margin-top: 6%;
                z-index: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                
                width: 30%;
            }
			.featured-main-title{
				.title {
					.title-left{
                        @include spaceM(margin-bottom);
                        color:#041E42;
                        font-weight: 500;
                    }
                }
                .content{
                    @include spaceM(margin-bottom);
                    white-space: wrap;
                    
                    p {
                        color:#707070 ;
                        overflow: hidden;
                        text-overflow:ellipsis;
                        -webkit-line-clamp: 8;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        white-space:wrap;
                    }
                }
                
                @media (max-width: 1024px) {
                    max-width: 100%;
                    .block-list-item {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}

.featured-right{   
    user-select: none;
    margin-left: 24px;
    width: 70%;
    .block-pic {
        width: 100%;
        height: 100%;

        .pic{
            width: 100%;
            height: 100%;
        }
    }
}

@media (max-width: map-get($media, ps)) {
    .featured-content .block-featured .featured-left{
        margin-top: 24px;
        margin-left: 0px;
        width: 80%;
    }
    .featured-right{
        width: 100%;
    }
    .featured-content .block-featured .block-main-title .title p{
        font-size: 28px;
    }
    // .featured-right {
    //     // width: 460px;
    //     // height: 346px;
    // }

    .featured-content .block-featured{
        width: 100%;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        // padding: 10px;
        @include spaceL(padding-bottom);

        .featured-left{
            margin-left: 0px;
            text-align: center;
        }

        .block-main-title {
            .title, .content{
                margin-bottom: 24px;
            }
        }
    }
}

@media (max-width: map-get($media, tl)){
    .featured-content .block-featured .featured-main-title .content p{
        -webkit-line-clamp: 4;
    }
    .featured-content .block-featured .featured-left{
        width: 90%;
    }
}

@media (max-width: map-get($media, ts)){
    .featured-content .block-featured .featured-left{
        width: 100%;
    }
}
@media (max-width: map-get($media, mm)) {
    .pic{
        .icons{
            display: none;
        }
    }
}



.pic {
    position: relative;
    height: 100%;
    
    $size-icon: 32px;
    .index-2__bg{
        display: block;
        width: 100%;
        height: 100%;
    }

    .icons{
        position: absolute;
        top: 0;
        left: 0;
        list-style-type: none;
        width: 100%;
        height: 100%;
        padding: 0;
        
        &-item{
            background-color: rgb(255, 255, 255);
            text-align: center;
            color: rgb(0, 0, 0);
            font-size: 15px;

            position: absolute;
            display: inline-block;
            
            min-width: $size-icon;
            height: $size-icon;
            line-height: $size-icon;
            width: $size-icon;
            border-radius: 50%;

            transition: 0.3s all;

            box-shadow: 0 0 2px rgba(0, 0, 0, .3);
            
            display: block;
            justify-content: center;
            align-items: center;

            &.active{
                width: auto;
                z-index: 1;
                height: auto;

                .icons-item__img{
                    opacity: 1;
                }
    
                .icons-item__desc{
                    width: 200px;
                    height: auto;
                    transform: scale(1);
                    transform-origin: right;
    
                    position: absolute;
                    top: 0%;
                    left: 8px;
    
                    background: white;
                    z-index: -1;
                    text-align: left;
    
                    padding-left: $size-icon;
                    padding-right: 5px;
                }
            }
            &.active-right{
                width: auto;
                z-index: 1;
                height: auto;

                .icons-item__img{
                    opacity: 1;
                }
                .icons-item__desc{
                    width: 200px;
                    height: auto;
                    transform: scale(1);
                    transform-origin: right;
    
                    position: absolute;
                    top: -100%;
                    left: calc(-200px + 16px);
    
                    background: white;
                    z-index: -1;
                    text-align: right;
                    
                    padding-right: $size-icon;
                    padding-left: 5px;
                }
            }


            &__img{
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgb(143, 143, 165);
                border: 1px solid rgba(45, 45, 48, 0.3);
              
                width: $size-icon;
                height: $size-icon;
                line-height: $size-icon;
                border-radius: 50%;
              
                opacity: .7;
              
                transform-origin: center;
                transition: .3s all;
            }
            &__desc{
                // font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                display: block;
                line-height: 1.5;
                height: $size-icon;
                width: $size-icon;

                text-align: start;
                
                // white-space: nowrap;
                border-radius: 8px;
                
                transition: .3s all ease-in-out;
                transform: scale(0);
                
            }

            

        }    
    }
}

.pic{
    .icons-item-1{
        bottom: 27.2%;
        left: 36.3%;
    }
    .icons-item-2{
        bottom: 21.5%;
        left: 36.2%;
    }
    .icons-item-3{
        bottom: 24.9%;
        left: 42.5%;
    }
    .icons-item-4{
        bottom: 28.3%;
        left: 51.2%;
    }
    .icons-item-5{
        top: 67%;
        left: 53.89%;
    }
    .icons-item-6{
        bottom: 25.9%;
        left: 58.2%;
    }
    .icons-item-7{
        bottom: 24.6%;
        left: 61.0%;
    }
    .icons-item-8{
        bottom: 14.66%;
        left: 61.7%;      
    }
    .icons-item-9{
        bottom: 24.3%;
        left: 63.9%;
    }
    .icons-item-10{
        bottom: 17.2%;
        left: 66.1%;
    }
    .icons-item-11{
        top: 63%;
        left: 71.5%;
    }
    .icons-item-12{
        top: 70%;
        left: 78%;
    }
    .icons-item-13{
        bottom: 28.3%;
        left: 82.5%;
    }
}

// // index-2 (background Our Featured Products)
// @media (max-width: 1620px) {
//     .pic{
//         $size-icon: 29px;
//         .icons{
//             &-item{
//                 min-width: $size-icon;
//                 height: $size-icon;
//                 line-height: $size-icon;
//                 width: $size-icon;
                
//                 &__img{
//                     width: $size-icon;
//                     height: $size-icon;
//                     line-height: $size-icon;
//                 }
//                 &__desc{
//                     line-height: $size-icon;
//                     width: $size-icon;
//                 }
//             }
//         }

//     }
// }

// @media (min-width: 1420px) and (max-width: 1619px) {
//     .pic{
//         $size-icon: 27px;
//         .icons{
//             &-item{
//                 font-size: 15px;

//                 min-width: $size-icon;
//                 height: $size-icon;
//                 line-height: $size-icon;
//                 width: $size-icon;

//                 &__img{
//                     width: $size-icon;
//                     height: $size-icon;
//                     line-height: $size-icon;
//                 }
//                 &__desc{
//                     width: $size-icon;
//                 }
//             }
//         }
//     }
// }

@media (max-width: map-get($media, ps)) {
    .pic{
        $size-icon: 26px;
        .icons{
            &-item{
                font-size: 16px;

                min-width: $size-icon;
                height: $size-icon;
                line-height: $size-icon;
                width: $size-icon;

                &__img{
                    width: $size-icon;
                    height: $size-icon;
                    line-height: $size-icon;
                }
                &__desc{
                    width: $size-icon;
                }
            }
        }
    }
}

@media (max-width: map-get($media, tl)) {
    .pic{
        $size-icon: 25px;
        .icons{
            &-item{
                font-size: 14px;

                min-width: $size-icon;
                height: $size-icon;
                line-height: $size-icon;
                width: $size-icon;

                &__img{
                    width: $size-icon;
                    height: $size-icon;
                    line-height: $size-icon;
                }
                &__desc{
                    width: $size-icon;
                }
            }
        }
    }
}


@media (max-width: map-get($media, ts)) {
    .pic{
        $size-icon: 24px;
        .icons{
            &-item{
                font-size: 13px;

                min-width: $size-icon;
                height: $size-icon;
                line-height: $size-icon;
                width: $size-icon;

                &__img{
                    width: $size-icon;
                    height: $size-icon;
                    line-height: $size-icon;
                }
                &__desc{
                    width: $size-icon;
                }
            }
        }
    }
}

@media (max-width: map-get($media, ml)) {
    .pic{
        $size-icon: 22px;
        .icons{
            &-item{
                font-size: 16px;

                min-width: $size-icon;
                height: $size-icon;
                line-height: $size-icon;
                width: $size-icon;

                &__img{
                    width: $size-icon;
                    height: $size-icon;
                    line-height: $size-icon;
                }
                &__desc{
                    width: $size-icon;
                }
            }
        }
    }
}