
.index-intro{
  .intro-pic-txt{
    position: relative;
    width: 100%;
    height: 100vh;
    @media (max-width: map-get($media, tl)) {
      max-height: 720px;
    }
    @media (max-width: map-get($media, ml)) {
      max-height: 520px;
    }
  }
  .intro-pic{
    width: 100%;
    height: 100%;
    
    .intro__background{
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .intro-txt{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 500;
    width: 100%;
    text-align: center;
    
    .intro__heading{
      text-transform: uppercase;
      display: inline-block;
      color: white;
      letter-spacing: 40px;
      // white-space: nowrap;
      font-family: inherit;
    }
    .intro__desc{
      text-transform: uppercase;
      color: white;
      letter-spacing: 5px;
      margin-top: map-get($size, margin-block-medium);
    }
  }
}


@media (max-width: map-get($media, tl)){
  .index-intro{
    .intro-txt{
      .intro__heading{
        letter-spacing: 15px;
      }
    }
  }
}

@media (max-width: map-get($media, tl)){
  .index-intro{
    .intro-txt{
      .intro__heading{
        letter-spacing: 5px;
      }
      .intro__desc{
        margin-top: map-get($size, margin-block-small);
      }
    }
  }
}