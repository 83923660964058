.footer__wrapper {
    background-color: #ECECEC;
    padding:0 50px;
    
    .footer-link{
        display: inline-block;
        p{
            display: inline-block;
        }
        transition: .3s all linear;
        &:hover, &:focus{
            // transform: scale(1.05);
            // transform-origin: top left;
            p{
                color: map-get($mau, dark-gray) !important;
                font-weight: bold;
            }
        }
    }

    .container{
        position: relative;
        &::after{
            position: absolute;
            content: "";
            margin-top: 15px;
            bottom: 0;
            // left: 0;
            height: 1px;
            width: 100%;
            background: gray;
        }
    }
    .row {
        padding-top: 35px;
        justify-content: space-around;
    }
    .footer-support{
        .support--title{
            margin:4% 0;
        }
        .support--content{
            p{
                color: gray;
                font-size: 15px;
                padding: 3px 0;
            }
        }
    }
    .footer-shop-mattresses{
        .shop-mattresses--title{
            margin:4% 0;
            p{
                letter-spacing: 2px;
                font-size: 20px;
                font-weight: 450;
                color: #041E42 ;  
            }
        }
        .shop-mattresses--content{
            display: flex;
            flex-direction: column;
            p{
                color: gray;
                padding: 3px 0;
                font-size: 15px;
            }
        }
    }
    .footer-latest-news{
        .latest-news--title{
            margin:4% 0;
            p{
                letter-spacing: 2px;
                font-size: 20px;
                font-weight: 450;
                color: #041E42 ;  
            }
        }
        .latest-news--content{
            display: flex;
            flex-direction: column;
            .content {
                color: gray;
                font-size: 15px;
                margin-bottom: 30px;
            }
                .content-1{
                    position: relative;
                    a{
                        color: gray;
                    }
                    &::after{
                        position: absolute;
                        content: "";
                        margin-top: 10px;
                        bottom: -12px;
                        left: 0;
                        height: 1px;
                        width: 70%;
                        background: gray;
                    }
                }
                .content-2 p{
                    margin-bottom: 20px;
                    font-weight: 3500;
                    font-size: 20px;
                    color: #041E42;

                }
                .content-3{
                    position: relative;
                    display: flex;
                    flex-wrap: wrap;
                    width: 95%;
                    
                    input[type="email"]{
                        &::placeholder{
                            font-style: italic;
                            font-size: 18px;
                        }
                        width: 60%;
                        height: 48px;
                        padding-left: 10px;
                        border: solid 2px #041e42;
                        border-right: none;
                        border-radius: 5px 0 0 5px;
                        background-color: #ececec;
                    }
                    input[type="submit"]{
                        min-width: 30%;
                        padding: 5px 8px;
                        font-style: italic;
                        height: 48px;
                        background-color: map-get($mau, color-primary);
                        border-radius: 0 5px 5px 0;
                        border: 2px solid map-get($mau, color-primary);
                        color: map-get($mau, gray-2);
                        right: 0;
                        text-transform: uppercase;
                        transition: .3s;
                        &:hover{
                            background-color: map-get($mau, gray-2);
                            color: map-get($mau, color-primary);
                            border: 2px solid map-get($mau, color-primary);
                        }
                    }
                    .form-info{
                        font-size: 14px;
                        padding: 5px 0;
                        width: 100%;
                    }
                    .error{
                        color: map-get($mau, error);
                    }
                    .success{
                        color: map-get($mau, success);
                    }
                }
            }
        }
    
    .footer-contact-us{
        .contact-us--title{
            margin:4% 0;
            p{
                letter-spacing: 2px;
                font-size: 20px;
                font-weight: 450;
                color: #041E42 ;  
            }
        }
        .contact-us--content{
            display: flex;
            flex-direction: column;
            p{
                color: gray;
                padding: 3px 0;
                font-size: 15px;                
            }
        }
        .contact-us--social{
            position: relative;
            .social__link{
                display: inline-block;
                margin-right: 20px;
            }
            .social__img{
                width: 36px;
                height: 36px;
                object-fit: contain;
                object-position: center;
            }
            padding-top: 15px;
            // border-top: 1px solid #70707070;
            &::after{
                position: absolute;
                content: "";
                // margin-top: 5px;
                top: 4px;
                left: 0;
                height: 1px;
                width: 80%;
                background: rgba(128, 128, 128, 0.432);
                }
        }
    }
    .copyright{
        padding: 15px 0;
        border-top: 2px solid rgba(map-get($mau, gray-1), .9);
        opacity: 1;
        text-align: center;
    }
}

@media (max-width: map-get($media, pm)){
    .footer__wrapper .font-heading-4{
        font-size: 26px;
    }
}
@media (max-width: map-get($media, ts)){
    .footer__wrapper .row{
        padding-bottom: 12px;
    }
    .footer__wrapper .footer-contact-us .contact-us--social::after{
        width: 100%;
    }
}
@media (max-width: map-get($media, ml)){
    .footer__wrapper .footer-latest-news .latest-news--content .content-3{
        flex-direction: column;
    }
    .footer__wrapper .footer-latest-news .latest-news--content .content-3 input[type=email]{
        width: 100%;
        height: 48px;
        padding-left: 10px;
        border: solid 2px #041e42;
        border-radius: 5px;
        background-color: #ececec;
    }
    .footer__wrapper .footer-latest-news .latest-news--content .content-3 input[type=submit]{
        border-radius: 5px;
    }
    .footer__wrapper .footer-latest-news .latest-news--content .content-3 {
        input + input{
            margin-top: 12px;
        }
    }
}
@media (max-width: map-get($media, mm)){
    .footer-contact-us{
        padding-bottom: 12px;
    }
    .footer__wrapper{
        padding: 0 20px;
    }
}
