.wholesale--dealer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    // margin: map-get($size, margin-block-large) 0;
    @include spaceL(margin-top);
    @include spaceXL(margin-bottom);
    &__content1{
        display: flex;
        flex-direction: row;
        justify-content: center;

        .left1{
            color: #041E42;
            width: 50%;
            padding-right: 10%;
        }
        .right1{
            color: map-get($mau, dark-gray);
            width: 50%;
            padding-right: 10%;

        }
    }
    &__content2{
        display: flex;
        flex-direction: row;
        // margin: 100px 0;
        @include spaceL(margin-top);
        @include spaceXL(margin-bottom);
        .left2{
            justify-content: flex-end;
            width: 50%;
            padding: 0 10% 0 0%;
            
            .content{
                width: 100%;
                display: grid;
                gap: 12px;
                grid-auto-columns: auto;
                grid-auto-rows: auto;
                min-height: 550px;

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                img:nth-child(1){
                    grid-column: 1 / span 3;
                    grid-row: 1 / span 4;
                }
                img:nth-child(2){
                    grid-column: 4 / span 2;
                    grid-row: 2 / span 4;
                }
                img:nth-child(3){
                    grid-column: 2 / span 2;
                    grid-row: 5 / span 3;
                }
                img:nth-child(4){
                    grid-column: 4 / span 3;
                    grid-row: 6 / span 8;
                }

                // .bot-left{
                //     margin-left: 60px;
                // }
                // .top-right{
                //     margin-top:20px ;
                //     margin-left:10px ;
                // }
                // .bot-right{
                //     margin-top: 10px;
                //     margin-left: 10px;
                // }
            }
        }
        .right2{
            padding-right: 10%;
            width: 50%;
            .content2{
                // padding: 5;
                margin-top: 24px;
                list-style-type: initial;
                li{
                    margin: 12px 0;
                }
            }
        }
    }
    &__content3{
       .title3{
            text-align: center;
            // margin:0 0 70px 0;
            @include spaceL(margin-bottom);
       }
       .content{
            // display: flex;
            // flex-direction: row;
            // justify-content: center;
            // flex-wrap: wrap;
            display: grid;
            grid-template-columns: auto auto auto;
            gap: 20px 40px;
            .box{
                .images{
                    img{
                        width: 100%;
                        height: 260px;
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }
            .title{
                color: #041E42;
                margin: 20px 0;
                @include p-line-clamp(1);
            }
            .btn a{
                border: none;
                font-style: italic;
                padding-left: 0;
                background-color: transparent;
                &:hover{
                    cursor: pointer;
                    background-color: transparent;
                    color: map-get($mau, color-primary);
                    opacity: .7;

                    svg{
                        path{
                            stroke: map-get($mau, color-primary);
                        }
                    }
                }
            }
            .text{
                overflow: hidden;
                text-overflow:ellipsis;
                -webkit-line-clamp: 3;
                font-size: 20px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                white-space:wrap;
                color: #707070;
                margin: 15px 0;
            }
       }
    }
}

@media (max-width: map-get($media, tl)){
    .wholesale--dealer__content3 .content{
        grid-template-columns: auto auto;
        gap: 20px;
    }
}
@media (max-width: map-get($media, ps)){
    .wholesale--dealer__content1{
        flex-direction: column;

        .left1, .right1{
            width: 100%;
        }
        .right1{
            padding-top: 24px;
        }
    }

    .wholesale--dealer__content2{
        flex-direction: column;

        .left2{
            width: 100%;
            padding: 0;
        }
        .right2{
            width: 100%;
            padding-top: 24px;
            padding-right: 0;
            .content2{
                padding-left: 24px;
            }
        }
    }

    .wholesale--dealer__content3 .content{
        grid-template-columns: auto;
        gap: 20px;
    }
    .wholesale--dealer__content3 .content .box .images img{
        height: 360px;
    }
}
@media (max-width: map-get($media, mm)){
    .wholesale--dealer__content3 .content .box .images img{
        height: 240px;
    }
}

@media (max-width: map-get($media, ts)){
    .wholesale--dealer__content2 .left2 .content{
        display: flex;
        flex-direction: column;
        &>*{
            min-height: 320px;
            max-height: 480px;
        }
        &>*:not(:first-child){
            margin-top: 12px;
        }
    }
}